import Star from "components/icons/Star";
import React, { useState, useEffect } from "react";

const Rating = ({ rating, index }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(false);
      if (window.innerWidth < 1000) {
        setIsMobile(true);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const filledStars = Math.floor(rating);
  const remainingStar = 5 - filledStars;
  const fillPercentage = Math.floor((rating % 1) * 100);
  const stars = [];

  for (let i = 0; i < filledStars; i++) {
    stars.push(
      <Star
        key={i}
        fill="#FFA412"
        width={isMobile ? "15" : "29"}
        height={isMobile ? "15" : "27"}
      />
    );
  }

  if (remainingStar > 0) {
    stars.push(
      <svg
        width={isMobile ? "15" : "29"}
        height={isMobile ? "15" : "27"}
        viewBox="0 0 29 27"
        xmlns="http://www.w3.org/2000/svg"
        key={"remainingstar"}
      >
        <defs>
          <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop
              offset={`${fillPercentage}%`}
              style={{ stopColor: "#FFA412", stopOpacity: 1 }}
            />
            <stop
              offset={`${fillPercentage}%`}
              style={{ stopColor: "none", stopOpacity: 0 }}
            />
          </linearGradient>
        </defs>
        <path
          d="M14.5 1.61804L17.2799 10.1738L17.3922 10.5193H17.7555L26.7515 10.5193L19.4735 15.807L19.1796 16.0205L19.2919 16.366L22.0718 24.9217L14.7939 19.634L14.5 19.4205L14.2061 19.634L6.92817 24.9217L9.7081 16.366L9.82035 16.0205L9.52646 15.807L2.24852 10.5193L11.2445 10.5193H11.6078L11.7201 10.1738L14.5 1.61804Z"
          fill="url(#grad1)"
          stroke="#FFA412"
        />
      </svg>
    );
  }

  for (let i = 0; i < remainingStar - 1; i++) {
    stars.push(
      <Star
        key={filledStars + i + 1}
        fill="#ddd"
        width={isMobile ? "15" : "29"}
        height={isMobile ? "15" : "27"}
      />
    );
  }

  return (
    <div className="flex ml-[10px] sm:ml-[3px]" key={index}>
      {stars}
    </div>
  );
};

export default Rating;
