import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";

const Carousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    centerMode: true, // Enable center mode
    centerPadding: "10%", // 10% padding on the sides
    slidesToShow: 1.1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };

  return (
    <div className="container mx-auto mr-4">
      <Slider {...settings}>
        <div>
          <img className="w-full" src="/images/func2-1.png" alt="Slide 1" />
        </div>
        <div>
          <img className="w-full" src="/images/func2-2.png" alt="Slide 2" />
        </div>
        <div>
          <img className="w-full" src="/images/func2-3.png" alt="Slide 3" />
        </div>
        <div>
          <img className="w-full" src="/images/func2-4.png" alt="Slide 4" />
        </div>
        <div>
          <img className="w-full" src="/images/func2-5.png" alt="Slide 5" />
        </div>
      </Slider>
    </div>
  );
};

export default Carousel;
