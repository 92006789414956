import React, { useState, useEffect } from "react";
import MessageSent from "./messageSent";

const HelpContact = () => {
  const [inputValue, setInputValue] = useState("");
  const [isSent, setIsSent] = useState(false);
  const characterLimit = 500;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleInputChange = (event) => {
    // Update the state only if the input length is less than or equal to the characterLimit
    if (event.target.value.length <= characterLimit) {
      setInputValue(event.target.value);
    }
  };

  // Function to handle form submission or other action to process the input value
  const handleSubmit = () => {
    console.log("Input Content:", inputValue);
    setIsSent(true);
  };

  return (
    <div className="bg-primary h-max">
      {/* {isSent ? (
        <MessageSent />
      ) : ( */}
      {/* <div className="max-screen-1440 mx-auto px-[28px] sm:px-[18px] md:px-[23px]"> */}
      <div className="max-screen-1440 mx-auto px-[28px] sm:px-[18px] md:px-[23px]">
        <div className="text-white text-[50px] sm:text-[24px] sm:font-medium font-roboto-bold pt-[50px] sm:pt-[14px]">
          Contact us
        </div>
        <div className="mt-10 sm:mt-4 w-full sm:w-full">
          <div className="relative">
            <textarea
              placeholder="Tell us how we can help."
              className="form-textarea bg-[#0B1620] text-white px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent w-full resize-none"
              style={{ height: "512px" }} // Set the width in your CSS or remove this if you're using w-full
              value={inputValue}
              onChange={handleInputChange}
              maxLength={characterLimit} // This enforces the character limit
            />
            <div className="absolute right-0 bottom-0 text-white text-sm p-2">
              {inputValue.length} / {characterLimit}
            </div>
          </div>
          <div className="text-[16px] sm:text-[16px] text-left text-white mt-8">
            By clicking Send, you acknowledge Capiwise may review metadata
            associated with your account to try to troubleshoot and solve your
            resported issue.{" "}
            <span className="text-light-green">Learn more.</span>
          </div>
        </div>

        <div className="text-center mt-[48px] sm:mt-[24px] pb-[300px] sm:pb-[154px] flex justify-center">
          <button
            className="justify-center w-[514px] bg-white mt-[60px] md:mt-[42px] md:h-[41px] h-[56px] md:w-[390px] sm:w-full sm:h-[42px] border sm:mt-[21px] sm:mx-auto border-black text-[#040B11] font-reg flex sm:justify-center items-center text-[24px] sm:text-[17px] rounded-[39px] relative hover:text-white hover:bg-light-green hover:border-light-green"
            onClick={() => handleSubmit()}
          >
            <span className="font-roboto-bold text-[24px] md:text-[17px] sm:text-[17px]">
              Send
            </span>
          </button>
        </div>
      </div>
      {/* )} */}
    </div>
  );
};

export default HelpContact;
