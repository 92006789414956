import React, { useEffect, useState } from "react";
import PublicLayout from "layout/PublicLayout";
import Form from "components/Contact/Form";
import Map from "components/Contact/Map";
import LaunchOffer from "components/Modal/LaunchOffer";
import CongratsModal from "components/Modal/CongratsModal";

function Contact() {
  const [open, setOpen] = useState(false);
  const [openCongrats, setOpenCongrats] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PublicLayout>
      <LaunchOffer
        onClick={() => {
          setOpenCongrats(true);
          setOpen(false);
        }}
        open={open}
        onClose={() => setOpen(false)}
      />
      <CongratsModal
        open={openCongrats}
        onClose={() => setOpenCongrats(false)}
      />
      <div className="bg-black">
        <div className="max-w-[1440px] mx-auto">
          <div className="font-roboto-bold text-[68px] px-[30px] sm:text-base pt-[104px] sm:pt-[36px] sm:text-center max-w-[855px] leading-normal tracking-tight text-white mb-[80px] sm:mb-[29px]">
            <span className="text-light-green">Get in touch;</span> we're happy
            to hear from you
          </div>
          <div>
            <div className="flex justify-between sm:flex-col px-4 mx-auto">
              <div className="text-white sm:text-17  sm:text-center sm:mb-[28px] sm:w-full text-base font-reg tracking-tight leading-normal w-[423px]">
                You're just one click away from taking your portfolio to the
                next level.
                <span className="sm:text-light-green"> Contact us now.</span>
              </div>
              <Form />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-black py-[160px] sm:pt-[60px] pb-0 sm:pb-0">
        <Map />
        <div className="max-w-[1440px] mx-auto sm:bg-[url('../public/images/contact/investing-bg-mbl.png')] bg-[url('../public/images/contact/investing-bg.png')] h-[798px] sm:h-[333px] bg-no-repeat bg-cover">
          <div className=" pl-[28px]">
            <div className="text-[68px] sm:text-base tracking-ti62er w-[584px] sm:w-[46%] leading-normal font-roboto-bold pt-[100px] sm:pt-[30px] text-white">
              Investing made <span className="text-light-green">easy</span> for
              everyone
            </div>
            <div className="font-reg mt-[32px] sm:mt-[16px] text-white sm:text-12 text-[34px] tracking-tighter mb-[60px] sm:mb-[29px]">
              Start today
            </div>
            {/* <button
              onClick={() => setOpen(true)}
              className="relative arrow-button justify-center sm:hidden sm:w-[210px] w-[287px] bg-light-green mt-[88px] md:mt-[52px] md:h-[41px] h-[54px] md:w-[190px] sm:h-[42px] border sm:mt-[21px]  text-white font-reg flex sm:justify-center items-center sm:pl-[0] pl-[35px] text-base sm:text-[14px] rounded-[39px] sm:hover:border-white sm:border-light-green hover:border-light-green hover:bg-light-green "
            >
              <div className="arrow absolute left-[20px] ">
                <div className="head"></div>
              </div>
              <span className="sm:absolute sm:right-[30px]">
                {" "}
                Get early access
              </span>
            </button> */}
          </div>
        </div>
      </div>
    </PublicLayout>
  );
}

export default Contact;
