import React, { useEffect, useState } from "react";
import HelpCard from "utils/helpCard";
import { useNavigate } from "react-router-dom";
import { HelpCenterTitles } from "utils/constants";

const Community2 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  const [isThumbUpActive, setIsThumbUpActive] = useState(false);
  const [isThumbDownActive, setIsThumbDownActive] = useState(false);

  const handleThumbUpClick = () => {
    setIsThumbUpActive(!isThumbUpActive);
    setIsThumbDownActive(false);
  };

  const handleThumbDownClick = () => {
    setIsThumbDownActive(!isThumbDownActive);
    setIsThumbUpActive(false);
  };

  return (
    <div className="bg-primary h-max">
      <div className="max-screen-1440 mx-auto px-[28px] sm:px-[18px] md:px-[23px]">
        <div className="sm:block hidden text-white text-[24px] font-medium pt-[14px] flex items-center">
          <div
            className="flex items-center gap-2 mb-4"
            onClick={() => navigate("/help/community")}
          >
            <img src="/icons/left.svg" className="z-[12]" alt="left-back" />
            Community
          </div>
        </div>
        <div className="sm:hidden block text-white text-[50px] font-roboto-bold pt-[50px] flex items-center gap-8">
          <img src="/images/help/community-detail.svg" alt="community-detail" />
          Community
        </div>
        <div className="mt-10 sm:mt-4 sm:w-full flex max-w-[1416px] w-full grid grid-cols-12">
          <div className="col-span-9 md:col-span-12 sm:col-span-12">
            <div className="w-full mx-auto mb-10 sm:mb-4">
              <div className="w-full bg-transparent rounded shadow-md">
                <div className="font-bold text-[24px] text-white w-full text-left flex justify-between items-center pb-8 sm:pb-4 border-b border-b-1 border-[#464646]">
                  {HelpCenterTitles.community[1].title}
                </div>
                <div
                  className={`mt-8 pb-8 sm:mt-4 sm:pb-[56px] border-b border-b-1 border-[#464646]`}
                >
                  <div className="text-white text-[24px] sm:text-[16px]">
                    <p className="font-normal">
                      Certainly! In the Capiwise app, you can follow other
                      investors or users to stay updated on their activities and
                      discussions. Here's a general guide on how to follow other
                      users:
                    </p>
                    <div className="mt-4">
                      <div className="font-bold">1. User Profiles:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Navigate to the profile of the user you want to follow.
                        This can often be done by clicking on their username or
                        avatar in community discussions or other sections of the
                        app.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">2. Follow Button:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Look for a "Follow" button on the user's profile. This
                        button is typically located near the user's profile
                        picture or username. Clicking or tapping on this button
                        will initiate the following action.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">3. Follow Confirmation:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        After clicking the "Follow" button, you may receive a
                        confirmation prompt to confirm your decision. Confirm
                        the following action to start following the user.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">4. Following List:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Check your own profile or account settings to view the
                        list of users you are currently following. This list may
                        be accessible through a "Following" or "Followers"
                        section, depending on the app's design.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">5. Unfollow Option:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        If, at any point, you wish to stop following a user,
                        revisit their profile and look for an "Unfollow" button.
                        Clicking or tapping on this button will cease your
                        following relationship with that user.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">6. Notifications:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Configure your notification settings to receive alerts
                        about updates from users you follow. This may include
                        notifications about their new posts, comments, or other
                        activities within the app.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">7. Explore Features:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Some apps have features that suggest users to follow
                        based on common interests or activities. Explore these
                        features to discover and connect with users who share
                        similar investment or market interests.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">8. Community Discussions:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Engage in community discussions and follow users whose
                        insights and contributions you find valuable. Following
                        other investors can enhance your experience by providing
                        diverse perspectives on market trends and investment
                        strategies.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">9. Privacy Settings:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Be mindful of your own privacy settings. Some apps allow
                        users to control who can follow them or view their
                        profile. Adjust your settings based on your preferences.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">10. Build a Network:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Building a network of users you follow and who follow
                        you can create a more interactive and collaborative
                        community experience. Share your thoughts, ask
                        questions, and participate in discussions to strengthen
                        your network.
                      </div>
                    </div>
                    <div className="mt-4 font-normal">
                      Remember to refer to the Capiwise app's documentation or
                      help centre for specific guidance on following other
                      users. The app's help centre provides valuable information
                      on how to use social features effectively and make the
                      most of your interactions with other investors.
                    </div>
                  </div>
                  <div className="text-[#979797] text-[16px] mt-[108px] sm:mt-8 sm:text-[10px] ">
                    Was this information helpful?
                  </div>
                  <div className="flex justify-between items-center w-[120px] sm:w-[75px] mt-4">
                    <img
                      src={
                        isThumbUpActive
                          ? "/images/help/thumbup_active.svg"
                          : "/images/help/thumbup_normal.svg"
                      }
                      alt="thumbup"
                      onClick={handleThumbUpClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                    <img
                      src={
                        isThumbDownActive
                          ? "/images/help/thumbdown_active.svg"
                          : "/images/help/thumbdown_normal.svg"
                      }
                      alt="thumbdown"
                      onClick={handleThumbDownClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                  </div>
                </div>
              </div>
              <div className="text-[#0F69FE] text-[24px] mt-[80px] sm:mt-4 sm:text-[16px]">
                More Help
              </div>
              <div onClick={() => navigate(`/help/community/1`)}>
                <HelpCard title={HelpCenterTitles.community[0].title} />
              </div>
              <div onClick={() => navigate(`/help/community/3`)}>
                <HelpCard title={HelpCenterTitles.community[2].title} />
              </div>
              <div onClick={() => navigate(`/help/community/4`)}>
                <HelpCard title={HelpCenterTitles.community[3].title} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Community2;
