import React, { useEffect, useState } from "react";
import HelpCard from "utils/helpCard";
import { useNavigate } from "react-router-dom";
import { HelpCenterTitles } from "utils/constants";

const Community3 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  const [isThumbUpActive, setIsThumbUpActive] = useState(false);
  const [isThumbDownActive, setIsThumbDownActive] = useState(false);

  const handleThumbUpClick = () => {
    setIsThumbUpActive(!isThumbUpActive);
    setIsThumbDownActive(false);
  };

  const handleThumbDownClick = () => {
    setIsThumbDownActive(!isThumbDownActive);
    setIsThumbUpActive(false);
  };

  return (
    <div className="bg-primary h-max">
      <div className="max-screen-1440 mx-auto px-[28px] sm:px-[18px] md:px-[23px]">
        <div className="sm:block hidden text-white text-[24px] font-medium pt-[14px] flex items-center">
          <div
            className="flex items-center gap-2 mb-4"
            onClick={() => navigate("/help/community")}
          >
            <img src="/icons/left.svg" className="z-[12]" alt="left-back" />
            Community
          </div>
        </div>
        <div className="sm:hidden block text-white text-[50px] font-roboto-bold pt-[50px] flex items-center gap-8">
          <img src="/images/help/community-detail.svg" alt="community-detail" />
          Community
        </div>
        <div className="mt-10 sm:mt-4 sm:w-full flex max-w-[1416px] w-full grid grid-cols-12">
          <div className="col-span-9 md:col-span-12 sm:col-span-12">
            <div className="w-full mx-auto mb-10 sm:mb-4">
              <div className="w-full bg-transparent rounded shadow-md">
                <div className="font-bold text-[24px] text-white w-full text-left flex justify-between items-center pb-8 sm:pb-4 border-b border-b-1 border-[#464646]">
                  {HelpCenterTitles.community[2].title}
                </div>
                <div
                  className={`mt-8 pb-8 sm:mt-4 sm:pb-[56px] border-b border-b-1 border-[#464646]`}
                >
                  <div className="text-white text-[24px] sm:text-[16px]">
                    <p className="font-normal">
                      Certainly! Here's a general response for the help centre
                      of your project regarding guidelines for community
                      interactions within the Capiwise app:
                    </p>
                    <div className="mt-4">
                      <div className="font-bold">
                        Community Interaction Guidelines
                      </div>
                      <div className="font-normal">
                        At Capiwise, we value a vibrant and respectful community
                        where investors can share insights, discuss market
                        trends, and learn from one another. To ensure a positive
                        experience for all users, we have established the
                        following guidelines for community interactions:
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">1. Respectful Conduct:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Treat fellow users with respect and courtesy. Avoid
                        engaging in personal attacks, harassment, or any form of
                        discriminatory behavior. Maintain a constructive and
                        inclusive environment.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">2. Relevant Discussions:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Keep discussions relevant to the topics at hand. Strive
                        to contribute insights, questions, and information that
                        align with the purpose of the community—discussing
                        investments, market trends, and related subjects.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        3. No Spam or Self-Promotion:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Refrain from spamming the community with promotional
                        content, advertisements, or excessive self-promotion.
                        The focus should be on sharing valuable information and
                        fostering meaningful discussions.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">4. Avoid Misinformation:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Share accurate and reliable information. Be cautious of
                        spreading unverified news or rumors that could mislead
                        the community. If uncertain about a piece of
                        information, seek clarification before sharing.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        5. Responsible Investing Discussions:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        While expressing opinions, be mindful of the potential
                        impact on others. Discuss investment strategies and
                        market trends responsibly, considering the diverse
                        perspectives within the community.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        6. Use Appropriate Language:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Keep language professional and suitable for a diverse
                        audience. Refrain from using offensive, vulgar, or
                        inappropriate language that may create an uncomfortable
                        atmosphere for others.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        7. Report Inappropriate Content:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        If you come across content that violates community
                        guidelines, use the reporting feature to notify
                        moderators. This helps maintain a safe and positive
                        community space.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">8. Respect Privacy:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Avoid sharing personal information or private details
                        about yourself or others. Respect the privacy of fellow
                        users and focus on the discussions related to investing
                        and finance.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">9. Community Moderation:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Moderators may enforce these guidelines to ensure a
                        positive community experience. Users who repeatedly
                        violate guidelines may face consequences, including
                        warnings or account restrictions.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">10. Continuous Learning:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Embrace the opportunity to learn from others and share
                        your knowledge. The community is a place for continuous
                        learning and collaboration among investors with varied
                        experiences and backgrounds.
                      </div>
                    </div>
                    <div className="mt-4 font-normal">
                      By adhering to these guidelines, you contribute to the
                      creation of a community that fosters meaningful
                      interactions and supports the growth of its members. Thank
                      you for being a valued member of the Capiwise community!
                    </div>
                    <div className="mt-4 font-normal">
                      For more details or specific questions, please refer to
                      our comprehensive Community Guidelines section in the app
                      or{" "}
                      <u
                        className="text-[#0F69FE] cursor-pointer"
                        onClick={() => navigate("/contact")}
                      >
                        contact our support team
                      </u>
                      .
                    </div>
                  </div>
                  <div className="text-[#979797] text-[16px] mt-[108px] sm:mt-8 sm:text-[10px] ">
                    Was this information helpful?
                  </div>
                  <div className="flex justify-between items-center w-[120px] sm:w-[75px] mt-4">
                    <img
                      src={
                        isThumbUpActive
                          ? "/images/help/thumbup_active.svg"
                          : "/images/help/thumbup_normal.svg"
                      }
                      alt="thumbup"
                      onClick={handleThumbUpClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                    <img
                      src={
                        isThumbDownActive
                          ? "/images/help/thumbdown_active.svg"
                          : "/images/help/thumbdown_normal.svg"
                      }
                      alt="thumbdown"
                      onClick={handleThumbDownClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                  </div>
                </div>
              </div>
              <div className="text-[#0F69FE] text-[24px] mt-[80px] sm:mt-4 sm:text-[16px]">
                More Help
              </div>
              <div onClick={() => navigate(`/help/community/1`)}>
                <HelpCard title={HelpCenterTitles.community[0].title} />
              </div>
              <div onClick={() => navigate(`/help/community/2`)}>
                <HelpCard title={HelpCenterTitles.community[1].title} />
              </div>
              <div onClick={() => navigate(`/help/community/4`)}>
                <HelpCard title={HelpCenterTitles.community[3].title} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Community3;
