import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import PublicLayout from "layout/PublicLayout";
import Watchlist1 from "./watchlist1";
import Watchlist2 from "./watchlist2";
import Watchlist3 from "./watchlist3";
import Watchlist4 from "./watchlist4";

const SingleHelpWatchlist = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams();
  return (
    <PublicLayout>
      {id === "1" ? (
        <Watchlist1 />
      ) : id === "2" ? (
        <Watchlist2 />
      ) : id === "3" ? (
        <Watchlist3 />
      ) : id === "4" ? (
        <Watchlist4 />
      ) : (
        <></>
      )}
    </PublicLayout>
  );
};

export default SingleHelpWatchlist;
