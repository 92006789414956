import React, { useEffect, useState } from "react";
import PublicLayout from "layout/PublicLayout";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Integration from "components/AboutPage/integration";
// import Teams from "components/AboutPage/Teams";
import LaunchOffer from "components/Modal/LaunchOffer";
import CongratsModal from "components/Modal/CongratsModal";
import { Link } from "react-router-dom";

function AboutPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [open, setOpen] = useState(false);
  const [openCongrats, setOpenCongrats] = useState(false);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(false);
      if (window.innerWidth < 834) {
        setIsMobile(true);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <PublicLayout>
      <LaunchOffer
        onClick={() => {
          setOpenCongrats(true);
          setOpen(false);
        }}
        open={open}
        onClose={() => setOpen(false)}
      />
      <CongratsModal
        open={openCongrats}
        onClose={() => setOpenCongrats(false)}
      />
      <div className="bg-primary  pb-[174px] sm:pt-0 sm:pb-[61px]  pt-[104px]">
        <div className="max-w-[1440px] px-[28px] sm:px-0 mx-auto">
          <div className="font-roboto-bold sm:mt-[-17px] sm:bg-[url('../public/images/about/inventing-mbl.png')] sm:bg-no-repeat sm:bg-cover sm:h-[229px] text-white text-[68px] md:text-[50px] sm:text-base sm:leading-normal tracking-tighter mb-[80px] md:mb-[63px] sm:mb-[63px]">
            <div className="sm:pt-[53px] sm:pl-[15px] sm:w-[156px] sm:mb-[28px] leading-normal">
              Investing made easy
            </div>
            {isMobile && (
              <Link to={"/contact"}>
                <button className="relative  ml-[15px] arrow-button justify-center sm:w-[210px] w-[287px] bg-light-green mt-[88px] md:mt-[52px] md:h-[41px] h-[54px] md:w-[190px] sm:h-[42px] border sm:mt-[21px] border-white text-white sm:font-roboto-bold  flex sm:justify-center items-center sm:pl-[0] pl-[35px] text-base sm:text-[14px] rounded-[39px] sm:hover:border-white sm:border-light-green hover:border-light-green hover:bg-light-green ">
                  <div className="arrow absolute left-[40px]">
                    <div className="head"></div>
                  </div>
                  <span className="sm:absolute sm:right-[50px]">
                    Get in touch
                  </span>
                </button>
              </Link>
            )}
          </div>
          {!isMobile && (
            <>
              <div className="flex items-center leading-normal mb-[80px] md:mb-[56px]">
                <div className="w-[137px] h-[9px] bg-light-green" />
                <div className="font-reg text-[34px] md:text-[26px] text-white ml-[38px]">
                  We are a pioneering digital financial investment platform that
                  combines zero-fee trading, auto-trading, analysis, risk
                  management, and more.
                </div>
              </div>
              <div className="mb-[160px] md:mb-[120px] flex grid grid-cols-12 gap-x-6">
                <div className="col-span-4 flex">
                  <img
                    src="/images/about/investing1.png"
                    alt="invest1"
                    className=""
                  />
                </div>
                <div className="col-span-4 flex flex-col justify-between">
                  <img
                    src="/images/about/investing2.png"
                    alt="invest2"
                    className=""
                  />
                  <div className="text-white font-roboto-bold text-[34px] md:text-[24px] tracking-tighter ">
                    We want to help you build your wealth and secure your
                    future.
                  </div>
                  <Link to={"/contact"}>
                    <button className="arrow-button border-light-green bg-light-green w-[328px] md:w-full justify-center h-[63px] md:h-[50px] border text-white font-reg flex items-center pl-[35px] text-base sm:text-17 rounded-[39px] relative">
                      <div className="arrow absolute left-[60px]">
                        <div className="head"></div>
                      </div>
                      <span className="sm:absolute sm:right-[50px]">
                        Get in touch
                      </span>
                    </button>
                  </Link>
                </div>
                <div className="col-span-4">
                  <img
                    src="/images/about/investing3.png"
                    className="m-[inherit]"
                    alt="invest3"
                  />
                </div>
              </div>
            </>
          )}
          <div className="flex flex-col items-center justify-center mb-[160px] sm:mb-[60px]">
            <img
              src="/images/about/logo.png"
              className="mb-[32px] sm:w-[31px] sm:mb-[23px]"
              alt=""
            />
            <div className="text-light-green sm:text-[17px] text-center mb-[48px] sm:mb-[28px] font-roboto-bold text-[48px] tracking-tighter">
              The Capiwise mission
            </div>
            <div className="text-white sm:w-[100%] sm:tracking-tight font-reg sm:leading-normal text-center text-[24px] sm:px-[16px] sm:text-12 w-[740px]">
              We are not just a financial trading platform; we are a new
              approach that aims to make fintech accessible to everyone. Our
              mission is to bring the idea of financial trading in a{" "}
              <span className="sm:text-light-green">
                simplified, safe, and frictionless
              </span>{" "}
              way so that people with no financial experience can trade side by
              side with professionals.
            </div>
          </div>
          <div className="flex mx-auto gap-8 md:gap-6 justify-center sm:flex-col sm:px-[16px] grid grid-cols-12">
            <div className="bg-secondary rounded-[10px] w-full sm:h-[343px] sm:px-[16px] col-span-6 sm:col-span-12 flex items-center justify-center">
              <div className="">
                <div className="bg-white flex justify-center items-center w-[250px] md:w-[200px] sm:text-[17px] sm:w-[153px] mx-auto rounded-full font-roboto-bold text-[34px] tracking-tighter text-secondary">
                  Our vision
                </div>
                <div className="leading-normal tracking-[0.4px] sm:text-17 md:text-[20px] font-reg text-base text-white w-[60%] md:w-[80%] sm:w-full mx-auto text-center mt-8">
                  To be a{" "}
                  <span className="sm:text-light-green">
                    key partner in the technological development
                  </span>{" "}
                  taking place in the world, but with an effective,{" "}
                  <span className="text-light-green">positive approach </span>
                  that educates and facilitates the involvement of new users.
                </div>
              </div>
            </div>
            <div className="col-span-6 sm:col-span-12 flex items-center justify-center">
              <img src="/images/about/vision.png" alt="vision" className="" />
            </div>
          </div>
          <Integration />
          {/* <Teams /> */}
        </div>
      </div>
    </PublicLayout>
  );
}

export default AboutPage;
