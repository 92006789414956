import LearningCard from "components/LearningCard";
import PublicLayout from "layout/PublicLayout";
import React, { useEffect } from "react";
import { learningItems, books, staticCard, courses } from "utils/constants";
import Card from "components/BookRecomendationCard";
import StaticCard from "components/StaticCard";

const LearningPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PublicLayout>
      <div className='2xl:hidden md:hidden xl:hidden lg:hidden sm:bg-[url("../public/images/background/mbl-learning.png")] sm:bg-cover sm:h-[257px] sm:px-0'>
        <div className=" sm:font-roboto-bold sm:text-white sm:tracking-[0.4px] sm:leading-[28px] sm:text-[24px] sm:pt-[75px] sm:pl-[28px] ">
          Learn.
          <br />
          Analyse.
          <br />
          <span className="sm:text-light-green">Invest.</span>
        </div>
      </div>
      <div className="max-screen-1440 mx-auto px-[28px] sm:px-[18px] md:px-[23px]">
        <div className="flex pt-[17px] justify-between sm:hidden md:pr-[23px] grid grid-cols-12 gap-2">
          <div className="col-span-4 font-roboto-bold text-white tracking-[0.4px] 2xl:leading-[79px] xl:leading-[79px] 2xl:text-[68px] xl:text-[68px] xl:pt-[30px] 2xl:pt-[30px] md:text-[42px] lg:leading-[49px]">
            Learn.
            <br />
            Analyse.
            <br />
            <span className="text-light-green">Invest.</span>
            <div className="mt-[40px] text-white font-roboto-bold tracking-[0.4px] xl:leading-[39px] xl:text-[34px] xl:mb-[20px] md:text-[28px] md:leading-[30px] ">
              Learning hub
            </div>
            <div className="text-white font-med text-[24px] md:text-17 leading-[28px] tracking-tight ">
              Everything you need to know to <br className="block md:hidden" />{" "}
              start your investment journey.
            </div>
          </div>
          <div className="col-span-4 flex justify-end">
            <img
              src="images/background/learning1.png"
              className="w-[425px] h-[534px] md:w-[325px] md:h-[430px] "
              alt="learning1"
            />
          </div>
          <div className="col-span-2 flex justify-end">
            <img
              src="images/background/learning2.png"
              className="w-[188px] h-[534px] md:w-[188px] md:h-[430px] "
              alt="learning2"
            />
          </div>
          <div className="col-span-2 flex justify-end">
            <img
              src="images/background/learning3.png"
              className="w-[188px] h-[534px] md:w-[188px] md:h-[430px] "
              alt="learning3"
            />
          </div>
        </div>

        <div className="mt-[135px] text-white flex justify-center items-center text-[48px] font-roboto-bold leading-[56px] tracking-[0.4px] sm:mt-[35px] sm:text-[24px] sm:leading-[28px] sm:justify-start md:mt-[80px] ">
          Investing for Beginners
        </div>
        <div className="mt-[53px]  w-[100%] sm:mt-[35px] ">
          <LearningCard items={learningItems} />
        </div>
        <div className="mt-[90px] sm:mt-[45px]">
          <div className="text-white font-roboto-bold leading-[56px] tracking-[0.4px] text-[48px] sm:text-[24px] sm:leading-[28px]">
            Book
            <span className="text-light-green"> recommendations</span>
          </div>
        </div>
        <div className="mt-[25px] sm:mt-[15px]">
          <div className="font-reg text-[34px] tracking-[0.24px] leading-[39px] text-white sm:text-[17px] sm:leading-[19px]">
            Our personal favourites{" "}
          </div>
        </div>
        <div className="mt-[40px]">
          <Card items={books} />
        </div>
        <div className="mt-[90px] sm:mt-[45px]">
          <div className="text-white font-roboto-bold leading-[56px] tracking-[0.4px] text-[48px] sm:text-[24px] sm:leading-[28px]">
            Course
            <span className="text-light-green"> recommendations</span>
          </div>
        </div>
        <div className="mt-[25px] sm:mt-[15px] sm:pr-[18px] md:pr-[23px]">
          <div className="font-reg text-[34px] tracking-[0.24px] leading-[39px] text-white sm:text-[17px] sm:leading-[19px]">
            Stay up-to-date on the most relevant topics in the market.
          </div>
        </div>
        <div className="mt-[40px]">
          <Card courses={true} items={courses} />
        </div>
        <div className="mt-[90px] sm:mt-[45px] sm:pr-[18px] md:pr-[23px]">
          <div className="text-white font-roboto-bold leading-[56px] tracking-[0.4px] text-[48px] flex justify-center sm:text-[24px] sm:leading-[28px]">
            There’s more
            <span className="text-light-green">&nbsp;to learn</span>
          </div>
        </div>
        <div className="mt-[40px] flex gap-[46px] justify-center sm:gap-[17px]  flex-wrap pb-[200px] sm:pb-[96px] sm:mt-[29px] sm:pr-[18px] md:pr-[23px]">
          {staticCard?.map((item, index) => (
            <StaticCard item={item} key={index} />
          ))}
        </div>
      </div>
    </PublicLayout>
  );
};

export default LearningPage;
