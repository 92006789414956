import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { HelpCenterTitles } from "utils/constants";

const SearchBox = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (searchTerm === "") setShowDropdown(false);
    else startSearch();
  }, [searchTerm]);

  const handleSelect = (option) => {
    setSearchTerm("");
    setShowDropdown(false);

    navigate(`/help/${option.category}/${option.index + 1}`);
  };

  const startSearch = async () => {
    const filteredTitles = Object.entries(HelpCenterTitles).flatMap(
      ([category, items]) =>
        items
          .map((item, index) => ({ category, title: item.title, index }))
          .filter((item) =>
            item.title?.toLowerCase().includes(searchTerm.toLowerCase())
          )
    );
    setFilteredOptions(filteredTitles);
    setShowDropdown(true);
  };

  const handleSearchFocus = () => {
    if (searchTerm !== "" && filteredOptions.length > 0) {
      setShowDropdown(true);
      document.getElementById("search_box")?.classList.remove("bg-transparent");
      if (
        !document
          .getElementById("search_box")
          ?.classList.contains("bg-[#0B1620]")
      ) {
        document.getElementById("search_box")?.classList.add("bg-[#0B1620]");
      }
    }
  };

  const clearSearchBox = () => {
    setSearchTerm("");
    setShowDropdown(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && searchTerm !== "") startSearch();
  };

  return (
    <div className="grow flex items-center justify-center">
      <div
        className={
          "relative rounded-full max-w-[646px] h-[44px] sm:w-full w-full " +
          (searchTerm === "" ? "border-[1px] border-[#979797] " : "")
        }
      >
        <input
          className={
            "text-[16px] py-2 text-white focus:outline-none rounded-full border-[#979797] w-full pl-5 pr-20 " +
            (searchTerm !== "" ? "bg-[#0B1620]" : "bg-transparent")
          }
          id="search_box"
          placeholder="Search"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
          onFocus={() => handleSearchFocus()}
          onKeyDown={(e) => {
            handleKeyDown(e);
          }}
        />
        <span className="absolute inset-y-0 right-0 flex items-center pl-2">
          {searchTerm !== "" ? (
            <button
              className="text-white hover:text-[#F35530]"
              onClick={() => clearSearchBox()}
            >
              X
            </button>
          ) : (
            <></>
          )}

          <button
            className="p-1 ml-2 pr-5 focus:outline-none focus:shadow-outline"
            onClick={(e) => startSearch()}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.2124 14.5735L11.4044 10.6129C12.3835 9.44901 12.92 7.98455 12.92 6.45999C12.92 2.89801 10.022 0 6.45999 0C2.89801 0 0 2.89801 0 6.45999C0 10.022 2.89801 12.92 6.45999 12.92C7.79721 12.92 9.07152 12.5167 10.161 11.751L13.998 15.7416C14.1583 15.9082 14.374 16 14.6052 16C14.824 16 15.0316 15.9166 15.1891 15.7649C15.5239 15.4428 15.5346 14.9085 15.2124 14.5735ZM6.45999 1.68522C9.09286 1.68522 11.2348 3.82713 11.2348 6.45999C11.2348 9.09286 9.09286 11.2348 6.45999 11.2348C3.82712 11.2348 1.68522 9.09286 1.68522 6.45999C1.68522 3.82713 3.82712 1.68522 6.45999 1.68522Z"
                fill="white"
              />
            </svg>
          </button>
        </span>

        {showDropdown && (
          <div
            className={
              "absolute z-10 w-full px-1 bg-[#0B1620] rounded-[10px] shadow-lg overflow-auto scrollbar-hide h-max text-white top-[50px] pb-4"
            }
            id="symbol-list"
          >
            <div className="text-[16px] text-left py-3 mx-4">Results</div>
            <ul>
              {filteredOptions.length > 0 ? (
                filteredOptions.map((option) => (
                  <li
                    key={option.category + option.title + option.index}
                    onClick={() => handleSelect(option)}
                    className="mx-4 pb-3 cursor-pointer hover:bg-[#040B11] "
                  >
                    <div className="flex text-sm w-full items-center pt-3 border-t border-t-1 border-t-[#464646]">
                      <div className="w-full text-left text-[16px] font-semibold">
                        {option.title}
                      </div>
                      <img
                        src="/icons/right-go.svg"
                        alt=""
                        className="h-[17px] sm:h-[10px]"
                      />
                    </div>
                  </li>
                ))
              ) : (
                <div className="text-sm w-full p-3 text-left ">No results!</div>
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBox;
