import { Header } from "components/Header";
import React, { useEffect } from "react";
import Modal from "react-modal";

function LanguageModal({ open, onClose }) {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    }
  }, [open]);
  return (
    <Modal
      isOpen={open}
      overlayClassName="modal-overlay"
      className={`bg-primary absolute top-0 left-0 sm:h-full w-full inset-[40px] h-full rounded-[4px] sm:pt-0 sm:px-0 outline-none`}
    >
      <Header lang={false} />
      <div className="max-w-[1440px] mx-auto sm:px-8 md:px-[100px] px-[200px]">
        <div className="flex justify-between">
          <div className="font-roboto-bold mt-[81px] text-base text-white">
            Select your preferred language
          </div>
          <div className="w-[42px] h-[42px] rounded-[50%] mt-[20px] hover:bg-secondary flex justify-center items-center z-[12]">
            <img
              className="cursor-pointer"
              src="/icons/close-white.svg"
              onClick={() => {
                onClose();
                document.body.style.overflow = "auto";
              }}
              alt="close"
            />
          </div>
        </div>
        <div className="flex mt-[70px]">
          <div className="bg-secondary w-[208px] h-[46px] flex items-center  font-med text-[14px] pl-[16px]  text-white rounded-lg">
            English
          </div>
          <div className="font-med flex items-center ml-[51px] text-[14px] text-white">
            Deutsch
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default LanguageModal;
