import { Header } from "components";
import Footer from "components/Footer/Footer";
import React from "react";

function HomeLayout({ children }) {
  return (
    <div className="bg-primary">
      <Header isDownload={false} />
      {children}
      <Footer />
    </div>
  );
}

export default HomeLayout;
