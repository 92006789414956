import React, { useEffect, useState } from "react";
import HelpCard from "utils/helpCard";
import { useNavigate } from "react-router-dom";
import { HelpCenterTitles } from "utils/constants";

const Notifications3 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  const [isThumbUpActive, setIsThumbUpActive] = useState(false);
  const [isThumbDownActive, setIsThumbDownActive] = useState(false);

  const handleThumbUpClick = () => {
    setIsThumbUpActive(!isThumbUpActive);
    setIsThumbDownActive(false);
  };

  const handleThumbDownClick = () => {
    setIsThumbDownActive(!isThumbDownActive);
    setIsThumbUpActive(false);
  };

  return (
    <div className="bg-primary h-max">
      <div className="max-screen-1440 mx-auto px-[28px] sm:px-[18px] md:px-[23px]">
        <div className="sm:block hidden text-white text-[24px] font-medium pt-[14px] flex items-center">
          <div
            className="flex items-center gap-2 mb-4"
            onClick={() => navigate("/help/notifications")}
          >
            <img src="/icons/left.svg" className="z-[12]" alt="left-back" />
            Notifications
          </div>
        </div>
        <div className="sm:hidden block text-white text-[50px] font-roboto-bold pt-[50px] flex items-center gap-8">
          <img
            src="/images/help/notifications-detail.svg"
            alt="notifications-detail"
          />
          Notifications
        </div>
        <div className="mt-10 sm:mt-4 sm:w-full flex max-w-[1416px] w-full grid grid-cols-12">
          <div className="col-span-9 md:col-span-12 sm:col-span-12">
            <div className="w-full mx-auto mb-10 sm:mb-4">
              <div className="w-full bg-transparent rounded shadow-md">
                <div className="font-bold text-[24px] text-white w-full text-left flex justify-between items-center pb-8 sm:pb-4 border-b border-b-1 border-[#464646]">
                  {HelpCenterTitles.notifications[2].title}
                </div>
                <div
                  className={`mt-8 pb-8 sm:mt-4 sm:pb-[56px] border-b border-b-1 border-[#464646]`}
                >
                  <div className="text-white text-[24px] sm:text-[16px]">
                    <p className="font-normal">
                      Yes, many apps provide the option to customize and turn
                      off specific types of notifications based on your
                      preferences. To manage your notification settings and
                      disable specific types of notifications, follow these
                      general steps:
                    </p>
                    <div className="mt-4">
                      <div className="font-bold">1. Sign In:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Ensure you are signed in to your account on the app.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">2. Navigate to Settings:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Locate the "Settings" or "Account Settings" option
                        within the app. This is often found in the profile
                        section or a dedicated settings menu.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        3. Access Notification Preferences:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Look for a specific section related to notifications.
                        This might be labelled as "Notification Preferences,"
                        "Alerts," or something similar.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        4. Review Notification Types:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Check the different types of notifications available.
                        Identify the ones you want to turn off.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">5. Toggle Switches Off:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        For each notification type you want to disable, use
                        toggle switches or checkboxes to turn off the
                        notifications. This usually means sliding the switch to
                        the "off" position.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        6. Customize Additional Settings (If Available):
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Explore additional settings that might allow you to
                        further customize your notification preferences. This
                        could include setting the frequency of notifications or
                        adjusting time settings.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">7. Save Changes:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        After customizing your notification preferences, save
                        your changes. Look for a "Save" or "Update" button
                        within the notification settings.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        8. Check In-App Notification Center:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Some apps have an in-app notification centre where you
                        can review a history of notifications. Check if there's
                        an option to manage or turn off specific notifications
                        from this centre.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        9. Device Settings (Optional):
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Depending on your device (iOS, Android, etc.), you may
                        also have device-specific notification settings. Check
                        your device settings to manage notifications at a system
                        level.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        10. Test Settings (Optional):
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        If the app provides a test option, use it to ensure that
                        the notifications you turned off are no longer
                        appearing.
                      </div>
                    </div>
                    <div className="mt-4 font-normal">
                      If the app provides a test option, use it to ensure that
                      the notifications you turned off are no longer appearing.
                    </div>
                  </div>
                  <div className="text-[#979797] text-[16px] mt-[108px] sm:mt-8 sm:text-[10px] ">
                    Was this information helpful?
                  </div>
                  <div className="flex justify-between items-center w-[120px] sm:w-[75px] mt-4">
                    <img
                      src={
                        isThumbUpActive
                          ? "/images/help/thumbup_active.svg"
                          : "/images/help/thumbup_normal.svg"
                      }
                      alt="thumbup"
                      onClick={handleThumbUpClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                    <img
                      src={
                        isThumbDownActive
                          ? "/images/help/thumbdown_active.svg"
                          : "/images/help/thumbdown_normal.svg"
                      }
                      alt="thumbdown"
                      onClick={handleThumbDownClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                  </div>
                </div>
              </div>
              <div className="text-[#0F69FE] text-[24px] mt-[80px] sm:mt-4 sm:text-[16px]">
                More Help
              </div>
              <div onClick={() => navigate(`/help/notifications/1`)}>
                <HelpCard title={HelpCenterTitles.notifications[0].title} />
              </div>
              <div onClick={() => navigate(`/help/notifications/2`)}>
                <HelpCard title={HelpCenterTitles.notifications[1].title} />
              </div>
              <div onClick={() => navigate(`/help/notifications/4`)}>
                <HelpCard title={HelpCenterTitles.notifications[3].title} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications3;
