import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import PublicLayout from "layout/PublicLayout";
import GetStarted1 from "./getstarted1";
import GetStarted2 from "./getstarted2";
import GetStarted3 from "./getstarted3";
import GetStarted4 from "./getstarted4";
import GetStarted5 from "./getstarted5";

const SingleHelpGetStarted = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams();
  return (
    <PublicLayout>
      {id === "1" ? (
        <GetStarted1 />
      ) : id === "2" ? (
        <GetStarted2 />
      ) : id === "3" ? (
        <GetStarted3 />
      ) : id === "4" ? (
        <GetStarted4 />
      ) : id === "5" ? (
        <GetStarted5 />
      ) : (
        <></>
      )}
    </PublicLayout>
  );
};

export default SingleHelpGetStarted;
