import React from "react";

function Arrow(props) {
  const { className, onClick, type, team } = props;
  return (
    <div className={className} onClick={onClick}>
      <img
        src={`/images/${team ? "white-arrows" : "arrows"}/${
          type === "prev" ? "left-arrow.svg" : "right-arrow.svg"
        }`}
        className="sm:w-[16px] sm:h-[16px]"
        alt="arrow"
      />
    </div>
  );
}

export default Arrow;
