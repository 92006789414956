import React, { useEffect } from "react";
import SearchBox from "components/searchbox";
import { useNavigate } from "react-router-dom";
import PublicLayout from "layout/PublicLayout";

const HelpPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  return (
    <div className="bg-primary h-max">
      <PublicLayout>
        {/* <div className="max-screen-1440 mx-auto mx-10 sm:mx-4 md:mx-6"> */}
        <div className="max-screen-1440 mx-auto px-[28px] sm:px-[18px] md:px-[23px]">
          <div className="text-white text-[50px] sm:text-[24px] sm:font-medium font-roboto-bold mt-[50px] sm:mt-[14px]">
            Help center
          </div>
          <div className="mt-10 sm:mt-4">
            <div className="text-white text-[32px] sm:text-[16px] font-medium text-center sm:text-left">
              Hi! How can we help you?
            </div>
            <div className="mt-8 sm:mt-4">
              <SearchBox />
            </div>
          </div>
          <div className="text-[24px] sm:text-[16px] text-center text-light-green font-roboto-bold flex items-center justify-center mt-[80px] sm:mt-8 mb-8 sm:mb-4">
            Popular help resources
          </div>
          <div className="text-[24px] sm:text-[10px] font-roboto text-white flex justify-center items-center mb-[80px] sm:mb-8">
            <div className="grid grid-cols-12 w-[780px] h-[511px] sm:h-max gap-[16px]">
              <div
                className="text-center font-semibold sm:h-[30vw] col-span-4 w-full h-full border border-white border-[1px] rounded-[10px] flex items-center justify-center cursor-pointer"
                onClick={() => navigate("getstarted")}
              >
                <div>
                  <div className="flex items-center justify-center">
                    <img
                      src="/images/help/get_started.svg"
                      alt="get_started"
                      className="sm:w-[22px]"
                    />
                  </div>
                  <div>Get started</div>
                </div>
              </div>
              <div
                className="text-center font-semibold sm:h-[30vw] col-span-4 w-full h-full border border-white border-[1px] rounded-[10px] flex items-center justify-center cursor-pointer"
                onClick={() => navigate("notifications")}
              >
                <div>
                  <div className="flex items-center justify-center">
                    <img
                      src="/images/help/notifications.svg"
                      alt="notifications"
                      className="sm:w-[22px]"
                    />
                  </div>
                  <div>Notifications</div>
                </div>
              </div>
              <div
                className="text-center font-semibold sm:h-[30vw] col-span-4 w-full h-full border border-white border-[1px] rounded-[10px] flex items-center justify-center cursor-pointer"
                onClick={() => navigate("security")}
              >
                <div>
                  <div className="flex items-center justify-center">
                    <img
                      src="/images/help/security.svg"
                      alt="security"
                      className="sm:w-[22px]"
                    />
                  </div>
                  <div>Security</div>
                </div>
              </div>
              <div
                className="text-center font-semibold sm:h-[30vw] col-span-4 w-full h-full border border-white border-[1px] rounded-[10px] flex items-center justify-center cursor-pointer"
                onClick={() => navigate("markets")}
              >
                <div>
                  <div className="flex items-center justify-center">
                    <img
                      src="/images/help/markets.svg"
                      alt="markets"
                      className="sm:h-[21px]"
                    />
                  </div>
                  <div>Markets</div>
                </div>
              </div>
              <div
                className="text-center font-semibold sm:h-[30vw] col-span-4 w-full h-full border border-white border-[1px] rounded-[10px] flex items-center justify-center cursor-pointer"
                onClick={() => navigate("community")}
              >
                <div>
                  <div className="flex items-center justify-center">
                    <img
                      src="/images/help/community.svg"
                      alt="community"
                      className="sm:w-[22px]"
                    />
                  </div>
                  <div>Community</div>
                </div>
              </div>
              <div
                className="text-center font-semibold sm:h-[30vw] col-span-4 w-full h-full border border-white border-[1px] rounded-[10px] flex items-center justify-center cursor-pointer"
                onClick={() => navigate("watchlist")}
              >
                <div>
                  <div className="flex items-center justify-center">
                    <img
                      src="/images/help/watchlist.svg"
                      alt="watchlist"
                      className="sm:w-[22px]"
                    />
                  </div>
                  <div>Watchlist</div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center text-white sm:text-left">
            <div className="font-semibold text-[32px] sm:text-[16px] ">
              Still looking for answers?
            </div>
            <div className="font-normal text-[24px] sm:text-[10px] sm:text-[#979797] mt-8 sm:mt-4">
              We will respond as soon as possible by email
            </div>
          </div>
          <div className="flex items-center justify-center mt-[48px] sm:mt-[24px] pb-[120px] sm:pb-[96px]">
            <button
              className="justify-center w-[514px] bg-white mt-[60px] md:mt-[42px] md:h-[41px] h-[56px] md:w-[390px] sm:h-[42px] border sm:mt-[21px] sm:mx-auto border-black text-[#040B11] font-reg flex sm:justify-center items-center text-[24px] sm:text-[17px] rounded-[39px] relative hover:text-white hover:bg-light-green hover:border-light-green"
              onClick={() => navigate("/help/contact")}
            >
              <span className="font-roboto-bold text-[24px] md:text-[17px] sm:text-[17px]">
                Contact
              </span>
            </button>
          </div>
        </div>
      </PublicLayout>
    </div>
  );
};

export default HelpPage;
