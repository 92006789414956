import React, { useEffect, useState } from "react";
import HelpCard from "utils/helpCard";
import { useNavigate } from "react-router-dom";
import { HelpCenterTitles } from "utils/constants";

const Community1 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  const [isThumbUpActive, setIsThumbUpActive] = useState(false);
  const [isThumbDownActive, setIsThumbDownActive] = useState(false);

  const handleThumbUpClick = () => {
    setIsThumbUpActive(!isThumbUpActive);
    setIsThumbDownActive(false);
  };

  const handleThumbDownClick = () => {
    setIsThumbDownActive(!isThumbDownActive);
    setIsThumbUpActive(false);
  };

  return (
    <div className="bg-primary h-max">
      <div className="max-screen-1440 mx-auto px-[28px] sm:px-[18px] md:px-[23px]">
        <div className="sm:block hidden text-white text-[24px] font-medium pt-[14px] flex items-center">
          <div
            className="flex items-center gap-2 mb-4"
            onClick={() => navigate("/help/community")}
          >
            <img src="/icons/left.svg" className="z-[12]" alt="left-back" />
            Community
          </div>
        </div>
        <div className="sm:hidden block text-white text-[50px] font-roboto-bold pt-[50px] flex items-center gap-8">
          <img src="/images/help/community-detail.svg" alt="community-detail" />
          Community
        </div>
        <div className="mt-10 sm:mt-4 sm:w-full flex max-w-[1416px] w-full grid grid-cols-12">
          <div className="col-span-9 md:col-span-12 sm:col-span-12">
            <div className="w-full mx-auto mb-10 sm:mb-4">
              <div className="w-full bg-transparent rounded shadow-md">
                <div className="font-bold text-[24px] text-white w-full text-left flex justify-between items-center pb-8 sm:pb-4 border-b border-b-1 border-[#464646]">
                  {HelpCenterTitles.community[0].title}
                </div>
                <div
                  className={`mt-8 pb-8 sm:mt-4 sm:pb-[56px] border-b border-b-1 border-[#464646]`}
                >
                  <div className="text-white text-[24px] sm:text-[16px]">
                    <p className="font-normal">
                      To participate in community discussions within the
                      Capiwise app, you can follow these general steps. Keep in
                      mind that the specific features and navigation may vary
                      based on the design of the app:
                    </p>
                    <div className="mt-4">
                      <div className="font-bold">1. Community Section:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Look for a dedicated "Community" section or tab within
                        the Capiwise app. This is where you can find and engage
                        in discussions with other users.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">2. Homepage or Dashboard:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Check the homepage or dashboard of the Capiwise app.
                        Some apps feature a community feed directly on the main
                        screen, showcasing the latest discussions and posts.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        3. Explore or Discover Section:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Navigate to an "Explore" or "Discover" section within
                        the app. This area may highlight trending discussions,
                        popular posts, or recent community activity.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">4. Forums or Topics:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Explore specific forums or topics of interest. Many apps
                        organize community discussions into forums or categories
                        based on themes such as general market discussions,
                        company-specific discussions, investment strategies, and
                        more.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        5. Post a Comment or Reply:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Once you find a discussion or post that interests you,
                        look for options to post a comment or reply. This is
                        typically done by tapping or clicking on the post and
                        finding a "comment" or "reply" button.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        6. Start a New Discussion:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        If you have a topic you'd like to discuss, check for
                        options to start a new discussion. This is often found
                        in the community section and allows you to initiate
                        conversations with other users.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">7. Like or Upvote Posts:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Many apps have a feature to like or upvote posts that
                        you find interesting or valuable. This not only shows
                        appreciation but can also increase the visibility of
                        popular posts within the community.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        8. Follow Users or Topics:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Consider following other users whose contributions you
                        find valuable. Some apps also allow you to follow
                        specific topics or forums to receive updates on related
                        discussions.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        9. Share Insights and Information:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Contribute to discussions by sharing your insights,
                        asking questions, or providing information that might be
                        relevant to the community. Engaging in a constructive
                        manner enhances the overall community experience.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">10. Notifications:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Configure your notification settings to receive alerts
                        about new replies, mentions, or updates in discussions
                        you're participating in. This helps you stay informed
                        and engaged.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">11. Community Guidelines:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Familiarize yourself with the community guidelines or
                        rules provided by Capiwise. These guidelines outline
                        acceptable behaviour and help maintain a positive and
                        respectful community environment.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        12. Report Inappropriate Content:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        If you come across inappropriate content or behaviour,
                        use the app's reporting feature to bring it to the
                        attention of moderators or administrators. This helps
                        maintain a healthy and supportive community.
                      </div>
                    </div>
                    <div className="mt-4 font-normal">
                      Remember to refer to the Capiwise app's documentation or
                      help centre for specific guidance on participating in
                      community discussions. The app's help centre provides
                      valuable information on features, guidelines, and best
                      practices for engaging in the community effectively.
                    </div>
                  </div>
                  <div className="text-[#979797] text-[16px] mt-[108px] sm:mt-8 sm:text-[10px] ">
                    Was this information helpful?
                  </div>
                  <div className="flex justify-between items-center w-[120px] sm:w-[75px] mt-4">
                    <img
                      src={
                        isThumbUpActive
                          ? "/images/help/thumbup_active.svg"
                          : "/images/help/thumbup_normal.svg"
                      }
                      alt="thumbup"
                      onClick={handleThumbUpClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                    <img
                      src={
                        isThumbDownActive
                          ? "/images/help/thumbdown_active.svg"
                          : "/images/help/thumbdown_normal.svg"
                      }
                      alt="thumbdown"
                      onClick={handleThumbDownClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                  </div>
                </div>
              </div>
              <div className="text-[#0F69FE] text-[24px] mt-[80px] sm:mt-4 sm:text-[16px]">
                More Help
              </div>
              <div onClick={() => navigate(`/help/community/2`)}>
                <HelpCard title={HelpCenterTitles.community[1].title} />
              </div>
              <div onClick={() => navigate(`/help/community/3`)}>
                <HelpCard title={HelpCenterTitles.community[2].title} />
              </div>
              <div onClick={() => navigate(`/help/community/4`)}>
                <HelpCard title={HelpCenterTitles.community[3].title} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Community1;
