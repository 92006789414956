import React, { useEffect, useState } from "react";
import HelpCard from "utils/helpCard";
import { useNavigate } from "react-router-dom";
import { HelpCenterTitles } from "utils/constants";

const Watchlist2 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  const [isThumbUpActive, setIsThumbUpActive] = useState(false);
  const [isThumbDownActive, setIsThumbDownActive] = useState(false);

  const handleThumbUpClick = () => {
    setIsThumbUpActive(!isThumbUpActive);
    setIsThumbDownActive(false);
  };

  const handleThumbDownClick = () => {
    setIsThumbDownActive(!isThumbDownActive);
    setIsThumbUpActive(false);
  };

  return (
    <div className="bg-primary h-max">
      <div className="max-screen-1440 mx-auto px-[28px] sm:px-[18px] md:px-[23px]">
        <div className="sm:block hidden text-white text-[24px] font-medium pt-[14px] flex items-center">
          <div
            className="flex items-center gap-2 mb-4"
            onClick={() => navigate("/help/watchlist")}
          >
            <img src="/icons/left.svg" className="z-[12]" alt="left-back" />
            Watchlist
          </div>
        </div>
        <div className="sm:hidden block text-white text-[50px] font-roboto-bold pt-[50px] flex items-center gap-8">
          <img src="/images/help/watchlist-detail.svg" alt="watchlist-detail" />
          Watchlist
        </div>
        <div className="mt-10 sm:mt-4 sm:w-full flex max-w-[1416px] w-full grid grid-cols-12">
          <div className="col-span-9 md:col-span-12 sm:col-span-12">
            <div className="w-full mx-auto mb-10 sm:mb-4">
              <div className="w-full bg-transparent rounded shadow-md">
                <div className="font-bold text-[24px] text-white w-full text-left flex justify-between items-center pb-8 sm:pb-4 border-b border-b-1 border-[#464646]">
                  {HelpCenterTitles.watchlist[1].title}
                </div>
                <div
                  className={`mt-8 pb-8 sm:mt-4 sm:pb-[56px] border-b border-b-1 border-[#464646]`}
                >
                  <div className="text-white text-[24px] sm:text-[16px]">
                    <p className="font-normal">
                      Certainly! Here's a response for the help centre of your
                      project regarding adding stocks or ETFs to the watchlist
                      from their performance summary within the Capiwise app:
                    </p>
                    <div className="mt-4">
                      <div className="font-bold">
                        Adding Stocks or ETFs to Your Watchlist
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        At Capiwise, we've made it easy for users to track and
                        monitor stocks or ETFs by adding them to a personalized
                        watchlist. Here's a step-by-step guide on how to add
                        stocks or ETFs to your watchlist directly from their
                        performance summary:
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        1. Navigate to the Performance Summary:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Access the performance summary of the stock or ETF
                        you're interested in by searching for it in the app and
                        clicking on its profile.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        2. Locate the Watchlist Feature:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Within the performance summary page, look for a
                        dedicated section or button related to the watchlist.
                        This feature is often prominently displayed to enable
                        quick and convenient access.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">3. Add to Watchlist:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Click on the "Add to Watchlist" button or icon within
                        the performance summary section. This action will prompt
                        the app to add the selected stock or ETF to your
                        personalized watchlist.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">4. Confirmation Prompt:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        You may receive a confirmation prompt to confirm the
                        addition to your watchlist. Confirm the action to ensure
                        the stock or ETF is successfully added.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        5. Verify Watchlist Addition:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        After confirming, verify that the stock or ETF has been
                        added to your watchlist. You can do this by navigating
                        to your profile or watchlist section within the app.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">6. Manage Your Watchlist:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Explore the watchlist management features to organize
                        and customize your watchlist. Some apps allow you to
                        create folders, set alerts, and prioritize certain
                        assets for easier tracking.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">7. Remove from Watchlist:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        If you ever wish to remove a stock or ETF from your
                        watchlist, revisit the performance summary or your
                        watchlist section and look for an option to "Remove" or
                        "Unwatch" the asset.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        8. Receive Watchlist Alerts:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Configure your notification settings to receive alerts
                        related to changes in the performance of assets on your
                        watchlist. This keeps you informed about developments
                        that matter to you.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        9. Compare with Watchlist:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Some apps offer a feature to compare the performance of
                        assets in your watchlist. Take advantage of this tool to
                        assess how different assets are performing relative to
                        each other.
                      </div>
                    </div>
                    <div className="mt-4 font-normal">
                      By following these steps, you can seamlessly add stocks or
                      ETFs to your watchlist directly from their performance
                      summary within the Capiwise app. For further assistance or
                      specific details, consult our user guide or{" "}
                      <u
                        className="text-[#0F69FE] cursor-pointer"
                        onClick={() => navigate("/contact")}
                      >
                        contact our support team.
                      </u>
                    </div>
                  </div>
                  <div className="text-[#979797] text-[16px] mt-[108px] sm:mt-8 sm:text-[10px] ">
                    Was this information helpful?
                  </div>
                  <div className="flex justify-between items-center w-[120px] sm:w-[75px] mt-4">
                    <img
                      src={
                        isThumbUpActive
                          ? "/images/help/thumbup_active.svg"
                          : "/images/help/thumbup_normal.svg"
                      }
                      alt="thumbup"
                      onClick={handleThumbUpClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                    <img
                      src={
                        isThumbDownActive
                          ? "/images/help/thumbdown_active.svg"
                          : "/images/help/thumbdown_normal.svg"
                      }
                      alt="thumbdown"
                      onClick={handleThumbDownClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                  </div>
                </div>
              </div>
              <div className="text-[#0F69FE] text-[24px] mt-[80px] sm:mt-4 sm:text-[16px]">
                More Help
              </div>
              <div onClick={() => navigate(`/help/watchlist/1`)}>
                <HelpCard title={HelpCenterTitles.watchlist[0].title} />
              </div>
              <div onClick={() => navigate(`/help/watchlist/3`)}>
                <HelpCard title={HelpCenterTitles.watchlist[2].title} />
              </div>
              <div onClick={() => navigate(`/help/watchlist/4`)}>
                <HelpCard title={HelpCenterTitles.watchlist[3].title} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Watchlist2;
