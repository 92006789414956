import React, { useEffect, useState } from "react";
import HelpCard from "utils/helpCard";
import { useNavigate } from "react-router-dom";
import { HelpCenterTitles } from "utils/constants";

const Markets1 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  const [isThumbUpActive, setIsThumbUpActive] = useState(false);
  const [isThumbDownActive, setIsThumbDownActive] = useState(false);

  const handleThumbUpClick = () => {
    setIsThumbUpActive(!isThumbUpActive);
    setIsThumbDownActive(false);
  };

  const handleThumbDownClick = () => {
    setIsThumbDownActive(!isThumbDownActive);
    setIsThumbUpActive(false);
  };

  return (
    <div className="bg-primary h-max">
      <div className="max-screen-1440 mx-auto px-[28px] sm:px-[18px] md:px-[23px]">
        <div className="sm:block hidden text-white text-[24px] font-medium pt-[14px] flex items-center">
          <div
            className="flex items-center gap-2 mb-4"
            onClick={() => navigate("/help/markets")}
          >
            <img src="/icons/left.svg" className="z-[12]" alt="left-back" />
            Markets
          </div>
        </div>
        <div className="sm:hidden block text-white text-[50px] font-roboto-bold pt-[50px] flex items-center gap-8">
          <img src="/images/help/markets-detail.svg" alt="markets-detail" />
          Markets
        </div>
        <div className="mt-10 sm:mt-4 sm:w-full flex max-w-[1416px] w-full grid grid-cols-12">
          <div className="col-span-9 md:col-span-12 sm:col-span-12">
            <div className="w-full mx-auto mb-10 sm:mb-4">
              <div className="w-full bg-transparent rounded shadow-md">
                <div className="font-bold text-[24px] text-white w-full text-left flex justify-between items-center pb-8 sm:pb-4 border-b border-b-1 border-[#464646]">
                  {HelpCenterTitles.markets[0].title}
                </div>
                <div
                  className={`mt-8 pb-8 sm:mt-4 sm:pb-[56px] border-b border-b-1 border-[#464646]`}
                >
                  <div className="text-white text-[24px] sm:text-[16px]">
                    <p className="font-normal">
                      To find trending companies within the Capiwise app, follow
                      these general steps. Keep in mind that the specific
                      features and navigation may vary based on the design of
                      the Capiwise app:
                    </p>
                    <div className="mt-4">
                      <div className="font-bold">1. Homepage or Dashboard:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Check Capiwise's homepage or dashboard. Often, trending
                        companies are highlighted in a dedicated section that
                        showcases popular or actively discussed stocks.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        2. Explore or Discover Section:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Navigate to an "Explore" or "Discover" section within
                        the Capiwise app. This area may feature trending stocks,
                        recent market movers, or companies with notable
                        activity.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        3. Trending or Popular Lists:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Look for lists labelled as "Trending," "Popular," or
                        "Hot Stocks" within the Capiwise app. These lists
                        typically showcase companies that are currently gaining
                        attention or experiencing significant price movements.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">4. Market Overview:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Explore a market overview section in the Capiwise app
                        where you may find information about top gainers, top
                        losers, or stocks with the highest trading volumes. This
                        can provide insights into trending companies.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">5. Search Functionality:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Use the Capiwise app's search functionality to look for
                        a specific list or filter related to trending companies.
                        Some apps allow users to filter stocks based on
                        popularity or recent performance.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        6. Watchlist Recommendations:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        If you have a personalized watchlist in the Capiwise
                        app, the app may provide recommendations for additional
                        companies based on current trends or market activity.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        7. Community or Social Features:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Check if the Capiwise app has community or social
                        features where users discuss and share information about
                        stocks. Trending companies may be highlighted within
                        these community discussions.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        8. News and Analysis Section:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Explore the Capiwise app's news and analysis section.
                        News articles or analysis reports often feature
                        information about companies that are currently trending
                        or making headlines.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        9. Market Insights or Trends:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Look for sections or features within the Capiwise app
                        that provide market insights or trends. This could
                        include information on sectors gaining popularity or
                        specific industries experiencing increased investor
                        interest.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">10. Featured Companies:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Some apps, including Capiwise, feature specific
                        companies based on market events, earnings
                        announcements, or other factors. Check for a section
                        highlighting featured or spotlighted companies.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">11. Customizable Filters:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        If the Capiwise app offers customizable filters, use
                        them to narrow down stocks based on criteria such as
                        popularity, recent performance, or social media
                        mentions.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        12. Top Picks or Recommendations:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Explore sections within the Capiwise app that provide
                        top picks or recommendations from the app's experts or
                        algorithms. These picks often include trending
                        companies.
                      </div>
                    </div>
                    <div className="mt-4 font-normal">
                      Remember to refer to Capiwise's documentation or help
                      centre for specific guidance on finding trending
                      companies.
                    </div>
                  </div>
                  <div className="text-[#979797] text-[16px] mt-[108px] sm:mt-8 sm:text-[10px] ">
                    Was this information helpful?
                  </div>
                  <div className="flex justify-between items-center w-[120px] sm:w-[75px] mt-4">
                    <img
                      src={
                        isThumbUpActive
                          ? "/images/help/thumbup_active.svg"
                          : "/images/help/thumbup_normal.svg"
                      }
                      alt="thumbup"
                      onClick={handleThumbUpClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                    <img
                      src={
                        isThumbDownActive
                          ? "/images/help/thumbdown_active.svg"
                          : "/images/help/thumbdown_normal.svg"
                      }
                      alt="thumbdown"
                      onClick={handleThumbDownClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                  </div>
                </div>
              </div>
              <div className="text-[#0F69FE] text-[24px] mt-[80px] sm:mt-4 sm:text-[16px]">
                More Help
              </div>
              <div onClick={() => navigate(`/help/markets/2`)}>
                <HelpCard title={HelpCenterTitles.markets[1].title} />
              </div>
              <div onClick={() => navigate(`/help/markets/3`)}>
                <HelpCard title={HelpCenterTitles.markets[2].title} />
              </div>
              <div onClick={() => navigate(`/help/markets/4`)}>
                <HelpCard title={HelpCenterTitles.markets[3].title} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Markets1;
