import React, { useEffect, useState } from "react";
import HelpCard from "utils/helpCard";
import { useNavigate } from "react-router-dom";
import { HelpCenterTitles } from "utils/constants";

const Security2 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  const [isThumbUpActive, setIsThumbUpActive] = useState(false);
  const [isThumbDownActive, setIsThumbDownActive] = useState(false);

  const handleThumbUpClick = () => {
    setIsThumbUpActive(!isThumbUpActive);
    setIsThumbDownActive(false);
  };

  const handleThumbDownClick = () => {
    setIsThumbDownActive(!isThumbDownActive);
    setIsThumbUpActive(false);
  };

  return (
    <div className="bg-primary h-max">
      <div className="max-screen-1440 mx-auto px-[28px] sm:px-[18px] md:px-[23px]">
        <div className="sm:block hidden text-white text-[24px] font-medium pt-[14px] flex items-center">
          <div
            className="flex items-center gap-2 mb-4"
            onClick={() => navigate("/help/security")}
          >
            <img src="/icons/left.svg" className="z-[12]" alt="left-back" />
            Security
          </div>
        </div>
        <div className="sm:hidden block text-white text-[50px] font-roboto-bold pt-[50px] flex items-center gap-8">
          <img src="/images/help/security-detail.svg" alt="security-detail" />
          Security
        </div>
        <div className="mt-10 sm:mt-4 sm:w-full flex max-w-[1416px] w-full grid grid-cols-12">
          <div className="col-span-9 md:col-span-12 sm:col-span-12">
            <div className="w-full mx-auto mb-10 sm:mb-4">
              <div className="w-full bg-transparent rounded shadow-md">
                <div className="font-bold text-[24px] text-white w-full text-left flex justify-between items-center pb-8 sm:pb-4 border-b border-b-1 border-[#464646]">
                  {HelpCenterTitles.security[1].title}
                </div>
                <div
                  className={`mt-8 pb-8 sm:mt-4 sm:pb-[56px] border-b border-b-1 border-[#464646]`}
                >
                  <div className="text-white text-[24px] sm:text-[16px]">
                    <p className="font-normal">
                      To ensure the protection of personal information from
                      external threats, Capiwise employs a robust set of
                      security measures. While the specific details may vary,
                      here are general practices that investment platforms often
                      implement to safeguard users' personal information:
                    </p>
                    <div className="mt-4">
                      <div className="font-bold">1. Encryption Protocols:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Capiwise uses advanced encryption protocols, such as
                        Secure Socket Layer (SSL) or Transport Layer Security
                        (TLS), to secure the transmission of data between users'
                        devices and Capiwise servers. This encryption ensures
                        that sensitive information remains confidential during
                        transit.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">2. Secure Data Storage:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Personal information is stored in a secure manner, with
                        data-at-rest encryption to protect it even when stored
                        on Capiwise servers. This encryption prevents
                        unauthorized access to stored user data.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        3. Two-Factor Authentication (2FA):
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Capiwise may offer 2FA as an additional layer of
                        security. This involves users providing a secondary form
                        of verification, such as a code sent to their mobile
                        device, to access their accounts.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        4. Regular Security Audits:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Capiwise conducts regular security audits and
                        assessments to identify and address potential
                        vulnerabilities. This proactive approach helps ensure
                        that the platform's security measures are continually
                        updated to address emerging threats.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        5. Firewalls and Intrusion Detection Systems:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Capiwise employs firewalls and intrusion detection
                        systems to monitor and block unauthorized access
                        attempts. These measures help protect against external
                        threats attempting to breach the platform's security.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        6. User Authentication Controls:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Capiwise enforces strong user authentication controls,
                        including secure password policies and mechanisms to
                        detect and prevent suspicious login attempts. This
                        ensures that only authorized users can access personal
                        information.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">7. Access Controls:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Access to personal information within Capiwise is
                        restricted to authorized personnel on a need-to-know
                        basis. Access controls are in place to limit and monitor
                        access, reducing the risk of internal threats.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        8. Security Certifications and Compliance:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Capiwise adheres to industry-standard security
                        certifications and compliance measures. This may include
                        compliance with data protection regulations such as GDPR
                        or industry-specific standards.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        9. Regular Software Updates:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Capiwise keeps its software up to date with regular
                        updates and patches. This helps address known
                        vulnerabilities and enhances overall security against
                        external threats.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        10. Incident Response Plan:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Capiwise has an incident response plan in place to
                        quickly and effectively respond to security incidents.
                        This plan includes steps to mitigate the impact of a
                        security breach and protect users' personal information.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">11. Employee Training:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Employees at Capiwise undergo security training to raise
                        awareness about potential threats and best practices.
                        This helps create a security-aware culture within the
                        organization.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        12. External Security Partnerships:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Capiwise may collaborate with external security experts
                        and partners to stay informed about the latest threats
                        and ensure that its security measures are aligned with
                        industry best practices.
                      </div>
                    </div>
                    <div className="mt-4 font-normal">
                      It's important for users to review Capiwise's{" "}
                      <u
                        className="text-[#0F69FE] cursor-pointer"
                        onClick={() => navigate("/privacypolicy")}
                      >
                        privacy policy
                      </u>
                      ,{" "}
                      <u
                        className="text-[#0F69FE] cursor-pointer"
                        onClick={() => navigate("/terms")}
                      >
                        terms of service
                      </u>
                      , and security documentation for specific details on how
                      personal information is protected from external threats.
                    </div>
                  </div>
                  <div className="text-[#979797] text-[16px] mt-[108px] sm:mt-8 sm:text-[10px] ">
                    Was this information helpful?
                  </div>
                  <div className="flex justify-between items-center w-[120px] sm:w-[75px] mt-4">
                    <img
                      src={
                        isThumbUpActive
                          ? "/images/help/thumbup_active.svg"
                          : "/images/help/thumbup_normal.svg"
                      }
                      alt="thumbup"
                      onClick={handleThumbUpClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                    <img
                      src={
                        isThumbDownActive
                          ? "/images/help/thumbdown_active.svg"
                          : "/images/help/thumbdown_normal.svg"
                      }
                      alt="thumbdown"
                      onClick={handleThumbDownClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                  </div>
                </div>
              </div>
              <div className="text-[#0F69FE] text-[24px] mt-[80px] sm:mt-4 sm:text-[16px]">
                More Help
              </div>
              <div onClick={() => navigate(`/help/security/1`)}>
                <HelpCard title={HelpCenterTitles.security[0].title} />
              </div>
              <div onClick={() => navigate(`/help/security/3`)}>
                <HelpCard title={HelpCenterTitles.security[2].title} />
              </div>
              <div onClick={() => navigate(`/help/security/4`)}>
                <HelpCard title={HelpCenterTitles.security[3].title} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Security2;
