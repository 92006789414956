import React from "react";

function InputField({ label, errors, ...props }) {
  return (
    <>
      <div className="text-[24px] font-reg sm:text-17 text-black pb-[55px] md:pb-[36px] sm:pb-[8px]">
        {label}
      </div>
      <input
        {...props}
        className="w-full indent-[22px] text-base font-reg sm:indent-[18px] sm:text-17 border-solid border-light-green border-t-0 outline-none border-[2px] sm:h-[28px]"
      />
      {errors && <div className="text-red-500 text-12 mt-[2px]">{errors}</div>}
    </>
  );
}

export default InputField;
