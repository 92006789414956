export const learningItems = [
  {
    heading: "Introducing to Investing",
    image: "/images/background/learning-slick.png",
  },
  {
    heading: "Understanding Risk and Return",
    image: "/images/background/risk.png",
  },
  {
    heading: "Types of Investments",
    image: "/images/background/investment.png",
  },
  {
    heading: "Setting Investment Goals",
    image: "/images/background/goals.png",
  },
  {
    heading: "Creating a Diversified Portfolio",
    image: "/images/background/portfolio.png",
  },
  {
    heading: "Introduction to Stocks",
    image: "/images/background/stocks.png",
  },
];
export const books = [
  {
    path: "images/books/book.png",
    title:
      "One Up On Wall Street: How To Use What You Already Know To Make Money In The Market",
    para: "Lynch offers easy-to-follow advice for sorting out the long shots from the no-shots by reviewing a company’s financial statements...",
    rating: 4.6,
    link: "https://www.amazon.com.mx/One-Up-Wall-Street-Already/dp/B0C3CTXD69/ref=sr_1_1?keywords=One+Up+On+Wall+Street%3A+How+To+Use+What+You+Already+Know+To+Make+Money+In+The+Market&qid=1693594917&sr=8-1&ufe=app_do%3Aamzn1.fos.4e545b5e-1d45-498b-8193-a253464ffa47",
  },
  {
    path: "images/books/intelligent.png",
    title: "The Intelligent Investor: The Definitive Book on Value Investing",
    para: "The Intelligent Investor is a masterpiece of investment, and it’s considered the first work on value investing. Many consider it essential for...",
    rating: 4.7,
    link: "https://www.amazon.com.mx/Intelligent-Investor-REV-Ed/dp/0060555661/ref=sr_1_1?__mk_es_MX=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=HQ6I49XLXVVV&keywords=The+Intelligent+Investor%3A+The+Definitive+Book+on+Value+Investing&qid=1693594941&sprefix=one+up+on+wall+street+how+to+use+what+you+already+know+to+make+money+in+the+market%2Caps%2C240&sr=8-1&ufe=app_do%3Aamzn1.fos.4e545b5e-1d45-498b-8193-a253464ffa47",
  },

  {
    path: "images/books/security.png",
    title: "Security Analysis, Seventh Edition: Principles and Techniques",
    para: "This book features the ideas and methods of today’s masters of value investing, who discuss the influence of Graham and Dodd on today’s...",
    rating: 4.8,
    link: "https://www.amazon.com.mx/Security-Analysis-Seventh-Principles-Techniques-ebook/dp/B0BR8DFXSH/ref=sr_1_1?__mk_es_MX=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=MMIXE0SC6QMG&keywords=Security+Analysis%2C+Seventh+Edition%3A+Principles+and+Techniques&qid=1693594958&sprefix=security+analysis+seventh+edition+principles+and+techniques%2Caps%2C171&sr=8-1",
  },
  {
    path: "images/books/money.png",
    title:
      "The Psychology of Money: Timeless Lessons on Wealth, Greed, and Happiness",
    para: "In The Psychology of Money, award-winning author Morgan Housel shares 19 short stories exploring the strange ways people think about...",
    rating: 4.8,
    link: "https://www.amazon.com.mx/Psychology-Money-Timeless-Lessons-Happiness/dp/0857197681/ref=sr_1_1?__mk_es_MX=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=7CASZAJF7AW8&keywords=The+Psychology+of+Money%3A+Timeless+Lessons+on+Wealth%2C+Greed%2C+and+Happiness&qid=1693594976&sprefix=the+psychology+of+money+timeless+lessons+on+wealth+greed+and+happiness%2Caps%2C205&sr=8-1&ufe=app_do%3Aamzn1.fos.4e545b5e-1d45-498b-8193-a253464ffa47",
  },
  {
    path: "images/books/works.png",
    title: "How Money Works: The Facts Visually Explained",
    para: "DK's visual approach breaks new ground. In graphics, charts, and diagrams, How Money Works demystifies processes and answers the...",
    rating: 4.6,
    link: "https://www.amazon.com.mx/How-Money-Works-Visually-Explained/dp/1465444270/ref=sr_1_1?__mk_es_MX=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=1SXZTRBZD53Q4&keywords=how+money+works&qid=1693595026&sprefix=how+money+works%2Caps%2C125&sr=8-1&ufe=app_do%3Aamzn1.fos.4e545b5e-1d45-498b-8193-a253464ffa47",
  },
  {
    path: "images/books/broke.png",
    title:
      "Broke Millennial Takes on Investing: A Beginner's Guide to Leveling Up Your Money",
    para: "Erin Lowry delivers all of the investment basics in one easy-to-digest package. Tackling topics ranging from common terminology to how to...",
    rating: 4.6,
    link: "https://www.amazon.com.mx/Broke-Millennial-Takes-Investing-Beginners/dp/0143133640/ref=sr_1_1?__mk_es_MX=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=2786YA1YUNP34&keywords=Broke+Millennial+Takes+on+Investing%3A+A+Beginner%27s+Guide+to+Leveling+Up+Your+Money&qid=1693595071&sprefix=how+money+broke+millennial+takes+on+investing+a+beginner%27s+guide+to+leveling+up+your+money%2Caps%2C251&sr=8-1&ufe=app_do%3Aamzn1.fos.4e545b5e-1d45-498b-8193-a253464ffa47",
  },
];
export const courses = [
  {
    title: "Investing In Stocks The Complete Course",
    para: "Top 1% Instructor & Millionaire Investor. Invest & Trade. Have complete understanding and confidence when investing in the Stock Market.",
    path: "/images/courses/course1.png",
    rating: 4.5,
    icon: "/images/udemy.svg",
    link: "https://www.udemy.com/course/investing-in-stocks/",
    source: "Udemy",
  },
  {
    title: "Practical Guide to Trading Specialization",
    para: "Understand the interconnectedness of the global capital markets with a practical emphasis on trading. The interweaving of several capital...",
    path: "/images/courses/course2.png",
    rating: 4.5,
    icon: "/images/coursera.svg",
    link: "https://www.coursera.org/specializations/financial-instruments-trading-investing",
    source: " Coursera",
  },
  {
    title: "Financial Markets",
    para: "An overview of the ideas, methods, and institutions that permit human society to manage risks and foster enterprise.  Emphasis...",
    path: "/images/courses/course3.png",
    rating: 4.8,
    icon: "/images/coursera.svg",
    link: "https://www.coursera.org/learn/financial-markets-global",
    source: " Coursera",
  },
  {
    title: "Complete Investing Course (Stocks, ETFs, Index/Mutual...",
    para: "By the end of this course you will be able to start your investing journey and start growing your wealth. You'll start off by learning everything...",
    path: "/images/courses/course4.png",
    rating: 4.6,
    icon: "/images/udemy.svg",
    link: "https://www.udemy.com/course/complete-investing-course-stocks-etfs-index-mutual-funds/",
    source: "Udemy",
  },
  {
    title: "Investment Banking: Financial Analysis and Valuation",
    para: "This course will provide students the key building blocks required for a career in investment banking, valuation, and other...",
    path: "/images/courses/course5.png",
    rating: 4.3,
    icon: "/images/coursera.svg",
    link: "https://www.coursera.org/learn/investment-banking-financial-analysis-valuation",
    source: " Coursera",
  },
  {
    title: "Innovations in Investment Technology: Artificial Intelligence",
    para: "Explore the evolution of AI investing and online wealth management. Investing and managing your wealth online has never been easier, but...",
    path: "/images/courses/course6.png",
    rating: 4.7,
    icon: "/images/coursera.svg",
    link: "https://www.coursera.org/learn/invest-tech",
    source: " Coursera",
  },
];
export const staticCard = [
  {
    title: "Investment Strategies",
    path: "images/investment.svg",
  },
  {
    title: "Financial planning",
    path: "images/card/card2.svg",
  },
  {
    title: "Advanced Concepts",
    path: "images/card/card3.svg",
  },
  {
    title: "Economic Fundamentals",
    path: "images/card/card4.svg",
  },
  {
    title: "Personal Finance",
    path: "images/card/card5.svg",
  },
  {
    title: "Financial Literacy",
    path: "images/card/card6.svg",
  },
];

export const HelpCenterTitles = {
  getstarted: [
    {
      title: "What are the initial steps to build my personalized watchlist?",
    },
    {
      title:
        "Where can I find beginner-friendly resources to understand stock market basics?",
    },
    {
      title: "How can I start interacting with the app's investment community?",
    },
    {
      title:
        "What's the best way to begin tracking market trends and news within the app?",
    },
    {
      title: "How can I personalize my profile settings?",
    },
  ],
  notifications: [
    {
      title: "How do I manage my notification settings?",
    },
    {
      title: "What type of notifications will I receive?",
    },
    {
      title: "Can I turn off specific types of notifications?",
    },
    {
      title: "Will I receive notifications outside of trading hours?",
    },
  ],
  security: [
    {
      title: "How is my personal information protected on the app?",
    },
    {
      title:
        "How Capiwise protect my personal information from external threats?",
    },
    {
      title: "What should I do if I suspect unauthorized access to my account?",
    },
    {
      title: "Does the app store my financial data locally?",
    },
  ],
  markets: [
    {
      title: "Where can I find trending companies in the app?",
    },
    {
      title: "How are the most-viewed companies by the community determined?",
    },
    {
      title:
        "Where can I access information about the daily earnings of companies?",
    },
    {
      title: "How do I access the articles on the news?",
    },
  ],
  community: [
    {
      title: "How do I participate in the community discussions?",
    },
    {
      title: "Can I follow other investors or users?",
    },
    {
      title: "Are there guidelines for community interactions?",
    },
    {
      title:
        "Can I share my investment strategies or tips within the community?",
    },
  ],
  watchlist: [
    {
      title: "How can I view the performance summary of a stock or ETF?",
    },
    {
      title:
        "Can I add stocks or ETFs to my watchlist from their performance summary?",
    },
    {
      title: "Where can I find my watchlist within the app?",
    },
    {
      title: "How do I interpret the data table in my watchlist?",
    },
  ],
};
