import React, { useState } from "react";
import LaunchOffer from "components/Modal/LaunchOffer";
import CongratsModal from "components/Modal/CongratsModal";
// import { useNavigate } from "react-router-dom";
import StoreModal from "components/Modal/StoreModal";

function Section1() {
  const [open, setOpen] = useState(false);
  const [openCongrats, setOpenCongrats] = useState(false);
  // const navigate = useNavigate();
  const [openStore, setOpenStore] = useState(false);

  return (
    <>
      <LaunchOffer
        onClick={() => {
          setOpenCongrats(true);
          setOpen(false);
        }}
        open={open}
        onClose={() => setOpen(false)}
      />
      <StoreModal
        onClick={() => {
          setOpenStore(false);
        }}
        open={openStore}
        onClose={() => setOpenStore(false)}
      />
      <CongratsModal
        open={openCongrats}
        onClose={() => setOpenCongrats(false)}
      />
      <section className="">
        <div
          className="bg-no-repeat max-screen-1440 2xl:max-w-[3840px] bg-cover 3xl:bg-[url('../public/images/background/gradient-larger-screen1.png')] sm:bg-[url('../public/images/background/section1-bg-mbl.png')] bg-[url('../public/images/background/section1-bg.png')] h-max sm:h-max md:h-max pt-8 font-roboto"
          style={{
            backgroundPosition: "top -8rem center",
          }}
        >
          <div className="flex max-w-[1440px] mx-auto px-[28px] pt-[40px] sm:pt-[24px] grid grid-cols-12">
            <div className="sm:mt-[10px] md:mt-[60px] mt-[75px] tracking-tight sm:pt-0 sm:col-span-12 col-span-6">
              <div className="text-white font-roboto-bold text-[50px] md:text-[42px] lg:text-[50px] sm:text-center sm:text-[24px] w-full leading-[normal]">
                Get Started
                <br className="hidden md:block lg:block" /> with Capiwise
              </div>
              <div className="text-white sm:text-[17px] md:text-[17px] text-[24px] font-reg mb-[40px] w-[60%] md:w-[100%] sm:w-[100%] sm:text-center sm:mt-[17px] mt-[33px]">
                Every day{" "}
                <span className="text-light-green font-roboto-bold">
                  invest smarter, connect Better:
                </span>{" "}
                your gateway to global investment conversations.
              </div>
              {/* Google play */}
              <div className="max-w-[1440px] mx-auto sm:mt-1 sm:hidden">
                <div className="flex gap-x-[30px] items-center">
                  <div>
                    <div
                      className="border border-white rounded-[4px] sm:rounded-0 sm:border-none sm:mb-4 cursor-pointer"
                      onClick={() => setOpenStore(true)}
                    >
                      {/* <div> */}
                      <picture>
                        <source
                          media="(max-width:465px)"
                          srcSet="/images/gplay-mbl.png"
                        />
                        <img
                          src="/images/android.svg"
                          className="md:w-[120px] "
                          alt="googleplay"
                        />
                      </picture>
                    </div>
                    <div
                      className="border border-white rounded-[4px] sm:rounded-0 sm:border-none cursor-pointer mt-4 sm:mt-2"
                      onClick={() => setOpenStore(true)}
                    >
                      {/* <div> */}
                      <picture>
                        <source
                          media="(max-width:465px)"
                          srcSet="/images/appstore-mbl.png"
                        />
                        <img
                          src="/images/ios.svg"
                          className="md:w-[120px]"
                          alt="applestore"
                        />
                      </picture>
                    </div>
                  </div>
                  <img
                    src="/images/image23.png"
                    className="sm:hidden w-[108px] md:w-[80px]"
                    alt="qr"
                  />
                </div>
                <div className="mt-6 sm:text-center sm:mt-4 mb-[56px] md:mb-[80px] ">
                  <span className="font-roboto text-[16px] text-white font-normal">
                    Enjoy free access, no hidden fees
                  </span>
                </div>
                <div className="flex items-center justify-start sm:justify-center gap-2">
                  <svg
                    width="12"
                    height="14"
                    viewBox="0 0 12 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_1270_8964)">
                      <path
                        d="M12 10.2563V10.3656C11.988 10.3902 11.9733 10.414 11.9641 10.4397C11.6195 11.3859 11.0746 12.2242 10.4351 13.0111C10.0384 13.4991 9.58343 13.9078 8.9034 13.9989H8.55936C8.47975 13.9871 8.39985 13.9767 8.32052 13.9636C7.98481 13.9075 7.67009 13.7876 7.35709 13.6666C6.65752 13.3968 5.96024 13.3888 5.25405 13.6439C4.8807 13.7789 4.51021 13.9258 4.11501 13.9989H3.57036C3.21712 13.908 2.91361 13.7334 2.64113 13.5046C2.45029 13.3445 2.28129 13.165 2.12206 12.9775C0.919498 11.5608 0.197791 9.95528 0.0290762 8.13217C-0.0373174 7.4137 0.000909256 6.70482 0.238892 6.01592C0.563674 5.07576 1.12328 4.3053 2.05106 3.81593C2.70465 3.47106 3.39877 3.31259 4.14634 3.44972C4.60822 3.53429 5.04567 3.69358 5.47537 3.87012C5.86395 4.02968 6.24335 4.05185 6.63682 3.88052C6.87049 3.7787 7.11854 3.7059 7.36112 3.62324C8.0423 3.39114 8.73354 3.29152 9.45438 3.44397C10.2885 3.6205 11.0087 3.96482 11.5212 4.64058C11.6313 4.78564 11.6319 4.78756 11.4735 4.89184C11.101 5.13735 10.7662 5.42172 10.5207 5.78711C9.95765 6.62572 9.92949 7.5303 10.2221 8.45048C10.4991 9.32221 11.1602 9.87508 12 10.2563Z"
                        fill="white"
                      />
                      <path
                        d="M5.96882 2.94203C6.02429 1.88473 6.53532 1.0754 7.45362 0.495708C7.83474 0.255126 8.25437 0.0939175 8.70676 0.0159131C8.93497 -0.0234996 8.97263 -0.00187736 8.9663 0.221735C8.92894 1.53084 8.2857 2.48359 7.07595 3.08792C6.77129 3.24009 6.43041 3.27184 6.0858 3.24967C6.00072 3.2442 5.96479 3.21327 5.96824 3.13335C5.97112 3.06985 5.96882 3.00608 5.96882 2.94231V2.94203Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1270_8964">
                        <rect width="12" height="14" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span className="font-roboto text-[16px] sm:text-[8px] text-white font-normal">
                    Coming soon in App Store
                  </span>
                </div>
              </div>
            </div>
            <div className="sm:col-span-12 col-span-6 flex sm:justify-center justify-start sm:mt-2 md:mt-[60px] mt-[75px]">
              <img
                src="/images/iphone14pro-mock.png"
                alt="iphone mock"
                className="w-full sm:max-w-[68%] md:max-w-[78%] max-w-[74%] h-auto"
              />
            </div>
          </div>
          {/* mobile google play */}
          <div className="max-w-[1440px] mx-auto px-[28px] hidden sm:block sm:mt-1">
            <div className="flex gap-x-[30px] justify-center">
              <div>
                <div onClick={() => setOpenStore(true)}>
                  {/* <div> */}
                  <picture>
                    <source srcSet="/images/gplay-mbl.png" />
                    <img src="/images/android.svg" alt="googleplay" />
                  </picture>
                </div>
                <div className="mt-[24px]" onClick={() => setOpenStore(true)}>
                  {/* <div> */}
                  <picture>
                    <source srcSet="/images/appstore-mbl.png" />
                    <img
                      src="/images/ios.svg"
                      className="mt-4 sm:mt-1"
                      alt="applestore"
                    />
                  </picture>
                </div>
              </div>
              <img
                src="/images/image23.png"
                className="sm:hidden md:w-[80px]"
                alt="qr"
              />
            </div>
            <div className="mt-6 sm:text-center sm:mt-4 sm:mb-6">
              <span className="font-roboto text-[16px] text-white font-normal">
                Enjoy free access, no hidden fees
              </span>
            </div>
            <div className="flex items-center justify-start sm:justify-center gap-2 pb-6">
              <svg
                width="12"
                height="14"
                viewBox="0 0 12 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_1270_8964)">
                  <path
                    d="M12 10.2563V10.3656C11.988 10.3902 11.9733 10.414 11.9641 10.4397C11.6195 11.3859 11.0746 12.2242 10.4351 13.0111C10.0384 13.4991 9.58343 13.9078 8.9034 13.9989H8.55936C8.47975 13.9871 8.39985 13.9767 8.32052 13.9636C7.98481 13.9075 7.67009 13.7876 7.35709 13.6666C6.65752 13.3968 5.96024 13.3888 5.25405 13.6439C4.8807 13.7789 4.51021 13.9258 4.11501 13.9989H3.57036C3.21712 13.908 2.91361 13.7334 2.64113 13.5046C2.45029 13.3445 2.28129 13.165 2.12206 12.9775C0.919498 11.5608 0.197791 9.95528 0.0290762 8.13217C-0.0373174 7.4137 0.000909256 6.70482 0.238892 6.01592C0.563674 5.07576 1.12328 4.3053 2.05106 3.81593C2.70465 3.47106 3.39877 3.31259 4.14634 3.44972C4.60822 3.53429 5.04567 3.69358 5.47537 3.87012C5.86395 4.02968 6.24335 4.05185 6.63682 3.88052C6.87049 3.7787 7.11854 3.7059 7.36112 3.62324C8.0423 3.39114 8.73354 3.29152 9.45438 3.44397C10.2885 3.6205 11.0087 3.96482 11.5212 4.64058C11.6313 4.78564 11.6319 4.78756 11.4735 4.89184C11.101 5.13735 10.7662 5.42172 10.5207 5.78711C9.95765 6.62572 9.92949 7.5303 10.2221 8.45048C10.4991 9.32221 11.1602 9.87508 12 10.2563Z"
                    fill="white"
                  />
                  <path
                    d="M5.96882 2.94203C6.02429 1.88473 6.53532 1.0754 7.45362 0.495708C7.83474 0.255126 8.25437 0.0939175 8.70676 0.0159131C8.93497 -0.0234996 8.97263 -0.00187736 8.9663 0.221735C8.92894 1.53084 8.2857 2.48359 7.07595 3.08792C6.77129 3.24009 6.43041 3.27184 6.0858 3.24967C6.00072 3.2442 5.96479 3.21327 5.96824 3.13335C5.97112 3.06985 5.96882 3.00608 5.96882 2.94231V2.94203Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1270_8964">
                    <rect width="12" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span className="font-roboto text-[16px] sm:text-[8px] text-white font-normal">
                Coming soon in App Store
              </span>
            </div>
          </div>
          <div className="px-[16px] flex justify-center pt-[50px] sm:flex-col-reverse sm:pt-[41px] md:pt-[120px] pb-[160px] md:pb-[96px] sm:pb-[64px]">
            <div>
              <picture>
                <source
                  media="(max-width:465px)"
                  srcSet="/images/section2-mbl-img.png"
                />
                <img
                  src="/images/section2-img.png"
                  className="mx-auto"
                  alt="section2_main_img"
                />
              </picture>
            </div>
            <div className="text-white mt-[240px] sm:mt-0 md:ml-[36px] md:mt-[106px] md:w-[50%]  ml-[90px] sm:ml-0 sm:text-center">
              <div className="font-roboto-bold leading-normal text-bmd md:text-[28px] md:w-[80%] sm:text-17 sm:text-center w-[344px] sm:w-[100%]">
                From beginner to expert
              </div>
              <div className="w-[438px] md:text-sm font-reg mt-[60px] md:w-[80%] tracking-tight text-base sm:text-12 md:mt-[43px] sm:mt-[20px] sm:mx-auto sm:w-[97%] mb-[36px]">
                We break down the barriers of confusion and complexity and take
                you on a{" "}
                <span className="text-light-green">
                  journey to investment success.
                </span>{" "}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Section1;
