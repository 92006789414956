import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import Arrow from "components/SlickSlider/Arrow.jsx";

function Section4() {
  const settings = {
    dots: false,
    infinite: true,
    fade: true,
    speed: 500,
    nextArrow: <Arrow type="next" />,
    prevArrow: <Arrow type="prev" />,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(false);
      if (window.innerWidth < 1000) {
        setIsMobile(true);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const sliderImages = [
    {
      image: "/images/clients.png",
      title:
        "“It‘s a cool platform, I like to shift between light and dark moods, and the notification messages are so useful to let me know when beginning an opportunity to buy/sell stocks.”",
      subTitle: "-Cecilia Raggio, Buenos Aires, Argentina",
    },
    {
      image: "/images/clients2.png",
      title:
        "“So, my first impression is that the design looks really really good. Also very sophisticated graphs and data.”",
      subTitle: "-Maico Strauß, Düsseldorf, Germany",
    },
    {
      image: "/images/clients3.png",
      title:
        "“A complete success! It is easy to use and create a stock portfolio with non-financial experience.”",
      subTitle: "-Chuong Nguyen, Hô Chi Minh, Vietnam",
    },
  ];

  return (
    <section className="bg-white">
      <div className="home-slick-slider max-w-[1440px] mx-auto py-[1px]">
        <div className="flex relative mt-[190px] md:mt-[152px] sm:mt-0 mb-[258px] md:mb-[166px] sm:mb-[42px] sm:flex-col">
          {isMobile && (
            <img
              src="/images/home/test-circle-mbl1.png"
              className="absolute bottom-0 right-0 z-[-1]"
              alt=""
            />
          )}
          <picture>
            <source
              media="(max-width:465px)"
              srcSet="/images/home/test-circle-mbl.svg"
            />
            <img
              src="/images/home/test-circle.svg"
              className="absolute top-[190px] sm:top-[35px] sm:left-[-60px] z-0"
              alt=""
            />
          </picture>
          <div className="w-[30%] sm:w-[100%] sm:ml-0 md:ml-[26px] ml-[60px] z-[1]">
            <div className="text-light-green sm:hidden text-base sm:text-12 sm:text-center font-reg mb-[56px] md:mb-[32px] sm:mb-[7px]">
              Testimonials
            </div>
            <div className="text-primary md:text-[28px] sm:tracking-tight sm:mx-auto sm:w-[212px] sm:mt-[47px] sm:leading-normal font-roboto-bold text-bmd  sm:text-17 sm:text-center">
              What our clients think about us?
            </div>
          </div>

          <div className="w-[61%] md:w-[68%] sm:w-[80%] sm:mx-auto md:pr-[20px]">
            <Slider {...settings}>
              {sliderImages.map(({ image, title, subTitle }) => (
                <div className="ml-[66px] sm:ml-[0px]" key={title + subTitle}>
                  <div>
                    <img
                      className="sm:w-[70px] md:w-[120px] sm:h-[29px] sm:mt-[28px]"
                      src={image}
                      alt=""
                    />
                  </div>
                  <div className="text-md md:w-[87%] md:text-sm text-black mt-[88px] md:mt-[41px] sm:mt-[22px] sm:text-12">
                    {title}
                  </div>
                  <div className="font-roboto-bold mt-[32px] sm:mt-[16px] sm:tracking-tighter text-md md:text-sm sm:text-12">
                    {subTitle}
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section4;
