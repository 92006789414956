import LanguageModal from "components/Modal/LanguageModal";
import React, { useState, useEffect } from "react";
import { slide as Menu } from "react-burger-menu";
import { Link, NavLink } from "react-router-dom";
import LaunchOffer from "components/Modal/LaunchOffer";
import CongratsModal from "components/Modal/CongratsModal";
import StoreModal from "components/Modal/StoreModal";

function Header({
  icon = "/icons/mbl-header.svg",
  onClick,
  lang = true,
  isDownload = true,
}) {
  const [companyHover, setCompanyHover] = useState(false);
  const [logo, setLogo] = useState(false);
  const [mblMenu, setMblMenu] = useState(false);
  const [open, setOpen] = useState(false);
  const [openLaunch, setOpenLaunch] = useState(false);
  const [openCongrats, setOpenCongrats] = useState(false);
  const [openStore, setOpenStore] = useState(false);

  const [isHovered, setIsHovered] = useState(false);
  const defaultImageSrc = "/images/help/appstore.svg";
  const hoverImageSrc = "/images/help/appstore_green.svg";

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(false);
      if (window.innerWidth < 1050) {
        setIsMobile(true);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <LanguageModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
      <StoreModal
        onClick={() => {
          setOpenStore(false);
        }}
        open={openStore}
        onClose={() => setOpenStore(false)}
      />
      <LaunchOffer
        onClick={() => {
          setOpenCongrats(true);
          setOpenLaunch(false);
        }}
        open={openLaunch}
        onClose={() => setOpenLaunch(false)}
      />
      <CongratsModal
        open={openCongrats}
        onClose={() => setOpenCongrats(false)}
      />
      {isMobile ? (
        <>
          <div className="flex z-[12] bg-secondary justify-between h-[52px] items-center px-[18px] sticky top-0 ">
            <Link to={"/"}>
              <img src="/images/header-logo.svg" alt="logo" />
            </Link>
            <img
              src={icon}
              alt="icons"
              onClick={onClick ? onClick : () => setMblMenu(true)}
            />
          </div>
          <Menu
            width={"90%"}
            id="mbl-menu"
            overlayClassName="mbl-menu-overlay"
            isOpen={mblMenu}
            onClose={() => {
              setMblMenu(false);
            }}
            onStateChange={(state) => setMblMenu(state.isOpen)}
          >
            <img
              src="/icons/close.svg"
              className="flex ml-auto pt-[10px] pr-[12px]"
              onClick={() => setMblMenu(false)}
              alt="close"
            />

            <div className="ml-[14px] mt-[22px] sm:text-[24px] text-[42px]">
              <div>
                <NavLink
                  to="/about"
                  className={({ isActive }) =>
                    isActive
                      ? "text-light-green font-roboto-bold mb-[28px] cursor-pointer"
                      : "text-primary font-roboto-bold mb-[28px] cursor-pointer"
                  }
                >
                  About us
                </NavLink>
              </div>
              <div>
                <NavLink
                  to="/learning"
                  className={({ isActive }) =>
                    isActive
                      ? "text-light-green font-roboto-bold mb-[28px] cursor-pointer"
                      : "text-primary font-roboto-bold mb-[28px] cursor-pointer"
                  }
                >
                  Learning
                </NavLink>
              </div>
              <div>
                <NavLink
                  to="/help"
                  className={({ isActive }) =>
                    isActive
                      ? "text-light-green font-roboto-bold mb-[28px] cursor-pointer"
                      : "text-primary font-roboto-bold mb-[28px] cursor-pointer"
                  }
                >
                  Help center
                </NavLink>
              </div>
              <div>
                <NavLink
                  to="/contact"
                  className={({ isActive }) =>
                    isActive
                      ? "text-light-green font-roboto-bold mb-[28px] cursor-pointer"
                      : "text-primary font-roboto-bold mb-[28px] cursor-pointer"
                  }
                >
                  Contact
                </NavLink>
              </div>
            </div>
            <div className="fixed bottom-[140px] right-10">
              <div
                className="flex gap-x-[8px] items-center cursor-pointer"
                onClick={() => (lang ? setOpen(true) : setOpen(false))}
              >
                <img
                  src="/icons/LanguageRounded.svg"
                  className="sm:h-[16px] h-[29px]"
                  alt="lang"
                />
                <div className="sm:text-[14px] text-[28px] font-normal text-[#040B11]">
                  EN
                </div>
              </div>
            </div>
            <div className="fixed sm:bottom-[45%] bottom-6 sm:right-[calc(50%-175px)] right-[calc(50%-250px)]">
              <Link to={"/stores"}>
                <button className="arrow-button justify-center sm:w-[350px] w-[500px] bg-light-green mt-[60px] md:mt-[42px] md:h-[50px] h-[56px] sm:h-[50px] border sm:mt-[21px] sm:mx-auto border-black border-light-green text-white font-reg flex sm:justify-center items-center ml-[16px] text-[24px] sm:text-[17px] rounded-[39px] relative hover:bg-black hover:border-white hover:border-light-green">
                  <div className="arrow absolute sm:left-[50px] left-[124px] ">
                    <div className="head"></div>
                  </div>
                  <span className="font-roboto-bold text-[24px] pl-8">
                    {" "}
                    Download it down
                  </span>
                </button>
              </Link>
            </div>
          </Menu>
        </>
      ) : (
        <div className="bg-secondary shadow sm:hidden  md:hidden sticky top-0 z-[2]">
          <div className="h-[84px] flex text-white justify-between items-center max-w-[1440px] mx-auto px-[28px]">
            <div className="flex gap-x-[20px] items-center cursor-pointer">
              <Link to={"/"}>
                <img
                  onMouseEnter={() => setLogo(true)}
                  onMouseLeave={() => setLogo(false)}
                  className="h-[24px]"
                  src={`/images/header-logo${logo ? "-green" : ""}.svg`}
                  alt="header-logo"
                />
              </Link>
              <Link to={"/learning"}>
                <div
                  className={`font-reg text-xsm ${window.location.pathname === "/learning" &&
                    "rounded-[50px] bg-primary font-roboto-bold"
                    } hover:rounded-[50px] px-[12px] flex items-center hover:bg-primary hover:font-roboto-bold h-[30px]`}
                >
                  Learning
                </div>
              </Link>
              <Link to={"/help"}>
                <div
                  className={`font-reg text-xsm ${window.location.pathname === "/help" &&
                    "rounded-[50px] bg-primary font-roboto-bold"
                    } hover:rounded-[50px] px-[12px] flex items-center hover:bg-primary hover:font-roboto-bold h-[30px]`}
                >
                  Help center
                </div>
              </Link>
            </div>
            <div className="flex gap-x-[20px]">
              <div
                className="flex gap-x-[10px] items-center cursor-pointer"
                onClick={() => (lang ? setOpen(true) : null)}
              >
                <img src="/icons/lang.svg" className="h-[16px] " alt="lang" />
                <div className="text-xsm font-reg">EN</div>
              </div>
              <div
                onMouseEnter={() => setCompanyHover(true)}
                onMouseLeave={() => setCompanyHover(false)}
                className="relative cursor-pointer flex gap-x-[10px] items-center hover:bg-primary px-[11px] hover:rounded-[50px] font-reg hover:font-roboto-bold hover:h-[30px]"
              >
                <img
                  className="h-[12px]"
                  src="/icons/company.svg"
                  alt="company"
                />
                <div className="text-xsm ">Company</div>
                {companyHover && (
                  <div className="text-white font-reg text-sm absolute bottom-[-108px] left-0 bg-secondary w-[134px]  rounded-[5px]">
                    <Link to={"/about"}>
                      <div className=" pl-[23px] py-[15px] hover:bg-primary ">
                        About us
                      </div>
                    </Link>
                    <Link to={"/contact"}>
                      <div className="pl-[23px] py-[15px] hover:bg-primary ">
                        Contact
                      </div>
                    </Link>
                  </div>
                )}
              </div>
              {isDownload && (
                <button
                  onMouseEnter={() => setIsHovered(true)} // When mouse enters the button area
                  onMouseLeave={() => setIsHovered(false)} // When mouse leaves the button area
                  onClick={() => setOpenStore(true)}
                  className="bg-light-green text-white hover:bg-white hover:text-light-green font-bold text-[16px] rounded-[16px] w-[201px] h-[32px] flex items-center justify-center gap-3"
                >
                  <img
                    src={isHovered ? hoverImageSrc : defaultImageSrc}
                    alt="appstore"
                    className="transition-all"
                  />
                  Download it now
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Header;
