import React, { useEffect, useState } from "react";
import HelpCard from "utils/helpCard";
import { useNavigate } from "react-router-dom";
import { HelpCenterTitles } from "utils/constants";

const Security3 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  const [isThumbUpActive, setIsThumbUpActive] = useState(false);
  const [isThumbDownActive, setIsThumbDownActive] = useState(false);

  const handleThumbUpClick = () => {
    setIsThumbUpActive(!isThumbUpActive);
    setIsThumbDownActive(false);
  };

  const handleThumbDownClick = () => {
    setIsThumbDownActive(!isThumbDownActive);
    setIsThumbUpActive(false);
  };

  return (
    <div className="bg-primary h-max">
      <div className="max-screen-1440 mx-auto px-[28px] sm:px-[18px] md:px-[23px]">
        <div className="sm:block hidden text-white text-[24px] font-medium pt-[14px] flex items-center">
          <div
            className="flex items-center gap-2 mb-4"
            onClick={() => navigate("/help/security")}
          >
            <img src="/icons/left.svg" className="z-[12]" alt="left-back" />
            Security
          </div>
        </div>
        <div className="sm:hidden block text-white text-[50px] font-roboto-bold pt-[50px] flex items-center gap-8">
          <img src="/images/help/security-detail.svg" alt="security-detail" />
          Security
        </div>
        <div className="mt-10 sm:mt-4 sm:w-full flex max-w-[1416px] w-full grid grid-cols-12">
          <div className="col-span-9 md:col-span-12 sm:col-span-12">
            <div className="w-full mx-auto mb-10 sm:mb-4">
              <div className="w-full bg-transparent rounded shadow-md">
                <div className="font-bold text-[24px] text-white w-full text-left flex justify-between items-center pb-8 sm:pb-4 border-b border-b-1 border-[#464646]">
                  {HelpCenterTitles.security[2].title}
                </div>
                <div
                  className={`mt-8 pb-8 sm:mt-4 sm:pb-[56px] border-b border-b-1 border-[#464646]`}
                >
                  <div className="text-white text-[24px] sm:text-[16px]">
                    <p className="font-normal">
                      If you suspect unauthorized access to your account on
                      Capiwise or any similar platform, it's crucial to take
                      immediate action to secure your account and protect your
                      personal information. Here are the steps you should
                      consider:
                    </p>
                    <div className="mt-4">
                      <div className="font-bold">1. Change Your Password:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Immediately change your account password. Choose a
                        strong, unique password that you haven't used elsewhere.
                        This will help prevent further unauthorized access.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        2. Enable Two-Factor Authentication (2FA):
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        If you haven't already, enable 2FA on your account. This
                        adds an extra layer of security by requiring a secondary
                        form of verification, such as a code sent to your mobile
                        device.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        3. Review Account Activity:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Check your account activity and transaction history for
                        any suspicious or unauthorized transactions. Report any
                        unauthorized activity to Capiwise immediately.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        4. Contact Customer Support:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Reach out to Capiwise's customer support as soon as
                        possible. Use the official contact channels provided by
                        the platform to report suspected unauthorized access and
                        seek assistance.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        5. Verify Account Information:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Verify that your account information, including contact
                        details and linked financial accounts, has not been
                        altered without your knowledge. If you find
                        discrepancies, report them to customer support.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">6. Secure Other Accounts:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        If you use similar passwords across multiple accounts,
                        consider changing the passwords on those accounts as
                        well. This helps prevent unauthorized access to your
                        other online profiles.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">7. Scan for Malware:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Run a comprehensive antivirus and anti-malware scan on
                        the device you use to access Capiwise. This can help
                        detect and remove any potential threats.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        8. Log Out of Other Devices:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        If you suspect unauthorized access, log out of all
                        active sessions on other devices. This can be done
                        through your account settings or by using a "log out of
                        all devices" feature if available.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">9. Monitor Account:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Regularly monitor your account for any unusual activity,
                        even after taking initial corrective measures. Be
                        vigilant for any signs of further unauthorized access.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        10. File a Security Report:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        If there is a security reporting feature on the
                        platform, use it to file a report about unauthorized
                        access. This can help the security team investigate and
                        take appropriate action.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        11. Consider Legal Action:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        If the unauthorized access has resulted in financial
                        losses or other damages, you may want to consider
                        seeking legal advice and, if necessary, reporting the
                        incident to law enforcement.
                      </div>
                    </div>
                    <div className="mt-4 font-normal">
                      Remember, the specific steps may vary based on Capiwise's
                      policies and features, so refer to the platform's help
                      centre or{" "}
                      <u
                        className="text-[#0F69FE] cursor-pointer"
                        onClick={() => navigate("/contact")}
                      >
                        contact customer support
                      </u>{" "}
                      for guidance tailored to your situation. Taking prompt
                      action is essential to minimize the impact of unauthorized
                      access and protect your account.
                    </div>
                  </div>
                  <div className="text-[#979797] text-[16px] mt-[108px] sm:mt-8 sm:text-[10px] ">
                    Was this information helpful?
                  </div>
                  <div className="flex justify-between items-center w-[120px] sm:w-[75px] mt-4">
                    <img
                      src={
                        isThumbUpActive
                          ? "/images/help/thumbup_active.svg"
                          : "/images/help/thumbup_normal.svg"
                      }
                      alt="thumbup"
                      onClick={handleThumbUpClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                    <img
                      src={
                        isThumbDownActive
                          ? "/images/help/thumbdown_active.svg"
                          : "/images/help/thumbdown_normal.svg"
                      }
                      alt="thumbdown"
                      onClick={handleThumbDownClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                  </div>
                </div>
              </div>
              <div className="text-[#0F69FE] text-[24px] mt-[80px] sm:mt-4 sm:text-[16px]">
                More Help
              </div>
              <div onClick={() => navigate(`/help/security/1`)}>
                <HelpCard title={HelpCenterTitles.security[0].title} />
              </div>
              <div onClick={() => navigate(`/help/security/2`)}>
                <HelpCard title={HelpCenterTitles.security[1].title} />
              </div>
              <div onClick={() => navigate(`/help/security/4`)}>
                <HelpCard title={HelpCenterTitles.security[3].title} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Security3;
