// import { Header } from "components/Header";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
// import { ShareSocial } from 'react-share-social'

function StoreModal({ open, onClose, onClick }) {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    }
  }, [open]);

  const url = "https://play.google.com/store/apps/details?id=com.capiwise"
  // const copyToClipboard = (url) => {
  //   navigator.clipboard.writeText(url).then(() => {
  //     // Success feedback
  //     alert("URL copied to clipboard!");
  //   }).catch(err => {
  //     // Error feedback
  //     console.error("Could not copy text: ", err);
  //     alert("Failed to copy URL");
  //   });
  // };

  // const shareSocial = () => {
  //   return <ShareSocial
  //     url="url_to_share.com"
  //     socialTypes={['facebook', 'twitter', 'reddit', 'linkedin']}
  //   />
  // }

  // const style = {
  //   root: {
  //     background: 'linear-gradient(180deg, #efffff 30%, #FFFFFF 90%)',
  //     borderRadius: 3,
  //     border: 0,
  //     boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  //     color: 'white',

  //   },
  //   copyContainer: {
  //     border: '1px solid blue',
  //     background: 'rgb(0,0,0,0.7)'
  //   },
  //   title: {
  //     color: "#040B11",
  //     fontSize: "24px",
  //     fontFamily: "roboto-bold"
  //   }
  // };

  return (
    <Modal
      isOpen={open}
      overlayClassName="modal-overlay"
      className={`bg-move-left xl:bg-[url('../public/images/popup.png')] bg-white absolute sm:top-0 sm:left-0 sm:w-full max-w-[1200px] mx-auto inset-[80px] h-max sm:h-full md:max-w-[784px] rounded-[10px] sm:rounded-none pt-[103px] md:pt-[60px] px-[60px] md:pr-[20px] md:pl-[40px] sm:pt-0 sm:px-0 outline-none overflow-hidden`}
    >
      {/* <div className="z-50 absolute bottom-0 w-full ">
        <ShareSocial
          title="Sharing Capiwise"
          url={url}
          socialTypes={['facebook', 'twitter', 'linkedin', 'whatsapp', 'telegram']}
          style={style}
        />

      </div> */}

      <div>
        {/* <div className="sm:block hidden hover:bg-[#2EBD85] p-[10px] rounded-[50%] top-[29px] absolute left-[48px] md:left-[50px] md:top-[10px] sm:left-[20px] sm:top-[15px] cursor-pointer"
          onClick={() => setShareSocial(!shareSocial)}>
          <img src="/icons/share.svg" alt="share" />
        </div> */}
        <div
          className="hover:bg-[#2EBD85] p-[10px] rounded-[50%] top-[29px] absolute right-[40px] md:right-[18px] md:top-[10px] sm:right-[20px] sm:top-[15px] cursor-pointer"
          onClick={() => {
            document.body.style.overflow = "auto";
            onClose();
          }}
        >
          <svg
            className="h-6 w-6 hover:stroke-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
      </div>
      <div className="flex sm:px-[16px] sm:mt-[96px] relative h-full">
        <div className="w-full">
          <div className="font-roboto-bold text-[#040B11] text-[50px] md:text-[42px] sm:text-[24px] sm:text-center leading-normal">
            Download the app
          </div>
          <div className="text-black text-[24px] md:text-[24px] sm:text-[17px] font-roboto font-normal mt-4 mb-8 md:my-[14px] sm:my-6 sm:text-center leading-normal">
            Enjoy free access, no hidden fees.
          </div>
          <div className="flex grid grid-cols-12 sm:pt-[48px]">
            <div className="col-span-5 sm:col-span-12">
              <div className="contents sm:flex items-center justify-center"
                onClick={() => window.open(url, '_blank')}
              >
                <picture>
                  <source
                    srcSet="/images/gplay-mbl.png"
                    media="(max-width:465px)"
                  />
                  <img
                    src="/images/android.svg"
                    className="w-[246px] md:w-[142px] pb-4"
                    alt="googleplay"
                  />
                </picture>
              </div>
              {/* <div className="contents sm:flex items-center justify-center ">
                <picture>
                  <source
                    srcSet="/images/appstore-mbl.png"
                    media="(max-width:465px)"
                  />
                  <img
                    src="/images/ios.svg"
                    className="w-[246px] md:w-[142px] mt-8 md:mt-[19px] sm:mt-4"
                    alt="applestore"
                  />
                </picture>
              </div> */}
              <div className="contents sm:flex items-center justify-center">
                <picture>
                  <source
                    srcSet="/images/image23.png"
                    media="(max-width:465px)"
                  />
                  <img
                    src="/images/image23.png"
                    className="w-[246px] md:w-[142px]"
                    alt="qr"
                  />
                </picture>
              </div>
              <div className="flex items-center justify-start sm:justify-center gap-2 mt-[80px] md:mt-[48px] sm:my-[48px] pb-[180px] md:pb-[90px] sm:pb-10">
                <svg
                  width="12"
                  height="14"
                  viewBox="0 0 12 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_1270_8964)">
                    <path
                      d="M12 10.2563V10.3656C11.988 10.3902 11.9733 10.414 11.9641 10.4397C11.6195 11.3859 11.0746 12.2242 10.4351 13.0111C10.0384 13.4991 9.58343 13.9078 8.9034 13.9989H8.55936C8.47975 13.9871 8.39985 13.9767 8.32052 13.9636C7.98481 13.9075 7.67009 13.7876 7.35709 13.6666C6.65752 13.3968 5.96024 13.3888 5.25405 13.6439C4.8807 13.7789 4.51021 13.9258 4.11501 13.9989H3.57036C3.21712 13.908 2.91361 13.7334 2.64113 13.5046C2.45029 13.3445 2.28129 13.165 2.12206 12.9775C0.919498 11.5608 0.197791 9.95528 0.0290762 8.13217C-0.0373174 7.4137 0.000909256 6.70482 0.238892 6.01592C0.563674 5.07576 1.12328 4.3053 2.05106 3.81593C2.70465 3.47106 3.39877 3.31259 4.14634 3.44972C4.60822 3.53429 5.04567 3.69358 5.47537 3.87012C5.86395 4.02968 6.24335 4.05185 6.63682 3.88052C6.87049 3.7787 7.11854 3.7059 7.36112 3.62324C8.0423 3.39114 8.73354 3.29152 9.45438 3.44397C10.2885 3.6205 11.0087 3.96482 11.5212 4.64058C11.6313 4.78564 11.6319 4.78756 11.4735 4.89184C11.101 5.13735 10.7662 5.42172 10.5207 5.78711C9.95765 6.62572 9.92949 7.5303 10.2221 8.45048C10.4991 9.32221 11.1602 9.87508 12 10.2563Z"
                      fill="black"
                    />
                    <path
                      d="M5.96882 2.94203C6.02429 1.88473 6.53532 1.0754 7.45362 0.495708C7.83474 0.255126 8.25437 0.0939175 8.70676 0.0159131C8.93497 -0.0234996 8.97263 -0.00187736 8.9663 0.221735C8.92894 1.53084 8.2857 2.48359 7.07595 3.08792C6.77129 3.24009 6.43041 3.27184 6.0858 3.24967C6.00072 3.2442 5.96479 3.21327 5.96824 3.13335C5.97112 3.06985 5.96882 3.00608 5.96882 2.94231V2.94203Z"
                      fill="black"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1270_8964">
                      <rect width="12" height="14" fill="black" />
                    </clipPath>
                  </defs>
                </svg>
                <span className="font-roboto text-[16px] sm:text-[12px] text-black font-normal">
                  Coming soon in App Store
                </span>
              </div>
            </div>
            <div className="xl:hidden sm:col-span-12 md:col-span-7 lg:col-span-7 relative h-full">
              <img
                src="/images/phones-popup.png"
                className="w-full absolute top-0 left-0 z-10 md:top-4"
                alt="func2-3"
              />
              {/* <img
                src="/images/func2-2.png"
                className="pt-0 w-[50%] absolute top-0 right-[55%] "
                alt="func2-2"
              />
              <img
                src="/images/func2-3.png"
                className="w-[50%]  absolute top-[67px] right-[23%] z-10"
                alt="func2-3"
              />
              <img
                src="/images/func2-4.png"
                className="pt-0 w-[50%]  absolute top-0 right-[-10%]"
                alt="func2-4"
              /> */}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default StoreModal;
