import React, { useState } from "react";
import StoreModal from "components/Modal/StoreModal";
import { Link } from "react-router-dom";

function Footer() {
  const url = "https://play.google.com/store/apps/details?id=com.capiwise"
  const [openStore, setOpenStore] = useState(false);
  return (
    <>
      {/* <StoreModal
        onClick={() => {
          setOpenStore(false);
        }}
        open={openStore}
        onClose={() => setOpenStore(false)}
      /> */}
      <div className="bg-secondary z-0">
        <div className="pb-[30px]  max-w-[1440px] mx-auto px-[28px] sm:px-[19px]  ">
          <div className="text-white flex sm:flex-col pt-[30px]">
            <img
              className=" md:w-[71px] sm:w-[120px]"
              src="/images/header-logo.svg"
              alt=""
            />
            <div className="font-reg md:text-[8px] text-sm sm:text-12 border-l-[6px] sm:border-none sm:pl-0 pl-[30px] ml-[20px] sm:ml-0 sm:mt-[21px]">
              Your all-in-one investment platform. AI-driven decisions, seamless
              valuations, limitless opportunities for trading and managing your
              portfolio. Experience the power of smart investing with Capiwise,
              where advanced technology meets effortless financial
              decision-making.
            </div>
          </div>
          <div className="pt-[30px] border-b border-b-[#252A2D] border-b-[2px]"></div>
          <div className="grid grid-cols-12 flex sm:flex-col sm:gap-y-[22px] mb-[35px] mt-[40px] sm:border-b-[#252A2D] sm:border-b-2 sm:border-b-solid pb-[26px]">
            <div className="col-span-2 sm:col-span-12">
              <div className="footer-title mb-4">Who we are</div>
              <Link to={"/about"}>
                <div className="footer-sub-title">About us</div>
              </Link>
              <Link to={"/about"}>
                <div className="footer-sub-title">Our values</div>
              </Link>
              <Link to={"/about"}>
                <div className="footer-sub-title">Our vision</div>
              </Link>
            </div>
            <div className="col-span-2 sm:col-span-12">
              <div className="footer-title mb-4">Support</div>
              {/* <div className="footer-sub-title">FAQ's </div> */}
              <Link to={"/help"}>
                <div className="footer-sub-title">Help center</div>
              </Link>
              <Link to={"/contact"}>
                <div className="footer-sub-title">Contact us</div>
              </Link>
            </div>
            {/* <div className="col-span-2 sm:col-span-12">
              <div className="footer-title mb-4">Privacy and regulations</div>
              <div className="footer-sub-title">General risk disclosure</div>
              <div className="footer-sub-title"> Legal notice</div>
              <div className="footer-sub-title">Financial services guide</div>
              <div className="footer-sub-title">Regulation & License</div>
            </div> */}
          </div>
          <div className="flex justify-between sm:flex-col-reverse">
            <div>
              <div className="sm:pt-[30px] sm:mt-[35px] sm:border-t-[#252A2D] sm:border-t-2 sm:border-t-solid text-white sm:text-[#C4C4C4] sm:text-[14px] sm:text-center mb-5 md:text-[10px] md:mb-[10px] font-roboto-bold tracking-tight">
                Follow us
              </div>
              <div className="flex gap-x-[15px] sm:justify-between sm:mx-6">
                <a
                  href="https://www.facebook.com/profile.php?id=100068959279852"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/icons/fb.svg" className="md:w-[14px]" alt="fb" />
                </a>
                <a
                  href="https://twitter.com/capiwiseapp"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/icons/twitter.svg"
                    className="md:w-[14px]"
                    alt="tw"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/capiwise"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/icons/linkdn.svg"
                    className="md:w-[14px]"
                    alt="ld"
                  />
                </a>
                <a
                  href="https://www.instagram.com/capiwiseapp/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/icons/insta.svg"
                    className="md:w-[14px]"
                    alt="ig"
                  />
                </a>
                <a
                  href="https://www.youtube.com/@Capiwise"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/icons/youtube.svg"
                    className="md:w-[14px]"
                    alt="yt"
                  />
                </a>
              </div>
            </div>
            <div className="flex gap-x-[30px]">
              <div
                className="border border-white rounded-[4px] sm:rounded-0 sm:border-none cursor-pointer"
                onClick={() => window.open(url, '_blank')}
              >
                {/* <div> */}
                <picture>
                  {/* <source
                    media="(max-width:465px)"
                    srcSet="/images/gplay-mbl.png"
                  /> */}
                  <img
                    src="/images/android.svg"
                    className="w-[232px] md:w-[120px] "
                    alt="googleplay"
                  />
                </picture>
              </div>
              <div
                className="border border-white rounded-[4px] sm:rounded-0 sm:border-none cursor-pointer"
                onClick={() => setOpenStore(true)}
              >
                {/* <div> */}
                <picture>
                  {/* <source
                    media="(max-width:465px)"
                    srcSet="/images/appstore-mbl.png"
                  /> */}
                  <img
                    src="/images/ios.svg"
                    className="w-[232px] md:w-[120px]"
                    alt="applestore"
                  />
                </picture>
              </div>
            </div>
          </div>
          <div className="pt-[30px] border-b border-b-[#252A2D] border-b-[2px] sm:hidden"></div>
          <div className="flex items-cetner grid grid-cols-12 pt-4">
            <div className="flex items-center justify-between col-span-5 sm:col-span-12 sm:mt-4 sm:hidden">
              <Link to={"/privacypolicy"}>
                <div className="footer-sub-title cursor-pointer">
                  Privacy and security
                </div>
              </Link>
              <Link to={"/terms"}>
                <div className="footer-sub-title cursor-pointer">
                  Terms and conditions
                </div>
              </Link>
              {/* <div className="footer-sub-title cursor-pointer">
                Cookies policy
              </div> */}
            </div>
            <div className="sm:flex justify-center items-center hidden col-span-12 sm:my-4 gap-2">
              <Link to={"/privacypolicy"}>
                <div className="footer-privacy-title cursor-pointer">
                  Privacy
                </div>
              </Link>
              <div className="footer-privacy-title">|</div>
              <Link to={"/terms"}>
                <div className="footer-privacy-title cursor-pointer">Terms</div>
              </Link>
              {/* <div className="footer-privacy-title">|</div>
              <div className="footer-privacy-title cursor-pointer">
                Cookies policy
              </div> */}
            </div>
            <div className="text-sm sm:text-[12px] md:text-[8px] text-white font-roboto font-normal col-span-7 text-right sm:col-span-12 sm:text-center">
              © Capiwise. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
