import React, { useEffect, useState } from "react";
import { Header } from "components/Header";
import Modal from "react-modal";
import { Link } from "react-router-dom";

function CongratsModal({ open, onClose }) {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(false);
      if (window.innerWidth < 1000) {
        setIsMobile(true);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    }
  }, [open]);

  return (
    <Modal
      isOpen={open}
      overlayClassName="modal-overlay"
      //   onRequestClose={onClose}
      //   overlayClassName={` ${overlayClassName}`}
      className={`bg-[#CEFBE3] absolute sm:top-0 sm:left-0 sm:h-full sm:w-full max-w-1440 mx-auto inset-[40px] h-[781px] rounded-[4px]  sm:pt-0 pl-[74px] sm:px-0 outline-none`}
      //   onAfterClose={onAfterClose}
      //   onAfterOpen={onAfterOpen}
      //   shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      //   style={style}
    >
      {isMobile ? (
        <Header
          icon="/icons/mbl-header-close.svg"
          onClick={() => {
            document.body.style.overflow = "auto";
            onClose();
          }}
        />
      ) : (
        <div className="hover:bg-[#8ef5cd] p-[10px]  rounded-[50%] top-[29px] absolute right-[45px] cursor-pointer">
          <img
            src="/icons/close.svg"
            onClick={() => {
              document.body.style.overflow = "auto";
              onClose();
            }}
            alt=""
          />
        </div>
      )}
      <div className="flex justify-center sm:px-[16px]">
        <div className="w-[1034px] ">
          <picture>
            <source
              media="(max-width:465px)"
              srcSet="/images/modal/congrats-mbl.svg"
            />
            <img
              src="/images/modal/congrats.svg"
              className="mx-auto mb-[40px] sm:mt-[33px]"
              alt=""
            />
          </picture>
          {/* <img
            src="/images/modal/congrats.svg"
            className="mx-auto mb-[40px]"
            alt=""
          /> */}
          <div className="text-[60px] sm:text-base sm:leading-normal tracking-tighter mb-[40px] sm:mx-[32px] font-roboto-bold text-center">
            Congratulations: You’re On The List!{" "}
          </div>
          <div className=" text-center text-base sm:text-17 text-primary mb-[30px] tracking-tighter">
            You will be one of the first to know about our exciting launch
            offers! You’re on your way to becoming a successful investor!
          </div>
          <div className="text-center text-base sm:text-17 text-primary tracking-tighter">
            In the meantime,{" "}
            <Link to="/learning">
              <span className="text-light-green font-roboto-bold">learn</span>{" "}
            </Link>
            more about investing or go back to the{" "}
            <Link to="/">
              <span className="text-light-green font-roboto-bold">
                homepage.
              </span>
            </Link>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default CongratsModal;
