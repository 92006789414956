import React, { useEffect, useState } from "react";
import HelpCard from "utils/helpCard";
import { useNavigate } from "react-router-dom";
import { HelpCenterTitles } from "utils/constants";

const Markets3 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  const [isThumbUpActive, setIsThumbUpActive] = useState(false);
  const [isThumbDownActive, setIsThumbDownActive] = useState(false);

  const handleThumbUpClick = () => {
    setIsThumbUpActive(!isThumbUpActive);
    setIsThumbDownActive(false);
  };

  const handleThumbDownClick = () => {
    setIsThumbDownActive(!isThumbDownActive);
    setIsThumbUpActive(false);
  };

  return (
    <div className="bg-primary h-max">
      <div className="max-screen-1440 mx-auto px-[28px] sm:px-[18px] md:px-[23px]">
        <div className="sm:block hidden text-white text-[24px] font-medium pt-[14px] flex items-center">
          <div
            className="flex items-center gap-2 mb-4"
            onClick={() => navigate("/help/markets")}
          >
            <img src="/icons/left.svg" className="z-[12]" alt="left-back" />
            Markets
          </div>
        </div>
        <div className="sm:hidden block text-white text-[50px] font-roboto-bold pt-[50px] flex items-center gap-8">
          <img src="/images/help/markets-detail.svg" alt="markets-detail" />
          Markets
        </div>
        <div className="mt-10 sm:mt-4 sm:w-full flex max-w-[1416px] w-full grid grid-cols-12">
          <div className="col-span-9 md:col-span-12 sm:col-span-12">
            <div className="w-full mx-auto mb-10 sm:mb-4">
              <div className="w-full bg-transparent rounded shadow-md">
                <div className="font-bold text-[24px] text-white w-full text-left flex justify-between items-center pb-8 sm:pb-4 border-b border-b-1 border-[#464646]">
                  {HelpCenterTitles.markets[2].title}
                </div>
                <div
                  className={`mt-8 pb-8 sm:mt-4 sm:pb-[56px] border-b border-b-1 border-[#464646]`}
                >
                  <div className="text-white text-[24px] sm:text-[16px]">
                    <p className="font-normal">
                      To access information about the daily earnings of
                      companies within the Capiwise app, you can follow these
                      general steps. Keep in mind that the specific features and
                      navigation may vary based on the design of the app:
                    </p>
                    <div className="mt-4">
                      <div className="font-bold">1. Earnings Calendar:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Check for an "Earnings Calendar" or a similar section
                        within the Capiwise app. This calendar typically
                        provides a schedule of companies' earnings release
                        dates, including those for the current day.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">2. Company Profiles:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Navigate to the individual profiles of specific
                        companies. Earnings-related information, such as recent
                        earnings reports, may be available in the company's
                        profile or financial details section.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">3. Market Overview:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Explore the "Market Overview" or a similar section in
                        the Capiwise app. This section may include a summary of
                        companies' recent earnings performances and key
                        financial metrics.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">4. News and Analysis:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Check the "News" or "Analysis" section within the
                        Capiwise app. Earnings-related news articles or analysis
                        reports often provide insights into daily earnings
                        releases and their impact on companies.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        5. Watchlist Notifications:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        If you have specific companies added to your watchlist,
                        the Capiwise app may send notifications about their
                        daily earnings releases. Ensure that your notification
                        settings are configured to receive alerts for earnings
                        events.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">6. Customizable Filters:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        If the app offers customizable filters, use them to
                        narrow down companies based on their earnings
                        performance or upcoming earnings dates.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        7. Earnings Insights Section:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Look for a dedicated section or feature within the
                        Capiwise app that provides insights into companies'
                        earnings, including daily earnings highlights.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">8. Market Newsfeed:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Explore the app's newsfeed or a real-time market feed.
                        Earnings-related updates for companies releasing results
                        on the current day may be featured in this feed.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">9. Community Discussions:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Check if there are community or social features within
                        the Capiwise app where users discuss and share
                        information about companies' daily earnings. Community
                        discussions can provide additional insights and
                        perspectives.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">10. Earnings History:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Some apps offer a historical view of companies' earnings
                        performances. Check for a section that provides a record
                        of past earnings releases for companies of interest.
                      </div>
                    </div>
                    <div className="mt-4 font-normal">
                      Remember to refer to the Capiwise app's documentation or
                      help centre for specific guidance on accessing information
                      about the daily earnings of companies. The app's features
                      and navigation details can vary, and the help centre is a
                      valuable resource for understanding how to utilize its
                      capabilities effectively.
                    </div>
                  </div>
                  <div className="text-[#979797] text-[16px] mt-[108px] sm:mt-8 sm:text-[10px] ">
                    Was this information helpful?
                  </div>
                  <div className="flex justify-between items-center w-[120px] sm:w-[75px] mt-4">
                    <img
                      src={
                        isThumbUpActive
                          ? "/images/help/thumbup_active.svg"
                          : "/images/help/thumbup_normal.svg"
                      }
                      alt="thumbup"
                      onClick={handleThumbUpClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                    <img
                      src={
                        isThumbDownActive
                          ? "/images/help/thumbdown_active.svg"
                          : "/images/help/thumbdown_normal.svg"
                      }
                      alt="thumbdown"
                      onClick={handleThumbDownClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                  </div>
                </div>
              </div>
              <div className="text-[#0F69FE] text-[24px] mt-[80px] sm:mt-4 sm:text-[16px]">
                More Help
              </div>
              <div onClick={() => navigate(`/help/markets/1`)}>
                <HelpCard title={HelpCenterTitles.markets[0].title} />
              </div>
              <div onClick={() => navigate(`/help/markets/2`)}>
                <HelpCard title={HelpCenterTitles.markets[1].title} />
              </div>
              <div onClick={() => navigate(`/help/markets/4`)}>
                <HelpCard title={HelpCenterTitles.markets[3].title} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Markets3;
