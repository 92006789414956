import React, { useEffect, useState } from "react";
import HelpCard from "utils/helpCard";
import { useNavigate } from "react-router-dom";
import { HelpCenterTitles } from "utils/constants";

const Notifications3 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  const [isThumbUpActive, setIsThumbUpActive] = useState(false);
  const [isThumbDownActive, setIsThumbDownActive] = useState(false);

  const handleThumbUpClick = () => {
    setIsThumbUpActive(!isThumbUpActive);
    setIsThumbDownActive(false);
  };

  const handleThumbDownClick = () => {
    setIsThumbDownActive(!isThumbDownActive);
    setIsThumbUpActive(false);
  };

  return (
    <div className="bg-primary h-max">
      <div className="max-screen-1440 mx-auto px-[28px] sm:px-[18px] md:px-[23px]">
        <div className="sm:block hidden text-white text-[24px] font-medium pt-[14px] flex items-center">
          <div
            className="flex items-center gap-2 mb-4"
            onClick={() => navigate("/help/notifications")}
          >
            <img src="/icons/left.svg" className="z-[12]" alt="left-back" />
            Notifications
          </div>
        </div>
        <div className="sm:hidden block text-white text-[50px] font-roboto-bold pt-[50px] flex items-center gap-8">
          <img
            src="/images/help/notifications-detail.svg"
            alt="notifications-detail"
          />
          Notifications
        </div>
        <div className="mt-10 sm:mt-4 sm:w-full flex max-w-[1416px] w-full grid grid-cols-12">
          <div className="col-span-9 md:col-span-12 sm:col-span-12">
            <div className="w-full mx-auto mb-10 sm:mb-4">
              <div className="w-full bg-transparent rounded shadow-md">
                <div className="font-bold text-[24px] text-white w-full text-left flex justify-between items-center pb-8 sm:pb-4 border-b border-b-1 border-[#464646]">
                  {HelpCenterTitles.notifications[3].title}
                </div>
                <div
                  className={`mt-8 pb-8 sm:mt-4 sm:pb-[56px] border-b border-b-1 border-[#464646]`}
                >
                  <div className="text-white text-[24px] sm:text-[16px]">
                    <p className="font-normal">
                      The ability to receive notifications outside of trading
                      hours depends on the specific features and settings of the
                      app. In many cases, apps do provide the option for users
                      to customize their notification preferences, including
                      specifying the times during which notifications are
                      delivered. Here's a general explanation:
                    </p>
                    <div className="mt-4">
                      <div className="font-bold">1. Customizable Settings:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Many investment apps allow users to customize their
                        notification settings, including the ability to set
                        preferences for receiving notifications during trading
                        hours or specific timeframes.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        2. Trading Hours Notifications:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        By default, certain notifications, such as price alerts,
                        market updates, or stock news, might be configured to be
                        active during regular trading hours when the financial
                        markets are open.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        3. After-Hours Notifications (If Supported):
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Some apps offer the flexibility for users to receive
                        notifications after trading hours. This is particularly
                        relevant for after-hours trading or for updates related
                        to global markets operating in different time zones.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        4. Time Zone Considerations:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Notifications might be based on the user's time zone.
                        Ensure that your app is set to the correct time zone to
                        align with your local trading hours.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">5. Check App Settings:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Navigate to the app's settings or notification
                        preferences to review and adjust the timeframes during
                        which you wish to receive notifications.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        6. Market-Specific Notifications:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Depending on the nature of the notification (e.g.,
                        earnings reports, dividends), some may be time-sensitive
                        and might be received outside of regular trading hours.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">7. Device-Level Settings:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Additionally, your device settings (iOS, Android, etc.)
                        may also influence when notifications are delivered.
                        Ensure that your device's notification settings align
                        with your preferences.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        8. Consideration for Global Markets:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        If you're tracking international stocks or global
                        markets, you may want to set notifications to consider
                        different trading hours in various regions.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">9. Test Notifications:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        If the app provides a test or preview feature for
                        notifications, use it to see when and how notifications
                        are delivered.
                      </div>
                    </div>
                    <div className="mt-4 font-normal">
                      Always refer to the specific app's documentation or help
                      centre for detailed information on notification settings
                      and whether you can control the timing of notifications,
                      especially outside of regular trading hours.
                    </div>
                  </div>
                  <div className="text-[#979797] text-[16px] mt-[108px] sm:mt-8 sm:text-[10px] ">
                    Was this information helpful?
                  </div>
                  <div className="flex justify-between items-center w-[120px] sm:w-[75px] mt-4">
                    <img
                      src={
                        isThumbUpActive
                          ? "/images/help/thumbup_active.svg"
                          : "/images/help/thumbup_normal.svg"
                      }
                      alt="thumbup"
                      onClick={handleThumbUpClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                    <img
                      src={
                        isThumbDownActive
                          ? "/images/help/thumbdown_active.svg"
                          : "/images/help/thumbdown_normal.svg"
                      }
                      alt="thumbdown"
                      onClick={handleThumbDownClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                  </div>
                </div>
              </div>
              <div className="text-[#0F69FE] text-[24px] mt-[80px] sm:mt-4 sm:text-[16px]">
                More Help
              </div>
              <div onClick={() => navigate(`/help/notifications/1`)}>
                <HelpCard title={HelpCenterTitles.notifications[0].title} />
              </div>
              <div onClick={() => navigate(`/help/notifications/2`)}>
                <HelpCard title={HelpCenterTitles.notifications[1].title} />
              </div>
              <div onClick={() => navigate(`/help/notifications/3`)}>
                <HelpCard title={HelpCenterTitles.notifications[2].title} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications3;
