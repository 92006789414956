import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import PublicLayout from "layout/PublicLayout";
import Community1 from "./community1";
import Community2 from "./community2";
import Community3 from "./community3";
import Community4 from "./community4";

const SingleHelpCommunity = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams();
  return (
    <PublicLayout>
      {id === "1" ? (
        <Community1 />
      ) : id === "2" ? (
        <Community2 />
      ) : id === "3" ? (
        <Community3 />
      ) : id === "4" ? (
        <Community4 />
      ) : (
        <></>
      )}
    </PublicLayout>
  );
};

export default SingleHelpCommunity;
