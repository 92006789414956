import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import HelpContact from "components/Help/contact";
import GetStarted from "components/Help/getstarted";
import Notifications from "components/Help/notifications";
import Security from "components/Help/security";
import Markets from "components/Help/markets";
import Community from "components/Help/community";
import Watchlist from "components/Help/watchlist";
import PublicLayout from "layout/PublicLayout";

const SingleHelpPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams();
  return (
    <PublicLayout>
      {id === "contact" ? (
        <HelpContact />
      ) : id === "getstarted" ? (
        <GetStarted />
      ) : id === "notifications" ? (
        <Notifications />
      ) : id === "security" ? (
        <Security />
      ) : id === "markets" ? (
        <Markets />
      ) : id === "community" ? (
        <Community />
      ) : id === "watchlist" ? (
        <Watchlist />
      ) : (
        <></>
      )}
    </PublicLayout>
  );
};

export default SingleHelpPage;
