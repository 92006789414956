import React, { useEffect, useState } from "react";
import HelpCard from "utils/helpCard";
import { useNavigate } from "react-router-dom";
import { HelpCenterTitles } from "utils/constants";

const GetStarted4 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  const [isThumbUpActive, setIsThumbUpActive] = useState(false);
  const [isThumbDownActive, setIsThumbDownActive] = useState(false);

  const handleThumbUpClick = () => {
    setIsThumbUpActive(!isThumbUpActive);
    setIsThumbDownActive(false);
  };

  const handleThumbDownClick = () => {
    setIsThumbDownActive(!isThumbDownActive);
    setIsThumbUpActive(false);
  };

  return (
    <div className="bg-primary h-max">
      <div className="max-screen-1440 mx-auto px-[28px] sm:px-[18px] md:px-[23px]">
        <div className="sm:block hidden text-white text-[24px] font-medium pt-[14px] flex items-center">
          <div
            className="flex items-center gap-2 mb-4"
            onClick={() => navigate("/help/getstarted")}
          >
            <img src="/icons/left.svg" className="z-[12]" alt="left-back" />
            Get started
          </div>
        </div>
        <div className="sm:hidden block text-white text-[50px] font-roboto-bold pt-[50px] flex items-center gap-8">
          <img
            src="/images/help/getstarted-detail.svg"
            alt="get_started-detail"
          />
          Get started
        </div>
        <div className="mt-10 sm:mt-4 sm:w-full flex max-w-[1416px] w-full grid grid-cols-12">
          <div className="col-span-9 md:col-span-12 sm:col-span-12">
            <div className="w-full mx-auto mb-10 sm:mb-4">
              <div className="w-full bg-transparent rounded shadow-md">
                <div className="font-bold text-[24px] text-white w-full text-left flex justify-between items-center pb-8 sm:pb-4 border-b border-b-1 border-[#464646]">
                  {HelpCenterTitles.getstarted[3].title}
                </div>
                <div
                  className={`mt-8 pb-8 sm:mt-4 sm:pb-[56px] border-b border-b-1 border-[#464646]`}
                >
                  <div className="text-white text-[24px] sm:text-[16px]">
                    <p className="font-normal">
                      To begin tracking market trends and news within the app,
                      follow these steps:
                    </p>
                    <div className="mt-4">
                      <div className="font-bold">1. Explore the Homepage:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Start by exploring the app's homepage. Often, apps have
                        a curated feed or dashboard that highlights top market
                        trends and news.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        2. Follow Market Sections:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Look for dedicated sections within the app that focus on
                        market trends and news. These sections may include
                        "Market News," "Trending Topics," or similar.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">3. Set Up Alerts:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Check if the app allows you to set up personalized
                        alerts for specific market trends or news categories.
                        Alerts can notify you in real-time about important
                        developments.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">4. Customize Your Feed:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        If the app offers customization features, personalize
                        your feed based on your interests. Follow specific
                        stocks, sectors, or keywords to tailor the content to
                        your preferences.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        5. Check the News Section:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Explore a designated "News" section if available. This
                        section may provide comprehensive coverage of market
                        news and trends.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">6. Utilize Watchlists:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        If the app has a watchlist feature, consider adding key
                        stocks or assets to your watchlist. This can help you
                        track their performance and receive relevant news
                        updates.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        7. Follow Influencers or Analysts:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        See if you can follow influential users, analysts, or
                        experts within the app. Their insights and posts may
                        provide valuable information about market trends and
                        news.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        8. Explore Market Analysis Tools:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Look for market analysis tools or charts within the app.
                        These tools can offer visual representations of market
                        trends and indicators.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        9. Join Community Discussions:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Participate in community discussions related to market
                        trends. Engaging with other users can provide additional
                        perspectives and insights.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        10. Read Earnings Reports and Updates:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        If the app includes company-specific information,
                        regularly check earnings reports and updates for the
                        stocks on your watchlist.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        11. Filter and Sort Content:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Use any available filters or sorting options to refine
                        the content you see. This can help you focus on specific
                        market sectors or types of news.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        12. Utilize Trending and Popular Sections:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Explore sections that highlight trending or popular
                        content. This can give you a quick overview of what the
                        community finds noteworthy.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        13. Set Daily or Weekly Routines:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Establish a routine for checking market trends and news
                        within the app. This consistency helps you stay informed
                        about ongoing developments.
                      </div>
                    </div>
                    <div className="mt-4 font-normal">
                      By following these steps, you can effectively track market
                      trends and news within the app, ensuring that you stay
                      well-informed about the latest happenings in the financial
                      markets.
                    </div>
                  </div>
                  <div className="text-[#979797] text-[16px] mt-[108px] sm:mt-8 sm:text-[10px] ">
                    Was this information helpful?
                  </div>
                  <div className="flex justify-between items-center w-[120px] sm:w-[75px] mt-4">
                    <img
                      src={
                        isThumbUpActive
                          ? "/images/help/thumbup_active.svg"
                          : "/images/help/thumbup_normal.svg"
                      }
                      alt="thumbup"
                      onClick={handleThumbUpClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                    <img
                      src={
                        isThumbDownActive
                          ? "/images/help/thumbdown_active.svg"
                          : "/images/help/thumbdown_normal.svg"
                      }
                      alt="thumbdown"
                      onClick={handleThumbDownClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                  </div>
                </div>
              </div>
              <div className="text-[#0F69FE] text-[24px] mt-[80px] sm:mt-4 sm:text-[16px]">
                More Help
              </div>
              <div onClick={() => navigate(`/help/getstarted/1`)}>
                <HelpCard title={HelpCenterTitles.getstarted[0].title} />
              </div>
              <div onClick={() => navigate(`/help/getstarted/2`)}>
                <HelpCard title={HelpCenterTitles.getstarted[1].title} />
              </div>
              <div onClick={() => navigate(`/help/getstarted/3`)}>
                <HelpCard title={HelpCenterTitles.getstarted[2].title} />
              </div>
              <div onClick={() => navigate(`/help/getstarted/5`)}>
                <HelpCard title={HelpCenterTitles.getstarted[4].title} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetStarted4;
