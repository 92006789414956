import React, { useEffect } from "react";

const Learning2 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="w-[85%] mx-auto bg-white px-[48px] sm:px-[18px] mt-[-260px] sm:mt-[-190px] sm:w-[100%] rounded-[10px] text-[#050505]">
      <div className="pt-[64px] font-bold text-[34px] leading-[33px] text-center sm:text-[17px] sm:leading-[23px]">
        Navigating the Stock Market: A Beginner's
        <br className="block sm:hidden" /> Journey into Risk and Return
      </div>
      <div className="text-[24px] font-normal text-center my-[48px] sm:my-[24px] sm:text-[12px] sm:leading-[23px] sm:text-start ">
        Embarking on the exciting journey of stock market investing? Welcome
        aboard! For those new to the financial arena, comprehending the ins and
        outs of risk and return is paramount. This comprehensive blog will be
        your guide, unraveling the complexities of these concepts, and
        empowering you to make informed investment decisions.
      </div>
      <div className="flex justify-center mb-[30px] ">
        <img src="/images/learnings/learning-2.png" alt="" className="w-full" />
      </div>
      <div>
        <div className="w-full text-start font-bold text-[24px] leading-[33px] mb-[30px] sm:mb-[20px] sm:text-[17px] sm:leading-[23px]">
          1. Embracing the Unknown - Understanding Risk
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px]">
          1.1 Definition of Risk:
          <p className="indent-4">
            The stock market is a realm of uncertainty, and risk is the
            heartbeat of this world. It's the looming possibility of not
            achieving the returns you expect.
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px] my-6 sm:my-3">
          1.2 Types of Risk:
          <p className="indent-4">
            Dive into the diverse world of risk, from market fluctuations and
            company-specific issues to broader economic and political factors.
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px]">
          1.3 Risk Tolerance:
          <p className="indent-4">
            Assess your own risk tolerance by considering your financial goals,
            investment time horizon, and how comfortable you are with the
            inevitable ebb and flow of the market.
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px]">
          1.4 Diversification:
          <p className="indent-4">
            Imagine risk as a storm, and diversification as your umbrella.
            Spread your investments across different assets to weather the
            market's unpredictability.
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px]">
          1.5 Risk Management Strategies:
          <p className="indent-4">
            Equip yourself with tools like stop-loss orders and realistic
            expectations to navigate and manage risks effectively.
          </p>
        </div>
      </div>
      <div className="mt-[48px] sm:mt-[24px]">
        <div className="w-full text-start font-bold text-[24px] leading-[33px] mb-[30px] sm:mb-[20px] sm:text-[17px] sm:leading-[23px]">
          2. The Pursuit of Prosperity - Understanding Return
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px]">
          2.1 Definition of Return:
          <p className="indent-4">
            Returns are the sweet fruits of your investment labor – the gains or
            losses expressed as a percentage.
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px] my-6 sm:my-3">
          2.2 Types of Return:
          <p className="indent-4">
            Delve into capital gains, dividends, and the all-encompassing total
            return, understanding how each contributes to your investment
            success.
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px]">
          2.3 Expected Return:
          <p className="indent-4">
            Peer into the crystal ball of financial analysis, historical
            performance, and market conditions to estimate the potential returns
            of your investments.
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px]">
          2.4 Time Horizon and Return:
          <p className="indent-4">
            Consider your investment time horizon as the roadmap for your
            returns. Longer horizons open the door to more aggressive investment
            options.
          </p>
        </div>
      </div>
      <div className="mt-[48px] sm:mt-[24px]">
        <div className="w-full text-start font-bold text-[24px] leading-[33px] mb-[30px] sm:mb-[20px] sm:text-[17px] sm:leading-[23px]">
          3. Achieving Harmony - Balancing Risk and Return
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px]">
          3.1 Risk-Return Tradeoff:
          <p className="indent-4">
            Uncover the delicate dance between risk and return – the higher the
            potential returns, the higher the accompanying risks.
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px] my-6 sm:my-3">
          3.2 Investment Strategies:
          <p className="indent-4">
            Choose your path – whether conservative, moderate, or aggressive –
            based on your risk tolerance and financial goals.
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px]">
          3.3 Reassessing Your Portfolio:
          <p className="indent-4">
            Adapt and evolve your investment portfolio as your financial
            situation, market conditions, and goals shift over time.
          </p>
        </div>
      </div>
      <div className="mt-[48px] sm:mt-[24px] pb-[96px] text-[24px] font-normal leading-[33px] text-start sm:text-[12px] sm:leading-[23px] sm:text-start">
        Cheers to your newfound knowledge! As you navigate the dynamic world of
        the stock market, remember to stay curious, stay informed, and adapt
        your strategies as needed. This is just the beginning of an exciting
        journey. Happy investing!
      </div>
    </div>
  );
};

export default Learning2;
