import React, { useEffect, useState } from "react";
import { Header } from "components/Header";
import Modal from "react-modal";

function ThanksModal({ open, onClose }) {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(false);
      if (window.innerWidth < 1000) {
        setIsMobile(true);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    }
  }, [open]);
  return (
    <Modal
      isOpen={open}
      overlayClassName="modal-overlay"
      //   onRequestClose={onClose}
      //   overlayClassName={` ${overlayClassName}`}
      className={`bg-white w-[658px] sm:w-full  absolute sm:top-0 sm:left-0 sm:h-full max-w-1440 mx-auto inset-[20%] h-[544px] rounded-[4px]  sm:pt-0  sm:px-0 outline-none`}
      //   onAfterClose={onAfterClose}
      //   onAfterOpen={onAfterOpen}
      //   shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      //   style={style}
    >
      {isMobile ? (
        <Header
          icon="/icons/mbl-header-close.svg"
          onClick={() => {
            onClose();
            document.body.style.overflow = "auto";
          }}
        />
      ) : null}
      <div className="flex justify-center items-center sm:items-baseline sm:pt-[20%] h-full sm:px-[16px]">
        <div className="w-[1034px] ">
          <picture>
            <source media="(max-width:465px)" srcSet="/tick.svg" />
            <img
              src="/tick.svg"
              className="mx-auto mb-[20px] mt-[0px] sm:mt-[33px]"
              alt=""
            />
          </picture>
          {/* <img
            src="/images/modal/congrats.svg"
            className="mx-auto mb-[40px]"
            alt=""
          /> */}
          <div className="text-[60px] text-light-green sm:text-base sm:leading-normal tracking-tighter mb-[28px] sm:mx-[32px] font-roboto-bold text-center">
            Thank you
          </div>
          <div className=" text-center text-[34px] px-[65px] sm:px-[30px] sm:text-17 text-primary leading-normal mb-[48px] tracking-tighter">
            Your submission has been sent, we will be in contact soon
          </div>
          <button
            onClick={() => {
              onClose();
              document.body.style.overflow = "auto";
            }}
            className="bg-light-green block mx-auto text-white z-10 text-base font-roboto-bold rounded-[39px] w-[287px] h-[56px]"
          >
            Ok
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default ThanksModal;
