import React, { useEffect, useState } from "react";
import HelpCard from "utils/helpCard";
import { useNavigate } from "react-router-dom";
import { HelpCenterTitles } from "utils/constants";

const Watchlist1 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  const [isThumbUpActive, setIsThumbUpActive] = useState(false);
  const [isThumbDownActive, setIsThumbDownActive] = useState(false);

  const handleThumbUpClick = () => {
    setIsThumbUpActive(!isThumbUpActive);
    setIsThumbDownActive(false);
  };

  const handleThumbDownClick = () => {
    setIsThumbDownActive(!isThumbDownActive);
    setIsThumbUpActive(false);
  };

  return (
    <div className="bg-primary h-max">
      <div className="max-screen-1440 mx-auto px-[28px] sm:px-[18px] md:px-[23px]">
        <div className="sm:block hidden text-white text-[24px] font-medium pt-[14px] flex items-center">
          <div
            className="flex items-center gap-2 mb-4"
            onClick={() => navigate("/help/watchlist")}
          >
            <img src="/icons/left.svg" className="z-[12]" alt="left-back" />
            Watchlist
          </div>
        </div>
        <div className="sm:hidden block text-white text-[50px] font-roboto-bold pt-[50px] flex items-center gap-8">
          <img src="/images/help/watchlist-detail.svg" alt="watchlist-detail" />
          Watchlist
        </div>
        <div className="mt-10 sm:mt-4 sm:w-full flex max-w-[1416px] w-full grid grid-cols-12">
          <div className="col-span-9 md:col-span-12 sm:col-span-12">
            <div className="w-full mx-auto mb-10 sm:mb-4">
              <div className="w-full bg-transparent rounded shadow-md">
                <div className="font-bold text-[24px] text-white w-full text-left flex justify-between items-center pb-8 sm:pb-4 border-b border-b-1 border-[#464646]">
                  {HelpCenterTitles.watchlist[0].title}
                </div>
                <div
                  className={`mt-8 pb-8 sm:mt-4 sm:pb-[56px] border-b border-b-1 border-[#464646]`}
                >
                  <div className="text-white text-[24px] sm:text-[16px]">
                    <p className="font-normal">
                      Certainly! Here's a response for the help centre of your
                      project regarding how users can view the performance
                      summary of a stock or ETF within the Capiwise app:
                    </p>
                    <div className="mt-4">
                      <div className="font-bold">
                        Viewing Performance Summary of a Stock or ETF
                      </div>
                      <div className="font-normal">
                        At Capiwise, we've designed the app to provide users
                        with easy access to the performance summary of stocks
                        and ETFs. Follow these steps to view the performance
                        summary:
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        1. Search for the Stock or ETF:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Utilize the app's search functionality to find the
                        specific stock or ETF you're interested in. Enter the
                        stock symbol or name in the search bar to locate the
                        asset.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        2. Navigate to the Stock/ETF Profile:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Click on the search result corresponding to the stock or
                        ETF to access its profile. This profile is a
                        comprehensive page that contains various details,
                        including performance metrics.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        3. Performance Metrics Section:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Look for a dedicated section within the stock or ETF
                        profile that displays performance metrics. This section
                        typically includes key information such as:
                        <br />
                        <b>Price Trends: </b>Charts illustrating historical
                        price movements.
                        <br />
                        <b>Percentage Changes: </b>Daily, weekly, monthly, and
                        yearly percentage changes.
                        <br />
                        <b>Key Financial Metrics: </b>Metrics like market
                        capitalization, dividend yield, and other relevant
                        financial data.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        4. Historical Performance Charts:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Explore historical performance charts that visualize the
                        stock or ETF's price movements over different time
                        frames. These charts offer insights into trends and
                        patterns.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">5. Detailed Statistics:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Access detailed statistics related to the stock or ETF's
                        performance. This may include metrics such as beta,
                        volatility, and other statistical measures.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        6. Compare with Benchmarks:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Some apps provide a feature to compare the performance
                        of the selected stock or ETF with relevant benchmarks or
                        indices. This comparative analysis can offer valuable
                        insights.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        7. Earnings and Dividends:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Check for sections that provide information about
                        earnings reports and dividend history. These details
                        contribute to a comprehensive understanding of the stock
                        or ETF's performance.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">8. News and Analysis:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Explore news articles and analysis reports related to
                        the stock or ETF. News updates can offer context to
                        recent performance changes.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">9. Performance Alerts:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Set up performance alerts to receive notifications about
                        significant changes in the stock or ETF's performance.
                        This ensures you stay informed about key developments.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">10. Community Insights:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        If applicable, engage with the community to gather
                        insights and opinions about the stock or ETF's
                        performance. Community discussions can provide
                        additional perspectives.
                      </div>
                    </div>
                    <div className="mt-4 font-normal">
                      By following these steps, you can gain a comprehensive
                      overview of the performance summary of a stock or ETF
                      within the Capiwise app. For more specific details or
                      assistance, refer to our user guide or reach out to our
                      support team.
                    </div>
                  </div>
                  <div className="text-[#979797] text-[16px] mt-[108px] sm:mt-8 sm:text-[10px] ">
                    Was this information helpful?
                  </div>
                  <div className="flex justify-between items-center w-[120px] sm:w-[75px] mt-4">
                    <img
                      src={
                        isThumbUpActive
                          ? "/images/help/thumbup_active.svg"
                          : "/images/help/thumbup_normal.svg"
                      }
                      alt="thumbup"
                      onClick={handleThumbUpClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                    <img
                      src={
                        isThumbDownActive
                          ? "/images/help/thumbdown_active.svg"
                          : "/images/help/thumbdown_normal.svg"
                      }
                      alt="thumbdown"
                      onClick={handleThumbDownClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                  </div>
                </div>
              </div>
              <div className="text-[#0F69FE] text-[24px] mt-[80px] sm:mt-4 sm:text-[16px]">
                More Help
              </div>
              <div onClick={() => navigate(`/help/watchlist/2`)}>
                <HelpCard title={HelpCenterTitles.watchlist[1].title} />
              </div>
              <div onClick={() => navigate(`/help/watchlist/3`)}>
                <HelpCard title={HelpCenterTitles.watchlist[2].title} />
              </div>
              <div onClick={() => navigate(`/help/watchlist/4`)}>
                <HelpCard title={HelpCenterTitles.watchlist[3].title} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Watchlist1;
