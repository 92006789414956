import React, { useEffect, useState } from "react";
import HelpCard from "utils/helpCard";
import { useNavigate } from "react-router-dom";
import { HelpCenterTitles } from "utils/constants";

const GetStarted2 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  const [isThumbUpActive, setIsThumbUpActive] = useState(false);
  const [isThumbDownActive, setIsThumbDownActive] = useState(false);

  const handleThumbUpClick = () => {
    setIsThumbUpActive(!isThumbUpActive);
    setIsThumbDownActive(false);
  };

  const handleThumbDownClick = () => {
    setIsThumbDownActive(!isThumbDownActive);
    setIsThumbUpActive(false);
  };

  return (
    <div className="bg-primary h-max">
      <div className="max-screen-1440 mx-auto px-[28px] sm:px-[18px] md:px-[23px]">
        <div className="sm:block hidden text-white text-[24px] font-medium pt-[14px] flex items-center">
          <div
            className="flex items-center gap-2 mb-4"
            onClick={() => navigate("/help/getstarted")}
          >
            <img src="/icons/left.svg" className="z-[12]" alt="left-back" />
            Get started
          </div>
        </div>
        <div className="sm:hidden block text-white text-[50px] font-roboto-bold pt-[50px] flex items-center gap-8">
          <img
            src="/images/help/getstarted-detail.svg"
            alt="get_started-detail"
          />
          Get started
        </div>
        <div className="mt-10 sm:mt-4 sm:w-full flex max-w-[1416px] w-full grid grid-cols-12">
          <div className="col-span-9 md:col-span-12 sm:col-span-12">
            <div className="w-full mx-auto mb-10 sm:mb-4">
              <div className="w-full bg-transparent rounded shadow-md">
                <div className="font-bold text-[24px] text-white w-full text-left flex justify-between items-center pb-8 sm:pb-4 border-b border-b-1 border-[#464646]">
                  {HelpCenterTitles.getstarted[1].title}
                </div>
                <div
                  className={`mt-8 pb-8 sm:mt-4 sm:pb-[56px] border-b border-b-1 border-[#464646]`}
                >
                  <div className="text-white text-[24px] sm:text-[16px]">
                    <p className="font-normal">
                      To find beginner-friendly resources to understand stock
                      market basics, consider the following options:
                    </p>
                    <div className="mt-4">
                      <div className="font-bold">1. Educational Websites:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Explore reputable educational websites that offer
                        comprehensive resources for beginners. Websites like
                        Investopedia, Investopedia Academy, and Khan Academy
                        have dedicated sections on stock market basics.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">2. Online Courses:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Enroll in online courses designed for beginners.
                        Platforms like Coursera, Udemy, and LinkedIn Learning
                        offer courses ranging from stock market fundamentals to
                        more advanced topics.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">3. Books for Beginners:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Look for beginner-friendly books on stock market basics.
                        Titles like "A Random Walk Down Wall Street" by Burton
                        Malkiel or "The Intelligent Investor" by Benjamin Graham
                        are often recommended for beginners.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        4. Financial News Websites:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Follow financial news websites such as CNBC, Bloomberg,
                        or Yahoo Finance. These platforms often have educational
                        sections or articles that break down stock market
                        concepts for beginners.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">5. Investment Apps:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Consider using investment apps that provide educational
                        resources alongside their services. Some apps, like
                        Robinhood or E*TRADE, offer educational content within
                        the app to help users understand the stock market.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">6. Podcasts:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Listen to podcasts that focus on financial education.
                        Podcasts like "The Motley Fool" or "InvestED Podcast"
                        cover stock market basics and investing principles in an
                        accessible way.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">7. Community Forums:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Join online forums or communities like Reddit's
                        "r/investing" or "r/personalfinance." These platforms
                        often have discussions and resources tailored for
                        beginners, and community members are usually willing to
                        share knowledge.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        8. Financial Literacy Organizations:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Explore resources from financial literacy organizations.
                        Organizations like the National Endowment for Financial
                        Education (NEFE) or the Financial Literacy and Education
                        Commission (FLEC) offer educational materials.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        9. Local Investing Classes or Workshops:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Check if there are any local investing classes,
                        workshops, or seminars offered in your area. Some
                        community colleges, libraries, or financial institutions
                        organize such events for beginners.
                      </div>
                    </div>
                    <div className="mt-4 font-normal">
                      Always verify the credibility of the resources you use and
                      cross-reference information from multiple reputable
                      sources. Additionally, consider consulting with a
                      financial advisor for personalized guidance based on your
                      specific financial goals and situation.
                    </div>
                  </div>
                  <div className="text-[#979797] text-[16px] mt-[108px] sm:mt-8 sm:text-[10px] ">
                    Was this information helpful?
                  </div>
                  <div className="flex justify-between items-center w-[120px] sm:w-[75px] mt-4">
                    <img
                      src={
                        isThumbUpActive
                          ? "/images/help/thumbup_active.svg"
                          : "/images/help/thumbup_normal.svg"
                      }
                      alt="thumbup"
                      onClick={handleThumbUpClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                    <img
                      src={
                        isThumbDownActive
                          ? "/images/help/thumbdown_active.svg"
                          : "/images/help/thumbdown_normal.svg"
                      }
                      alt="thumbdown"
                      onClick={handleThumbDownClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                  </div>
                </div>
              </div>
              <div className="text-[#0F69FE] text-[24px] mt-[80px] sm:mt-4 sm:text-[16px]">
                More Help
              </div>
              <div onClick={() => navigate(`/help/getstarted/1`)}>
                <HelpCard title={HelpCenterTitles.getstarted[0].title} />
              </div>
              <div onClick={() => navigate(`/help/getstarted/3`)}>
                <HelpCard title={HelpCenterTitles.getstarted[2].title} />
              </div>
              <div onClick={() => navigate(`/help/getstarted/4`)}>
                <HelpCard title={HelpCenterTitles.getstarted[3].title} />
              </div>
              <div onClick={() => navigate(`/help/getstarted/5`)}>
                <HelpCard title={HelpCenterTitles.getstarted[4].title} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetStarted2;
