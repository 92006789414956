import React, { useEffect, useState } from "react";
import HelpCard from "utils/helpCard";
import { useNavigate } from "react-router-dom";
import { HelpCenterTitles } from "utils/constants";

const GetStarted5 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  const [isThumbUpActive, setIsThumbUpActive] = useState(false);
  const [isThumbDownActive, setIsThumbDownActive] = useState(false);

  const handleThumbUpClick = () => {
    setIsThumbUpActive(!isThumbUpActive);
    setIsThumbDownActive(false);
  };

  const handleThumbDownClick = () => {
    setIsThumbDownActive(!isThumbDownActive);
    setIsThumbUpActive(false);
  };

  return (
    <div className="bg-primary h-max">
      <div className="max-screen-1440 mx-auto px-[28px] sm:px-[18px] md:px-[23px]">
        <div className="sm:block hidden text-white text-[24px] font-medium pt-[14px] flex items-center">
          <div
            className="flex items-center gap-2 mb-4"
            onClick={() => navigate("/help/getstarted")}
          >
            <img src="/icons/left.svg" className="z-[12]" alt="left-back" />
            Get started
          </div>
        </div>
        <div className="sm:hidden block text-white text-[50px] font-roboto-bold pt-[50px] flex items-center gap-8">
          <img
            src="/images/help/getstarted-detail.svg"
            alt="get_started-detail"
          />
          Get started
        </div>
        <div className="mt-10 sm:mt-4 sm:w-full flex max-w-[1416px] w-full grid grid-cols-12">
          <div className="col-span-9 md:col-span-12 sm:col-span-12">
            <div className="w-full mx-auto mb-10 sm:mb-4">
              <div className="w-full bg-transparent rounded shadow-md">
                <div className="font-bold text-[24px] text-white w-full text-left flex justify-between items-center pb-8 sm:pb-4 border-b border-b-1 border-[#464646]">
                  {HelpCenterTitles.getstarted[4].title}
                </div>
                <div
                  className={`mt-8 pb-8 sm:mt-4 sm:pb-[56px] border-b border-b-1 border-[#464646]`}
                >
                  <div className="text-white text-[24px] sm:text-[16px]">
                    <p className="font-normal">
                      To personalize your profile settings within the app,
                      follow these steps:
                    </p>
                    <div className="mt-4">
                      <div className="font-bold">1. Sign In:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Ensure you are signed in to your account on the app.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">2. Navigate to Profile:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Locate the "Profile" section within the app. This is
                        often represented by an icon or your profile picture.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">3. Access Settings:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Look for a "Settings" or "Account Settings" option
                        within the profile section.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        4. Edit Profile Information:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Choose the "Edit Profile" or similar option to update
                        your personal information. This may include your name,
                        bio, profile picture, or any other details you initially
                        provided during sign-up.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        5. Change Profile Picture:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        If you want to update your profile picture, look for an
                        option to upload a new image. Follow the prompts to
                        select and upload a new profile picture.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        6. Update Contact Information:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        If applicable, update your contact information such as
                        email address or phone number. This is crucial for
                        account management and communication.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">7. Privacy Settings:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Check for privacy settings to control who can see your
                        profile information. Adjust these settings based on your
                        preferences, balancing privacy with the level of
                        visibility you desire.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        8. Notification Preferences:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Explore notification preferences. Choose the types of
                        notifications you want to receive, and adjust settings
                        to tailor your notification experience.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">9. Security Settings:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Review and update security settings. If the app offers
                        features like two-factor authentication (2FA), consider
                        enabling them for enhanced security.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        10. Customize Display Name:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Some apps allow you to customize your display name.
                        Update this if you want your profile to be identified by
                        a specific name or nickname.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        11. Biographical Information:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        If there is a bio or description section, update it with
                        information about yourself. This can provide context to
                        other community members.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        12. Language and Region Preferences:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Check for language and region preferences. Adjust these
                        settings to personalize your experience based on
                        language and location.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">13. Save Changes:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        After making the desired updates, don't forget to save
                        your changes. Look for a "Save" or "Update" button
                        within the profile settings.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        14. Explore Additional Features:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Depending on the app, there may be additional features
                        or settings you can customize. Explore these options to
                        enhance your overall experience.
                      </div>
                    </div>
                    <div className="mt-4 font-normal">
                      By following these steps, you can effectively personalize
                      your profile settings within the app, ensuring that your
                      account reflects your preferences and provides a tailored
                      experience.
                    </div>
                  </div>
                  <div className="text-[#979797] text-[16px] mt-[108px] sm:mt-8 sm:text-[10px] ">
                    Was this information helpful?
                  </div>
                  <div className="flex justify-between items-center w-[120px] sm:w-[75px] mt-4">
                    <img
                      src={
                        isThumbUpActive
                          ? "/images/help/thumbup_active.svg"
                          : "/images/help/thumbup_normal.svg"
                      }
                      alt="thumbup"
                      onClick={handleThumbUpClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                    <img
                      src={
                        isThumbDownActive
                          ? "/images/help/thumbdown_active.svg"
                          : "/images/help/thumbdown_normal.svg"
                      }
                      alt="thumbdown"
                      onClick={handleThumbDownClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                  </div>
                </div>
              </div>
              <div className="text-[#0F69FE] text-[24px] mt-[80px] sm:mt-4 sm:text-[16px]">
                More Help
              </div>
              <div onClick={() => navigate(`/help/getstarted/1`)}>
                <HelpCard title={HelpCenterTitles.getstarted[0].title} />
              </div>
              <div onClick={() => navigate(`/help/getstarted/2`)}>
                <HelpCard title={HelpCenterTitles.getstarted[1].title} />
              </div>
              <div onClick={() => navigate(`/help/getstarted/3`)}>
                <HelpCard title={HelpCenterTitles.getstarted[2].title} />
              </div>
              <div onClick={() => navigate(`/help/getstarted/4`)}>
                <HelpCard title={HelpCenterTitles.getstarted[3].title} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetStarted5;
