import React, { Component } from "react";
import stickybits from "stickybits";
import { CSSTransition, TransitionGroup } from "react-transition-group";

export default class ScrollJacker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      childrenCount: 0,
      currentPage: 0,
      currentProgress: 0,
    };

    this.increment = this.props.increment || 120;
    this.height = this.props.height || 200;
    this.id = this.props.id || "STC-sticky-child";
  }

  componentDidMount() {
    let { children, stickyOffset, scrollSensitivity } = this.props;
    scrollSensitivity = scrollSensitivity < 1 ? 1 : scrollSensitivity;
    scrollSensitivity = scrollSensitivity > 9 ? 9 : scrollSensitivity;

    if (children) {
      this.setState({
        childrenCount: React.Children.count(children),
      });
    }

    if (window) {
      window.addEventListener("scroll", this.updateCurrentPage);
      stickybits("#" + this.id, {
        stickyBitStickyOffset: stickyOffset || 0,
      });
    }
  }

  componentWillUnmount() {
    if (window) {
      window.removeEventListener("scroll", this.updateCurrentPage);
    }
  }

  updateCurrentPage = () => {
    if (this.state.currentPage !== this.getCurrentPage()) {
      this.setState({ currentPage: this.getCurrentPage() });
    }
    this.setState({ currentProgress: this.getProgress() });
  };

  getCurrentPage() {
    const { childrenCount } = this.state;
    if (childrenCount < 2 || this.container.getBoundingClientRect().top > 0) {
      return 0;
    }

    const progress = Math.abs(this.container.getBoundingClientRect().top);
    const output = Math.floor(progress / this.increment);

    return output > childrenCount - 1 ? childrenCount - 1 : output;
  }

  getProgress() {
    const { childrenCount } = this.state;
    if (childrenCount < 2 || this.container.getBoundingClientRect().top > 0) {
      return 0;
    }

    const progress = Math.abs(this.container.getBoundingClientRect().top);
    if (progress / this.increment > childrenCount) {
      return 1;
    }

    return progress / this.increment - Math.floor(progress / this.increment);
  }

  injectedChildren() {
    const { children } = this.props;
    return React.Children.map(children, (x) => {
      if (React.isValidElement(x)) {
        return React.cloneElement(x, {
          currentPage: this.getCurrentPage(),
          progress: this.getProgress(),
        });
      }
      return x;
    });
  }

  renderChild() {
    const { injectChildren, children } = this.props;
    const { currentPage } = this.state;
    return injectChildren
      ? this.injectedChildren()[currentPage]
      : children[this.getCurrentPage()];
  }

  render() {
    return (
      <div
        className={this.props.className}
        ref={(container) => {
          this.container = container;
        }}
        style={{
          ...this.props.style,
          height: `${this.height}px`,
        }}
      >
        <div id={this.id}>
          <TransitionGroup id="slide-container">
            <CSSTransition
              key={this.state.currentPage}
              timeout={500}
              classNames="fade"
            >
              <div>{this.renderChild()}</div>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    );
  }
}
