import HomeLayout from "layout/HomeLayout";
import React, { useEffect } from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Section1 from "components/Landing-Page/Section1";
import Section2 from "components/Landing-Page/Section2";
import Section3 from "components/Landing-Page/Section3";
import Section4 from "components/Landing-Page/Section4";

function LandingPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-primary">
      <HomeLayout>
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        {/* <div className="geniune">
          <section className=" max-screen-1440 flex sm:flex-col bg-no-repeat bg-cover bg-[url('../public/images/background/genuine.svg')] sm:bg-[url('../public/images/background/geniuine-mbl.png')] sm:mx-auto sm:w-[100%]  h-[798px] md:h-[466px] sm:h-[498px] ">
            <div className="pl-[51px] w-[594px] sm:w-[100%] sm:pl-0">
              <div className="font-roboto-bold md:mb-[32px] text-bmd mt-[250px] md:mt-[133px] md:text-[28px] sm:text-center mb-[50px] sm:mt-[33px] sm:mb-[18px] sm:text-17">
                Genuine human backing
              </div>
              <div className="text-primary md:text-[16px] text-md sm: font-reg mb-[50px] md:mb-[32px] sm:mb-[14px] sm:text-center sm:mx-[33px] sm:text-12">
                Our dedicated{" "}
                <span className="font-roboto-bold ">
                  team of experts is here to listen, guide, and assist you
                </span>{" "}
                in making wise investment decisions.
              </div>
              <img
                src="/images/section-4.svg"
                alt=""
                className="sm:w-[177px] hidden sm:block mx-auto"
              />
              <Link to={"/contact"}>
                <button className="arrow-button justify-center w-[287px] bg-primary mt-[88px] md:mt-[52px] md:h-[41px] h-[56px] md:w-[190px] sm:h-[42px] border sm:mt-[21px] sm:w-[90%] sm:mx-auto border-white sm:border-light-green text-white font-reg flex sm:justify-center items-center sm:pl-[0] pl-[35px] text-base sm:text-17 rounded-[39px] relative hover:bg-light-green sm:hover:border-white hover:border-light-green">
                  <div className="arrow absolute left-[50px] sm:left-[70px]">
                    <div className="head"></div>
                  </div>
                  <span> Contact us</span>
                </button>
              </Link>
            </div>
            <img
              className="h-[638px] mt-[70px]  ml-[162px] md:h-[372px] md:mr-[15%] md:ml-[20px] md:mt-[56px]  md:w-[275px] sm:hidden"
              src="/images/section-4.svg"
              alt=""
            />
          </section>
        </div> */}
      </HomeLayout>
    </div>
  );
}

export default LandingPage;
