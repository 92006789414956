import React, { useEffect } from "react";

const Learning4 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="w-[85%] mx-auto bg-white px-[48px] sm:px-[18px] mt-[-260px] sm:mt-[-190px] sm:w-[100%] rounded-[10px] text-[#050505]">
      <div className="pt-[64px] font-bold text-[34px] leading-[33px] text-center sm:text-[17px] sm:leading-[23px]">
        Crafting Your Financial Destiny: A Novice's
        <br className="block sm:hidden" /> Guide to Setting Investment Goals
      </div>
      <div className="text-[24px] font-normal text-center my-[48px] sm:my-[24px] sm:text-[12px] sm:leading-[23px] sm:text-start ">
        Greetings, aspiring investors! If you've ever felt the allure of the
        stock market but found the financial jargon overwhelming, fear not. This
        blog is your roadmap to demystifying the world of investments, starting
        with the crucial step of setting meaningful goals. Let's embark on this
        journey together and carve out a path to financial success.
      </div>
      <div className="flex justify-center mb-[30px] ">
        <img src="/images/learnings/learning-4.png" alt="" className="w-full" />
      </div>
      <div>
        <div className="w-full text-start font-bold text-[24px] leading-[33px] mb-[30px] sm:mb-[20px] sm:text-[17px] sm:leading-[23px]">
          1. Defining Your Financial Aspirations
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px]">
          1.1 The Power of Goals:
          <p className="indent-4">
            Unleash the potential of your investments by defining clear and
            realistic financial goals. Whether it's buying a home, funding your
            child's education, or retiring comfortably, setting objectives is
            the first step to success.
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px] my-6 sm:my-3">
          1.2 Short-term vs. Long-term Goals:
          <p className="indent-4">
            Distinguish between short-term goals (1-3 years) and long-term goals
            (5 years or more). Understanding your time horizon helps tailor your
            investment strategy.
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px]">
          1.3 Prioritizing Goals:
          <p className="indent-4">
            Not all goals are created equal. Prioritize your objectives based on
            urgency, significance, and feasibility, creating a roadmap for your
            investment journey.
          </p>
        </div>
      </div>
      <div className="mt-[48px] sm:mt-[24px]">
        <div className="w-full text-start font-bold text-[24px] leading-[33px] mb-[30px] sm:mb-[20px] sm:text-[17px] sm:leading-[23px]">
          2. Assessing Your Risk Tolerance
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px]">
          2.1 Understanding Risk:
          <p className="indent-4">
            Risk is an inherent part of investing. Grasp the concept that higher
            potential returns often come with higher levels of risk.
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px] my-6 sm:my-3">
          2.2 Risk Tolerance Assessment:
          <p className="indent-4">
            Evaluate your comfort level with market fluctuations. Are you a
            risk-taker or someone who prefers a more conservative approach? Your
            risk tolerance shapes your investment strategy.
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px]">
          2.3 Aligning Risk with Goals:
          <p className="indent-4">
            Match the risk level of your investments with your goals. Short-term
            goals may require more stable, low-risk investments, while long-term
            goals can accommodate a higher level of risk.
          </p>
        </div>
      </div>
      <div className="mt-[48px] sm:mt-[24px]">
        <div className="w-full text-start font-bold text-[24px] leading-[33px] mb-[30px] sm:mb-[20px] sm:text-[17px] sm:leading-[23px]">
          3. Quantifying Your Financial Dreams
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px]">
          3.1 Setting Specific Targets:
          <p className="indent-4">
            Be specific about your financial objectives. Instead of saying "I
            want to retire comfortably," quantify it – "I aim to accumulate $1
            million for retirement."
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px] my-6 sm:my-3">
          3.2 Factoring in Inflation:
          <p className="indent-4">
            Account for the eroding power of inflation when setting monetary
            goals. Ensure your investments outpace inflation to maintain
            purchasing power.
          </p>
        </div>
      </div>
      <div className="mt-[48px] sm:mt-[24px]">
        <div className="w-full text-start font-bold text-[24px] leading-[33px] mb-[30px] sm:mb-[20px] sm:text-[17px] sm:leading-[23px]">
          4. Creating an Investment Plan
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px]">
          4.1 Diversification:
          <p className="indent-4">
            Don't put all your eggs in one basket. Diversify your investment
            portfolio by spreading assets across different classes, reducing
            risk exposure.
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px] my-6 sm:my-3">
          4.2 Asset Allocation:
          <p className="indent-4">
            Allocate your investments wisely based on your goals, risk
            tolerance, and time horizon. A mix of stocks, bonds, and other
            assets can help strike the right balance.
          </p>
        </div>
      </div>
      <div className="mt-[48px] sm:mt-[24px]">
        <div className="w-full text-start font-bold text-[24px] leading-[33px] mb-[30px] sm:mb-[20px] sm:text-[17px] sm:leading-[23px]">
          5. Monitoring and Adjusting
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px]">
          5.1 Regular Portfolio Review:
          <p className="indent-4">
            Keep a watchful eye on your investment portfolio. Regularly review
            its performance against your goals and make adjustments as needed.
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px] my-6 sm:my-3">
          5.2 Adapting to Life Changes:
          <p className="indent-4">
            Life is dynamic, and so are your financial circumstances. Be ready
            to adapt your investment plan to changes in income, expenses, and
            goals.
          </p>
        </div>
      </div>
      <div className="mt-[48px] sm:mt-[24px] pb-[96px] text-[24px] font-normal leading-[33px] text-start sm:text-[12px] sm:leading-[23px] sm:text-start">
        Congratulations! You've just laid the foundation for a successful
        investment journey by setting clear goals. Armed with this newfound
        knowledge, you're well on your way to navigating the complex yet
        rewarding world of finance. Stay focused, stay informed, and watch your
        financial dreams unfold. Happy investing!
      </div>
    </div>
  );
};

export default Learning4;
