import { Header } from "components/Header";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import axios from "axios";
import { useFormik } from "formik";
import { generateSchema } from "validation";
// import nodemailer from "nodemailer";

function LaunchOffer({ open, onClose, onClick }) {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(false);
      if (window.innerWidth < 1000) {
        setIsMobile(true);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const initialValues = {
    fullName: "",
    email: "",
  };

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    }
  }, [open]);

  const onSubmit = async (values) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL,
        {
          subject: `Get Early Access Request from ${values.email}`,
          body: ` <html>
                <body>
                  <h2>Following are the details:</h2>
                  <p>Name: ${values.fullName}</p>
                  <p>Email: ${values.email}</p>
                </body>
              </html>`,
          is_html_body: true,
        },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "Content-Type": "application/json",
          },
        }
      );

      if (response) {
        onClick();
      }
    } catch (error) {
      console.error("There was an error sendng the email:", error);
    }
  };

  // const { values, errors, handleChange, handleSubmit, handleBlur } = useFormik({
  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues,
    onSubmit,
    validationSchema: generateSchema(initialValues),
  });

  return (
    <Modal
      isOpen={open}
      overlayClassName="modal-overlay"
      className={`bg-[#CEFBE3] absolute sm:top-0 sm:left-0 sm:h-full sm:w-full max-w-1440 mx-auto inset-[40px] h-[781px] rounded-[4px] pt-[103px] sm:pt-0 pl-[74px] sm:px-0 outline-none`}
    >
      {isMobile ? (
        <Header
          icon="/icons/mbl-header-close.svg"
          onClick={() => {
            document.body.style.overflow = "auto";
            onClose();
          }}
        />
      ) : (
        <div className="hover:bg-[#8ef5cd] p-[10px]  rounded-[50%] top-[29px] absolute right-[45px] cursor-pointer">
          <img
            src="/icons/close.svg"
            onClick={() => {
              document.body.style.overflow = "auto";
              onClose();
            }}
            alt=""
          />
        </div>
      )}

      <div className="flex sm:px-[16px] sm:pt-[27px]">
        <div>
          <div className="font-roboto-bold sm:text-base text-[60px] w-[550px]  sm:w-full tracking-tight text-primary leading-normal sm:text-center">
            Unlock the Future of Investing
          </div>
          <div className="font-roboto-bold sm:text-17 w-[517px] sm:w-full text-[32px] tracking-tighter text-primary leading-normal mt-[32px] sm:mt-[17px] sm:mb-[28px] sm:text-center mb-[56px]">
            Join the waitlist and get a head start on Exclusive Launch Offers!
          </div>
          <div>
            <input
              type="text"
              className="h-[54px] rounded-[8px] sm:w-[100%] w-[420px] outline-primary indent-7"
              placeholder="Full name"
              name="fullName"
              // handleBlur={handleBlur}
              onChange={handleChange}
              values={values.fullName}
            />
            {errors.fullName && (
              <div className="text-red-500 text-12 mt-[2px]">
                {errors.fullName}
              </div>
            )}
          </div>
          <div className="mb-[64px] mt-[32px]">
            <input
              type="text"
              placeholder="E-mail"
              className="h-[54px] sm:w-full rounded-[8px] w-[420px] outline-primary indent-7"
              name="email"
              // handleBlur={handleBlur}
              onChange={handleChange}
              values={values.email}
            />
            {errors.email && (
              <div className="text-red-500 text-12 mt-[2px]">
                {errors.email}
              </div>
            )}
          </div>
          <button
            onClick={handleSubmit}
            className="arrow-button sm:mb-[32px] bg-black w-[329px] justify-center mt-[88px] md:mt-[52px] h-[56px] sm:h-[42px] border sm:mt-[21px] sm:w-[90%] sm:mx-auto sm:border-black sm:hover:border-white text-white font-roboto-bold flex sm:justify-center items-center sm:pl-[0] pl-[35px]  text-base sm:text-17 rounded-[50px] relative hover:bg-light-green "
          >
            <div className="arrow absolute left-[20px]">
              <div className="head"></div>
            </div>
            <span> Get Early Access</span>
          </button>
          {/* <button
            onClick={onClick}
            className="bg-black rounded-[50px] w-[278px] h-[50px] sm:h-[42px] sm:w-full sm:tracking-[0.141px] sm:text-17 text-white font-roboto-bold text-[24px] tracking-[0.141px]"
          >
            Get Early Access
          </button> */}
        </div>
        <img
          src="/images/modal/launch.svg"
          // className="sm:hidden w-[735px] absolute right-0"
          className="sm:hidden  w-[735px] md:hidden  lg:w-1/2 absolute right-0"
          // className="sm:hidden  w-[735px] md:hidden w-3/5 h-2/5 md:w-1/5  absolute right-0"
          alt=""
        />
      </div>
    </Modal>
  );
}

export default LaunchOffer;
