import Rating from "components/Rating";
import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const Card = ({ courses, items, learning }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const settings = {
    dots: learning ? false : true,
    infinite: false,
    arrows: false,
    speed: 500,
    initialSlide: 0,
    slidesToShow: 3,
    adaptiveHeight: true,
    afterChange: (indexOfCurrentSlide) => {
      setCurrentSlide(indexOfCurrentSlide);
    },
    appendDots: (dots) => (
      <>
        <ul className="flex justify-center mt-[30px] pb-[10px] gap-4">
          {dots}
        </ul>
      </>
    ),
    customPaging: (i) => (
      <div
        className={
          i === currentSlide
            ? "bg-light-green  rounded-full w-[15px] h-[15px] cursor-pointer"
            : "bg-[#979797] rounded-full w-[15px] h-[15px] cursor-pointer"
        }
      ></div>
    ),
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,

          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1.6,

          dots: false,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2.5,

          dots: false,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 2,

          dots: false,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1.6,

          dots: false,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1.6,

          dots: false,
        },
      },
      {
        breakpoint: 370,
        settings: {
          slidesToShow: 1.6,

          dots: false,
        },
      },
    ],
  };
  return (
    <Slider {...settings}>
      {items?.map((item, index) => (
        <div key={index}>
          <div
            className={`w-[423px] md:w-[400px] sm:w-[calc(100%-16px)] ${
              courses
                ? "h-[646px] md:h-[500px] sm:h-[363px]"
                : "h-[610px] md:h-[500px] sm:h-[349px]"
            }  border-2 border-white hover:border-light-green  rounded-[10px] cursor-pointer`}
          >
            <img
              src={item?.path}
              alt=""
              className={`mx-auto my-[25px] ${
                courses || learning
                  ? "sm:w-[157px] md:w-[250px] md:h-[230px] sm:h-[158px]"
                  : "sm:w-[114px]  sm:h-[177px]"
              }`}
              onClick={() => {
                !learning && window.open(item?.link, "_blank");
              }}
            />
            <div
              onClick={() => {
                !learning && window.open(item?.link, "_blank");
              }}
              className="font-med text-white leading-[29px] tracking-[0.1px] h-[87px] text-[24px] mx-[20px] sm:h-[62px] md:text-[20px] sm:text-[17px] sm:leading-[20px] sm:text-clip overflow-hidden "
            >
              {item?.title}
            </div>
            <div className="mt-[25px] mx-[20px] text-white text-[17px] leading-[18px] tracking-[0.25px] h-[70px] sm:hidden md:hidden">
              {item?.para}
            </div>
            {!learning && (
              <div className="mx-[20px] my-[26px] flex justify-between sm:my-[15px]">
                <div
                  key={index}
                  className="text-white text-[24px] leading-[20px] tracking-[0.1px] sm:text-[17px] sm:leading-[20px] flex items-center mr-[10px]"
                >
                  {item?.rating}
                  <>
                    <Rating rating={item?.rating} key={index} index={index} />
                  </>
                </div>
                <img
                  src="/images/share.svg"
                  alt=""
                  className="sm:w-[15px] sm:h-[15px]"
                />
              </div>
            )}
            {courses && (
              <div className="mx-[20px] my-[30px] flex sm:my-[20px]">
                <img
                  src={item?.icon}
                  alt=""
                  className="sm:w-[14px] sm:h-[14px]"
                />
                <div className="pl-[5px] font-reg text-[17px] leading-[19px] tracking-[0.28px] text-white sm:text-[12px] sm:leading-[14px]">
                  Source: {item?.source}
                </div>
              </div>
            )}
            {learning && (
              <div className="mx-[20px] my-[30px] flex justify-between">
                <div className="flex">
                  <img src="/images/clock.svg" alt="" className=" mr-[5px]" />
                  <div className="text-[17px] text-white font-reg leading-[45px]">
                    5 min read
                  </div>
                </div>
                <button className="bg-light-green w-[170px] h-[42px] text-white text-[17px] leading-[20px] rounded-[100px] font-roboto-bold">
                  Read article
                </button>
              </div>
            )}
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default Card;
