import React from "react";

const HelpCard = ({ title }) => {
  return (
    <div className="py-4 sm:py-2 w-full bg-transparent rounded shadow-md">
      <button className="w-full text-left flex justify-between items-center focus:outline-none pb-8 sm:pb-4 border-b border-b-1 border-[#464646]">
        <div className="font-normal text-[24px] text-white sm:text-[16px] sm:w-[90%]">
          {title}
        </div>
        <img src="/icons/right-go.svg" alt="" className="sm:h-[10px]" />
      </button>
    </div>
  );
};

export default HelpCard;
