import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HelpCard from "utils/helpCard";
import { HelpCenterTitles } from "utils/constants";

const Watchlist = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  return (
    <div className="bg-primary h-max">
      <div className="max-screen-1440 mx-auto px-[28px] sm:px-[18px] md:px-[23px]">
        <div className="sm:block hidden text-white text-[24px] font-medium pt-[14px] flex items-center">
          <div
            className="flex items-center gap-2 mb-4"
            onClick={() => navigate("/help")}
          >
            <img src="/icons/left.svg" className="z-[12]" alt="left-back" />
            Watchlist
          </div>
          <img
            src="/images/help/watchlist-detail.svg"
            alt="watchlist-detail"
            className="w-[40px]"
          />
        </div>
        <div className="sm:hidden block text-white text-[50px] font-roboto-bold pt-[50px] flex items-center gap-8">
          <img src="/images/help/watchlist-detail.svg" alt="watchlist-detail" />
          Watchlist
        </div>
        <div className="mt-10 sm:mt-0 sm:w-full flex max-w-[1416px] w-[92%] grid grid-cols-12 gap-8">
          <div className="col-span-8 sm:col-span-12">
            <div className="text-[24px] font-semibold sm:text-[16px] text-left text-white mt-8">
              Monitor Your Investment Potential
            </div>
            <div className="text-[16px] sm:text-[10px] text-left text-white mt-8 sm:mt-4">
              Visualize and monitor stocks and ETFs that pique your interest
              without direct investment capabilities within the app. Explore
              performance summaries of various stocks and ETFs, deciding whether
              to include them in your watchlist. Your watchlist acts as a
              convenient hub for tracking the performance of these assets,
              offering insights for potential external investment decisions.
            </div>
            <div className="my-[64px] sm:my-6">
              <div className="text-[#0F69FE] text-[24px] font-medium sm:text-[16px] leading-tight">
                Related questions
              </div>
              <div className="w-full mx-auto mt-6 sm:pr-[12px]">
                {HelpCenterTitles.watchlist.map((item, index) => (
                  <div onClick={() => navigate(`${index + 1}`)} key={index}>
                    <HelpCard title={item.title} />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-span-4 sm:hidden">
            <img src="/images/help/watchlist.png" alt="watchlist" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Watchlist;
