import React, { useEffect, useState } from "react";
import HelpCard from "utils/helpCard";
import { useNavigate } from "react-router-dom";
import { HelpCenterTitles } from "utils/constants";

const Notifications2 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  const [isThumbUpActive, setIsThumbUpActive] = useState(false);
  const [isThumbDownActive, setIsThumbDownActive] = useState(false);

  const handleThumbUpClick = () => {
    setIsThumbUpActive(!isThumbUpActive);
    setIsThumbDownActive(false);
  };

  const handleThumbDownClick = () => {
    setIsThumbDownActive(!isThumbDownActive);
    setIsThumbUpActive(false);
  };

  return (
    <div className="bg-primary h-max">
      <div className="max-screen-1440 mx-auto px-[28px] sm:px-[18px] md:px-[23px]">
        <div className="sm:block hidden text-white text-[24px] font-medium pt-[14px] flex items-center">
          <div
            className="flex items-center gap-2 mb-4"
            onClick={() => navigate("/help/notifications")}
          >
            <img src="/icons/left.svg" className="z-[12]" alt="left-back" />
            Notifications
          </div>
        </div>
        <div className="sm:hidden block text-white text-[50px] font-roboto-bold pt-[50px] flex items-center gap-8">
          <img
            src="/images/help/notifications-detail.svg"
            alt="notifications-detail"
          />
          Notifications
        </div>
        <div className="mt-10 sm:mt-4 sm:w-full flex max-w-[1416px] w-full grid grid-cols-12">
          <div className="col-span-9 md:col-span-12 sm:col-span-12">
            <div className="w-full mx-auto mb-10 sm:mb-4">
              <div className="w-full bg-transparent rounded shadow-md">
                <div className="font-bold text-[24px] text-white w-full text-left flex justify-between items-center pb-8 sm:pb-4 border-b border-b-1 border-[#464646]">
                  {HelpCenterTitles.notifications[1].title}
                </div>
                <div
                  className={`mt-8 pb-8 sm:mt-4 sm:pb-[56px] border-b border-b-1 border-[#464646]`}
                >
                  <div className="text-white text-[24px] sm:text-[16px]">
                    <p className="font-normal">
                      The specific types of notifications you receive will
                      depend on the features and functionalities of the app.
                      However, here are common types of notifications you might
                      encounter in an investment or finance app:
                    </p>
                    <div className="mt-4">
                      <div className="font-bold">1. Price Alerts:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Notifications about significant price movements for
                        stocks, ETFs, or other financial instruments you are
                        tracking.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">2. News Updates:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Alerts for breaking news, market analysis, or updates
                        related to stocks and financial markets.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">3. Earnings Reports:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Notifications about upcoming or released earnings
                        reports for companies on your watchlist.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        4. Community Interactions:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Notifications for new followers, likes, comments, or
                        mentions within the app's community or social features.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">5. Portfolio Changes:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Alerts about changes in the value of your investment
                        portfolio, daily summaries, or performance updates.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">6. Market Insights:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Notifications providing market insights, trends, or
                        analysis from the app's experts or community.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        7. Announcements and Updates:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Notifications about new features, updates, or
                        announcements related to the app.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">8. Educational Content:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Alerts for educational content, webinars, or articles
                        that can enhance your understanding of investment
                        topics.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        9. Reminders and Calendar Events:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Notifications for events such as earnings releases,
                        dividend payments, or other important dates on your
                        watchlist.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">10. Security Alerts:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Notifications related to security, such as login
                        attempts, password changes, or two-factor authentication
                        requests.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        11. Survey or Poll Invitations:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Invitations to participate in surveys or polls within
                        the app to gather user feedback.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        12. General Account Information:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Alerts related to account activities, such as account
                        verifications, updates to terms of service, or account
                        status changes.
                      </div>
                    </div>
                    <div className="mt-4 font-normal">
                      Remember that you can often customize these notifications
                      based on your preferences. Check the app's settings or
                      notification preferences to enable or disable specific
                      types of alerts, set frequency, or adjust other parameters
                      to tailor your notification experience. Always review the
                      app's documentation or help centre for detailed
                      information on the types of notifications it provides.
                    </div>
                  </div>
                  <div className="text-[#979797] text-[16px] mt-[108px] sm:mt-8 sm:text-[10px] ">
                    Was this information helpful?
                  </div>
                  <div className="flex justify-between items-center w-[120px] sm:w-[75px] mt-4">
                    <img
                      src={
                        isThumbUpActive
                          ? "/images/help/thumbup_active.svg"
                          : "/images/help/thumbup_normal.svg"
                      }
                      alt="thumbup"
                      onClick={handleThumbUpClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                    <img
                      src={
                        isThumbDownActive
                          ? "/images/help/thumbdown_active.svg"
                          : "/images/help/thumbdown_normal.svg"
                      }
                      alt="thumbdown"
                      onClick={handleThumbDownClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                  </div>
                </div>
              </div>
              <div className="text-[#0F69FE] text-[24px] mt-[80px] sm:mt-4 sm:text-[16px]">
                More Help
              </div>
              <div onClick={() => navigate(`/help/notifications/1`)}>
                <HelpCard title={HelpCenterTitles.notifications[0].title} />
              </div>
              <div onClick={() => navigate(`/help/notifications/3`)}>
                <HelpCard title={HelpCenterTitles.notifications[2].title} />
              </div>
              <div onClick={() => navigate(`/help/notifications/4`)}>
                <HelpCard title={HelpCenterTitles.notifications[3].title} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications2;
