import React, { useEffect, useState } from "react";
import HelpCard from "utils/helpCard";
import { useNavigate } from "react-router-dom";
import { HelpCenterTitles } from "utils/constants";

const Notifications1 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  const [isThumbUpActive, setIsThumbUpActive] = useState(false);
  const [isThumbDownActive, setIsThumbDownActive] = useState(false);

  const handleThumbUpClick = () => {
    setIsThumbUpActive(!isThumbUpActive);
    setIsThumbDownActive(false);
  };

  const handleThumbDownClick = () => {
    setIsThumbDownActive(!isThumbDownActive);
    setIsThumbUpActive(false);
  };

  return (
    <div className="bg-primary h-max">
      <div className="max-screen-1440 mx-auto px-[28px] sm:px-[18px] md:px-[23px]">
        <div className="sm:block hidden text-white text-[24px] font-medium pt-[14px] flex items-center">
          <div
            className="flex items-center gap-2 mb-4"
            onClick={() => navigate("/help/notifications")}
          >
            <img src="/icons/left.svg" className="z-[12]" alt="left-back" />
            Notifications
          </div>
        </div>
        <div className="sm:hidden block text-white text-[50px] font-roboto-bold pt-[50px] flex items-center gap-8">
          <img
            src="/images/help/notifications-detail.svg"
            alt="notifications-detail"
          />
          Notifications
        </div>
        <div className="mt-10 sm:mt-4 sm:w-full flex max-w-[1416px] w-full grid grid-cols-12">
          <div className="col-span-9 md:col-span-12 sm:col-span-12">
            <div className="w-full mx-auto mb-10 sm:mb-4">
              <div className="w-full bg-transparent rounded shadow-md">
                <div className="font-bold text-[24px] text-white w-full text-left flex justify-between items-center pb-8 sm:pb-4 border-b border-b-1 border-[#464646]">
                  {HelpCenterTitles.notifications[0].title}
                </div>
                <div
                  className={`mt-8 pb-8 sm:mt-4 sm:pb-[56px] border-b border-b-1 border-[#464646]`}
                >
                  <div className="text-white text-[24px] sm:text-[16px]">
                    <p className="font-normal">
                      To manage your notification settings within the app,
                      follow these general steps:
                    </p>
                    <div className="mt-4">
                      <div className="font-bold">1. Sign In:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Ensure you are signed in to your account on the app.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">2. Navigate to Settings:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Locate the "Settings" or "Account Settings" option
                        within the app. This is often found in the profile
                        section or a dedicated settings menu.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        3. Access Notification Preferences:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Look for a specific section related to notifications.
                        This might be labelled as "Notification Preferences,"
                        "Alerts," or something similar.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        4. Review Notification Types:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Check the different types of notifications available.
                        These may include alerts for new messages, updates on
                        followed stocks, community interactions, or other
                        relevant events.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        5. Toggle Switches On/Off:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        For each notification type, use toggle switches or
                        checkboxes to enable or disable notifications based on
                        your preferences. Turning a switch on usually means
                        you'll receive notifications for that type.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        6. Customize Sound or Vibration (Optional):
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Some apps allow you to customize notification sounds or
                        vibrations. Explore these options if you want to
                        differentiate between different types of notifications.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        7. Set Frequency (If Applicable):
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        If the app offers frequency settings, determine how
                        often you want to receive certain notifications. Options
                        might include real-time, hourly, daily, or weekly
                        updates.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        8. Adjust Time Settings (If Applicable):
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        For time-sensitive notifications, check if there are
                        settings to control when you receive notifications.
                        Adjust these settings based on your preferred time zone
                        or schedule.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        9. Community Interaction Notifications:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        If the app includes a community or social aspect,
                        configure settings for notifications related to new
                        followers, likes, comments, or other community
                        interactions.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">10. Save Changes:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        After customizing your notification preferences, save
                        your changes. Look for a "Save" or "Update" button
                        within the notification settings.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        11. Test Notifications (Optional):
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Some apps provide a test option to ensure that your
                        notification settings are configured correctly. If
                        available, use this feature to test different
                        notification types.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        12. Review Platform-Specific Options:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Depending on the platform (iOS, Android, etc.), there
                        might be additional notification settings available in
                        your device settings. Check these options for further
                        customization.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        13. Explore In-App Notification Center:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Some apps have an in-app notification centre where you
                        can review a history of notifications. Familiarize
                        yourself with this feature for comprehensive
                        notification management.
                      </div>
                    </div>
                  </div>
                  <div className="text-[#979797] text-[16px] mt-[108px] sm:mt-8 sm:text-[10px] ">
                    Was this information helpful?
                  </div>
                  <div className="flex justify-between items-center w-[120px] sm:w-[75px] mt-4">
                    <img
                      src={
                        isThumbUpActive
                          ? "/images/help/thumbup_active.svg"
                          : "/images/help/thumbup_normal.svg"
                      }
                      alt="thumbup"
                      onClick={handleThumbUpClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                    <img
                      src={
                        isThumbDownActive
                          ? "/images/help/thumbdown_active.svg"
                          : "/images/help/thumbdown_normal.svg"
                      }
                      alt="thumbdown"
                      onClick={handleThumbDownClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                  </div>
                </div>
              </div>
              <div className="text-[#0F69FE] text-[24px] mt-[80px] sm:mt-4 sm:text-[16px]">
                More Help
              </div>
              <div onClick={() => navigate(`/help/notifications/2`)}>
                <HelpCard title={HelpCenterTitles.notifications[1].title} />
              </div>
              <div onClick={() => navigate(`/help/notifications/3`)}>
                <HelpCard title={HelpCenterTitles.notifications[2].title} />
              </div>
              <div onClick={() => navigate(`/help/notifications/4`)}>
                <HelpCard title={HelpCenterTitles.notifications[3].title} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications1;
