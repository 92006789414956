import LearningPage from "pages/learning-page";
import { LandingPage, AboutPage, Conatct, StoresPage, HelpPage, PrivacyPolicy, TermsOfUse } from "pages";
import SingleLearningPage from 'pages/singleLearning-page';
import SingleHelpPage from "pages/singleHelp-page";
import SingleHelpGetStarted from "components/Help/SingleHelpGetStarted";
import SingleHelpNotifications from "components/Help/SingleHelpNotifications";
import SingleHelpSecurity from "components/Help/SingleHelpSecurity";
import SingleHelpMarkets from "components/Help/SingleHelpMarkets";
import SingleHelpCommunity from "components/Help/SingleHelpCommunity";
import SingleHelpWatchlist from "components/Help/SingleHelpWatchlist";

// List of Routes name using for links and route
export const routes = Object.freeze({
  ROUTE_LANDING_PAGE: "/",
  ROUTE_LEARNING_PAGE: "/learning",
  ROUTE_ABOUT_PAGE: "/about",
  ROUTE_CONTACT_PAGE: "/contact",
  ROUTE_PRIVACY_PAGE: "/privacypolicy",
  ROUTE_TERMS_PAGE: "/terms",
  ROUTE_SINGLE_LEARNING: "/learning/:id",
  ROUTE_STORE: "/stores",
  ROUTE_HELP_PAGE: "/help",
  ROUTE_SINGLE_HELP: "/help/:id",
  ROUTE_SINGLE_HELP_GETSTARTED: "/help/getstarted/:id",
  ROUTE_SINGLE_HELP_NOTIFICATIONS: "/help/notifications/:id",
  ROUTE_SINGLE_HELP_SECURITY: "/help/security/:id",
  ROUTE_SINGLE_HELP_MARKETS: "/help/markets/:id",
  ROUTE_SINGLE_HELP_COMMUNITY: "/help/community/:id",
  ROUTE_SINGLE_HELP_WATCHLIST: "/help/watchlist/:id",
});

export const AllRoutes = [
  {
    path: routes.ROUTE_ABOUT_PAGE,
    page: <AboutPage />,
    isPrivate: false,
  },
  {
    path: routes.ROUTE_CONTACT_PAGE,
    page: <Conatct />,
    isPrivate: false,
  },
  {
    path: routes.ROUTE_PRIVACY_PAGE,
    page: <PrivacyPolicy />,
    isPrivate: false,
  },
  {
    path: routes.ROUTE_TERMS_PAGE,
    page: <TermsOfUse />,
    isPrivate: false,
  },
  {
    path: routes.ROUTE_LANDING_PAGE,
    page: <LandingPage />,
    isPrivate: false,
  },
  {
    path: routes.ROUTE_LEARNING_PAGE,
    page: <LearningPage />,
    isPrivate: false,
  },
  {
    path: routes.ROUTE_SINGLE_LEARNING,
    page: <SingleLearningPage />,
    isPrivate: false,
  },
  {
    path: routes.ROUTE_STORE,
    page: <StoresPage />,
    isPrivate: false,
  },
  {
    path: routes.ROUTE_HELP_PAGE,
    page: <HelpPage />,
    isPrivate: false,
  },
  {
    path: routes.ROUTE_SINGLE_HELP,
    page: <SingleHelpPage />,
    isPrivate: false,
  },
  {
    path: routes.ROUTE_SINGLE_HELP_GETSTARTED,
    page: <SingleHelpGetStarted />,
    isPrivate: false,
  },
  {
    path: routes.ROUTE_SINGLE_HELP_NOTIFICATIONS,
    page: <SingleHelpNotifications />,
    isPrivate: false,
  },
  {
    path: routes.ROUTE_SINGLE_HELP_SECURITY,
    page: <SingleHelpSecurity />,
    isPrivate: false,
  },
  {
    path: routes.ROUTE_SINGLE_HELP_MARKETS,
    page: <SingleHelpMarkets />,
    isPrivate: false,
  },
  {
    path: routes.ROUTE_SINGLE_HELP_COMMUNITY,
    page: <SingleHelpCommunity />,
    isPrivate: false,
  },
  {
    path: routes.ROUTE_SINGLE_HELP_WATCHLIST,
    page: <SingleHelpWatchlist />,
    isPrivate: false,
  },
];
