import React, { useEffect, useState } from "react";
import HelpCard from "utils/helpCard";
import { useNavigate } from "react-router-dom";
import { HelpCenterTitles } from "utils/constants";

const Community4 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  const [isThumbUpActive, setIsThumbUpActive] = useState(false);
  const [isThumbDownActive, setIsThumbDownActive] = useState(false);

  const handleThumbUpClick = () => {
    setIsThumbUpActive(!isThumbUpActive);
    setIsThumbDownActive(false);
  };

  const handleThumbDownClick = () => {
    setIsThumbDownActive(!isThumbDownActive);
    setIsThumbUpActive(false);
  };

  return (
    <div className="bg-primary h-max">
      <div className="max-screen-1440 mx-auto px-[28px] sm:px-[18px] md:px-[23px]">
        <div className="sm:block hidden text-white text-[24px] font-medium pt-[14px] flex items-center">
          <div
            className="flex items-center gap-2 mb-4"
            onClick={() => navigate("/help/community")}
          >
            <img src="/icons/left.svg" className="z-[12]" alt="left-back" />
            Community
          </div>
        </div>
        <div className="sm:hidden block text-white text-[50px] font-roboto-bold pt-[50px] flex items-center gap-8">
          <img src="/images/help/community-detail.svg" alt="community-detail" />
          Community
        </div>
        <div className="mt-10 sm:mt-4 sm:w-full flex max-w-[1416px] w-full grid grid-cols-12">
          <div className="col-span-9 md:col-span-12 sm:col-span-12">
            <div className="w-full mx-auto mb-10 sm:mb-4">
              <div className="w-full bg-transparent rounded shadow-md">
                <div className="font-bold text-[24px] text-white w-full text-left flex justify-between items-center pb-8 sm:pb-4 border-b border-b-1 border-[#464646]">
                  {HelpCenterTitles.community[3].title}
                </div>
                <div
                  className={`mt-8 pb-8 sm:mt-4 sm:pb-[56px] border-b border-b-1 border-[#464646]`}
                >
                  <div className="text-white text-[24px] sm:text-[16px]">
                    <p className="font-normal">
                      Certainly! Here's a response for the help centre of your
                      project regarding sharing investment strategies or tips
                      within the Capiwise app community:
                    </p>
                    <div className="mt-4">
                      <div className="font-bold">
                        Sharing Investment Strategies and Tips
                      </div>
                      <div className="font-normal">
                        At Capiwise, we encourage users to actively share their
                        investment strategies and tips within the community to
                        facilitate collaborative learning and discussion. Here
                        are some guidelines to ensure a positive and
                        constructive sharing environment:
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">1. Open Discussions:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Feel free to initiate discussions about your investment
                        strategies or share tips that have been valuable in your
                        experience. The community is a space for open dialogue
                        on various investment approaches.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">2. Provide Context:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        When sharing strategies or tips, provide context to help
                        others understand the rationale behind your approach.
                        Contextual information enhances the educational value of
                        your contributions.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        3. Diversity of Perspectives:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Acknowledge that there are various investment styles and
                        approaches. Embrace the diversity of perspectives within
                        the community and be open to learning from different
                        strategies employed by fellow investors.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">4. Avoid Guarantees:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        While sharing tips, avoid making absolute guarantees
                        about investment outcomes. Investments inherently
                        involve risk, and it's important to convey that any
                        strategy carries its own level of risk and uncertainty.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        5. Disclosure of Interests:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        If you have a financial interest in a particular
                        investment or strategy you're discussing, disclose it
                        transparently. Transparency builds trust and helps
                        others evaluate information in the appropriate context.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">6. Respectful Feedback:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Be open to receiving feedback and differing opinions
                        from other community members. Constructive discussions
                        contribute to a rich learning environment for everyone.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">7. Educational Focus:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Frame your contributions in an educational manner. Share
                        insights that can help users better understand
                        investment concepts and make informed decisions.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        8. Avoid Personalized Advice:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Refrain from providing personalized investment advice
                        tailored to individual circumstances. Community
                        discussions are meant to be informative and educational
                        rather than offering specific financial recommendations.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        9. Compliance with Regulations:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Ensure that your contributions comply with relevant
                        financial regulations and guidelines. Avoid discussions
                        that may violate legal or regulatory standards.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">10. Community Moderation:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Moderators may step in to ensure that discussions align
                        with community guidelines. If your contributions are
                        reported or flagged, moderators may review the content
                        for compliance.
                      </div>
                    </div>
                    <div className="mt-4 font-normal">
                      Remember, the Capiwise community is a space for
                      collaborative learning, and your willingness to share
                      insights contributes to the growth of the community as a
                      whole. Thank you for being an active and valued
                      participant in the Capiwise community!
                    </div>
                    <div className="mt-4 font-normal">
                      For additional information or questions, refer to the
                      Community Guidelines or{" "}
                      <u
                        className="text-[#0F69FE] cursor-pointer"
                        onClick={() => navigate("/contact")}
                      >
                        contact our support team
                      </u>
                      .
                    </div>
                  </div>
                  <div className="text-[#979797] text-[16px] mt-[108px] sm:mt-8 sm:text-[10px] ">
                    Was this information helpful?
                  </div>
                  <div className="flex justify-between items-center w-[120px] sm:w-[75px] mt-4">
                    <img
                      src={
                        isThumbUpActive
                          ? "/images/help/thumbup_active.svg"
                          : "/images/help/thumbup_normal.svg"
                      }
                      alt="thumbup"
                      onClick={handleThumbUpClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                    <img
                      src={
                        isThumbDownActive
                          ? "/images/help/thumbdown_active.svg"
                          : "/images/help/thumbdown_normal.svg"
                      }
                      alt="thumbdown"
                      onClick={handleThumbDownClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                  </div>
                </div>
              </div>
              <div className="text-[#0F69FE] text-[24px] mt-[80px] sm:mt-4 sm:text-[16px]">
                More Help
              </div>
              <div onClick={() => navigate(`/help/community/1`)}>
                <HelpCard title={HelpCenterTitles.community[0].title} />
              </div>
              <div onClick={() => navigate(`/help/community/2`)}>
                <HelpCard title={HelpCenterTitles.community[1].title} />
              </div>
              <div onClick={() => navigate(`/help/community/3`)}>
                <HelpCard title={HelpCenterTitles.community[2].title} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Community4;
