import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import PublicLayout from "layout/PublicLayout";
import Markets1 from "./markets1";
import Markets2 from "./markets2";
import Markets3 from "./markets3";
import Markets4 from "./markets4";

const SingleHelpMarkets = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams();
  return (
    <PublicLayout>
      {id === "1" ? (
        <Markets1 />
      ) : id === "2" ? (
        <Markets2 />
      ) : id === "3" ? (
        <Markets3 />
      ) : id === "4" ? (
        <Markets4 />
      ) : (
        <></>
      )}
    </PublicLayout>
  );
};

export default SingleHelpMarkets;
