import React, { useEffect, useState } from "react";
import HelpCard from "utils/helpCard";
import { useNavigate } from "react-router-dom";
import { HelpCenterTitles } from "utils/constants";

const Watchlist3 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  const [isThumbUpActive, setIsThumbUpActive] = useState(false);
  const [isThumbDownActive, setIsThumbDownActive] = useState(false);

  const handleThumbUpClick = () => {
    setIsThumbUpActive(!isThumbUpActive);
    setIsThumbDownActive(false);
  };

  const handleThumbDownClick = () => {
    setIsThumbDownActive(!isThumbDownActive);
    setIsThumbUpActive(false);
  };

  return (
    <div className="bg-primary h-max">
      <div className="max-screen-1440 mx-auto px-[28px] sm:px-[18px] md:px-[23px]">
        <div className="sm:block hidden text-white text-[24px] font-medium pt-[14px] flex items-center">
          <div
            className="flex items-center gap-2 mb-4"
            onClick={() => navigate("/help/watchlist")}
          >
            <img src="/icons/left.svg" className="z-[12]" alt="left-back" />
            Watchlist
          </div>
        </div>
        <div className="sm:hidden block text-white text-[50px] font-roboto-bold pt-[50px] flex items-center gap-8">
          <img src="/images/help/watchlist-detail.svg" alt="watchlist-detail" />
          Watchlist
        </div>
        <div className="mt-10 sm:mt-4 sm:w-full flex max-w-[1416px] w-full grid grid-cols-12">
          <div className="col-span-9 md:col-span-12 sm:col-span-12">
            <div className="w-full mx-auto mb-10 sm:mb-4">
              <div className="w-full bg-transparent rounded shadow-md">
                <div className="font-bold text-[24px] text-white w-full text-left flex justify-between items-center pb-8 sm:pb-4 border-b border-b-1 border-[#464646]">
                  {HelpCenterTitles.watchlist[2].title}
                </div>
                <div
                  className={`mt-8 pb-8 sm:mt-4 sm:pb-[56px] border-b border-b-1 border-[#464646]`}
                >
                  <div className="text-white text-[24px] sm:text-[16px]">
                    <p className="font-normal">
                      Certainly! Here's a response for the help center of your
                      project regarding where users can find their watchlist
                      within the Capiwise app:
                    </p>
                    <div className="mt-4">
                      <div className="font-bold">
                        Locating Your Watchlist in the Capiwise App
                      </div>
                      <div className="font-normal">
                        Your personalized watchlist is a central tool for
                        tracking and monitoring the stocks and ETFs that matter
                        to you. Here's how you can find your watchlist within
                        the Capiwise app:
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        1. Navigate to the Homepage:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Start by accessing the app's homepage or main dashboard.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        2. Profile or Account Section:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Look for a "Profile" or "Account" section within the
                        app. This is typically represented by your user avatar
                        or profile picture.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        3. Watchlist Tab or Section:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Within the Profile or Account section, find a dedicated
                        "Watchlist" tab or section. This is where you can access
                        and manage the list of stocks and ETFs you've added for
                        tracking.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">4. Watchlist Icon:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Some apps use an icon, often resembling an eye or
                        binoculars, to represent the watchlist. Clicking or
                        tapping on this icon may directly lead you to your
                        watchlist.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">5. Customization Options:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Explore customization options within the watchlist
                        section. Depending on the app's design, you may be able
                        to organize your watchlist, set alerts, or create
                        folders to categorize different assets.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        6. Swipe or Scroll Navigation:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Swipe or scroll through the app's interface to find a
                        specific section labeled "Watchlist." In some apps, this
                        section may be directly accessible through a swipe or
                        scroll gesture.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">7. Search Functionality:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Utilize the app's search functionality. Enter keywords
                        like "Watchlist" in the search bar to quickly locate the
                        section dedicated to your personalized watchlist.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        8. Menu or Navigation Bar:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Check the app's menu or navigation bar for a direct link
                        to your watchlist. This bar is often located at the
                        bottom or top of the app's interface.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        9. Widget or Dashboard Shortcut:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Some apps offer widgets or dashboard shortcuts for quick
                        access to your watchlist. Check if your watchlist can be
                        added as a widget on your device's home screen or within
                        the app's dashboard.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        10. User Guide or Help Center:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        For more detailed instructions, refer to the user guide
                        or help centre within the app. These resources often
                        provide step-by-step guidance on navigating and using
                        features like the watchlist.
                      </div>
                    </div>
                    <div className="mt-4 font-normal">
                      By following these steps, you should be able to easily
                      locate and access your watchlist within the Capiwise app.
                      If you have specific questions or need further assistance,
                      feel free to consult the user guide or reach out to our{" "}
                      <u
                        className="text-[#0F69FE] cursor-pointer"
                        onClick={() => navigate("/contact")}
                      >
                        support team
                      </u>
                      .
                    </div>
                  </div>
                  <div className="text-[#979797] text-[16px] mt-[108px] sm:mt-8 sm:text-[10px] ">
                    Was this information helpful?
                  </div>
                  <div className="flex justify-between items-center w-[120px] sm:w-[75px] mt-4">
                    <img
                      src={
                        isThumbUpActive
                          ? "/images/help/thumbup_active.svg"
                          : "/images/help/thumbup_normal.svg"
                      }
                      alt="thumbup"
                      onClick={handleThumbUpClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                    <img
                      src={
                        isThumbDownActive
                          ? "/images/help/thumbdown_active.svg"
                          : "/images/help/thumbdown_normal.svg"
                      }
                      alt="thumbdown"
                      onClick={handleThumbDownClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                  </div>
                </div>
              </div>
              <div className="text-[#0F69FE] text-[24px] mt-[80px] sm:mt-4 sm:text-[16px]">
                More Help
              </div>
              <div onClick={() => navigate(`/help/watchlist/1`)}>
                <HelpCard title={HelpCenterTitles.watchlist[0].title} />
              </div>
              <div onClick={() => navigate(`/help/watchlist/2`)}>
                <HelpCard title={HelpCenterTitles.watchlist[1].title} />
              </div>
              <div onClick={() => navigate(`/help/watchlist/4`)}>
                <HelpCard title={HelpCenterTitles.watchlist[3].title} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Watchlist3;
