import React, { useEffect } from "react";

const Learning3 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="w-[85%] mx-auto bg-white px-[48px] sm:px-[18px] mt-[-260px] sm:mt-[-190px] sm:w-[100%] rounded-[10px] text-[#050505]">
      <div className="pt-[64px] font-bold text-[34px] leading-[33px] text-center sm:text-[17px] sm:leading-[23px]">
        Demystifying Investments: A Beginner's Dive
        <br className="block sm:hidden" /> into the World of Financial
        Opportunities
      </div>
      <div className="text-[24px] font-normal text-center my-[48px] sm:my-[24px] sm:text-[12px] sm:leading-[23px] sm:text-start ">
        Embarking on the journey of investing is like navigating a vast sea of
        opportunities, and for those with non-financial backgrounds, the journey
        can seem both exhilarating and daunting. In this article, we'll take a
        closer look at the various types of investments, unravelling the
        complexities to empower you to make informed choices and build a
        diversified portfolio.
      </div>
      <div className="flex justify-center mb-[30px] ">
        <img src="/images/learnings/learning-3.png" alt="" className="w-full" />
      </div>
      <div>
        <div className="w-full text-start font-bold text-[24px] leading-[33px] mb-[30px] sm:mb-[20px] sm:text-[17px] sm:leading-[23px]">
          1. A Peek into the World of Investments
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px]">
          1.1 The Essence of Investments:
          <p className="indent-4">
            Investing is essentially putting your money to work with the
            expectation of generating income or witnessing appreciation over
            time. It's a way to let your money grow beyond the constraints of a
            savings account.
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px] my-6 sm:my-3">
          1.2 Unlocking the Power of Diversification:
          <p className="indent-4">
            Diversifying your investments across different asset classes is like
            planting a garden with a variety of fruits and vegetables. It helps
            manage risk and optimize returns by not putting all your eggs in one
            basket.
          </p>
        </div>
      </div>
      <div className="mt-[48px] sm:mt-[24px]">
        <div className="w-full text-start font-bold text-[24px] leading-[33px] mb-[30px] sm:mb-[20px] sm:text-[17px] sm:leading-[23px]">
          2. Unveiling the Tapestry of Investment Options
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px]">
          2.1 Equities (Stocks):
          <p className="indent-4">
            Imagine owning a piece of your favourite company! Stocks represent
            ownership in a company, offering the potential for high returns but
            accompanied by higher volatility.
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px] my-6 sm:my-3">
          2.2 Fixed-Income Securities (Bonds):
          <p className="indent-4">
            Bonds are like lending money to governments or corporations in
            exchange for regular interest payments. Lower risk compared to
            stocks, they provide a more predictable income stream.
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px]">
          2.3 Mutual Funds:
          <p className="indent-4">
            Picture a professional chef mixing a variety of ingredients to
            create a delicious dish. Mutual funds pool money from multiple
            investors to invest in a diverse range of stocks, bonds, or other
            assets.
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px]">
          2.4 Exchange-Traded Funds (ETFs):
          <p className="indent-4">
            ETFs bring a modern twist to investing, combining the
            diversification of mutual funds with the ease of trading individual
            stocks. They often come with lower fees and can be traded on stock
            exchanges.
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px] my-6 sm:my-3">
          2.5 Real Estate Investment Trusts (REITs):
          <p className="indent-4">
            Ever wanted to invest in real estate without managing properties?
            REITs make it possible, offering a way to invest in
            income-generating real estate projects.
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px]">
          2.6 Commodities:
          <p className="indent-4">
            From gold and silver to oil and agricultural products, commodities
            add a tangible element to your portfolio. They can act as a hedge
            against inflation but come with their own set of risks.
          </p>
        </div>
      </div>
      <div className="mt-[48px] sm:mt-[24px]">
        <div className="w-full text-start font-bold text-[24px] leading-[33px] mb-[30px] sm:mb-[20px] sm:text-[17px] sm:leading-[23px]">
          3. Crafting Your Investment Strategy
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px]">
          3.1 Understanding Risk Tolerance and Investment Goals:
          <p className="indent-4">
            Before setting sail, understand your risk tolerance, investment time
            horizon, and financial goals. It's like choosing the right vessel
            for your journey.
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px] my-6 sm:my-3">
          3.2 Research and Due Diligence:
          <p className="indent-4">
            Think of researching investments as charting your course. Dive into
            historical performance, management details, and current economic
            conditions to make informed decisions.
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px]">
          3.3 Seek Guidance from Financial Advisors:
          <p className="indent-4">
            Navigating uncharted waters can be challenging. Consider seeking
            advice from financial professionals who can help tailor your
            strategy to fit your unique financial situation.
          </p>
        </div>
      </div>
      <div className="mt-[48px] sm:mt-[24px] pb-[96px]">
        <div className="w-full text-start font-bold text-[24px] leading-[33px] mb-[30px] sm:mb-[20px] sm:text-[17px] sm:leading-[23px]">
          Set Sail with Confidence
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px]">
          Congratulations on setting sail into the diverse world of investments!
          Remember, diversification, research, and aligning your investments
          with your goals are your compasses on this exciting journey. As you
          navigate the waters, may your financial ship sail smoothly toward
          prosperity. Happy investing!
        </div>
      </div>
    </div>
  );
};

export default Learning3;
