import React, { useEffect } from "react";

const Learning1 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="w-[85%] mx-auto bg-white px-[48px] sm:px-[18px] mt-[-260px] sm:mt-[-190px] sm:w-[100%] rounded-[10px] text-[#050505]">
      <div className="pt-[64px] font-bold text-[34px] leading-[33px] text-center sm:text-[17px] sm:leading-[23px]">
        Unveiling the World of Investments: A Beginner's
        <br className="block sm:hidden" /> Guide to Stock Market Triumph
      </div>
      <div className="text-[24px] font-normal text-center my-[48px] sm:my-[24px] sm:text-[12px] sm:leading-[23px] sm:text-start ">
        Ah, the stock market – a bustling arena of possibilities and profits. If
        you're new to the financial game, fear not! This blog is your compass,
        guiding you through the intricacies of investing. Let's embark on this
        thrilling journey together and unlock the secrets of the stock market.
      </div>
      <div className="flex justify-center mb-[30px] ">
        <img src="/images/learnings/learning-1.png" alt="" className="w-full" />
      </div>
      <div>
        <div className="w-full text-start font-bold text-[24px] leading-[33px] mb-[30px] sm:mb-[20px] sm:text-[17px] sm:leading-[23px]">
          1. Decoding the Investment Landscape
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px]">
          1.1 The Basics of Investing:
          <p className="indent-4">
            Welcome to the world where money works for you! Investing is
            essentially putting your money into assets with the expectation of
            generating a return.
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px] my-6 sm:my-3">
          1.2 Stocks, Bonds, and Beyond:
          <p className="indent-4">
            Explore the diverse range of investment options, from stocks – a
            share in a company's ownership – to bonds – a loan to a company or
            government.
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px] ">
          1.3 Risk and Reward:
          <p className="indent-4">
            Understand the fundamental principle – the greater the risk, the
            higher the potential reward. Balancing risk and reward is the key to
            successful investing.
          </p>
        </div>
      </div>
      <div className="mt-[48px] sm:mt-[24px]">
        <div className="w-full text-start font-bold text-[24px] leading-[33px] mb-[30px] sm:mb-[20px] sm:text-[17px] sm:leading-[23px]">
          2. Setting Your Financial Goals
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px] ">
          2.1 Defining Your Objectives:
          <p className="indent-4">
            What are you investing in? Whether it's a dream vacation, buying a
            home, or retiring comfortably, establish clear and achievable
            financial goals.
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px] my-6 sm:my-3">
          2.2 Investment Time Horizon:
          <p className="indent-4">
            Consider the time you have until you need the money. A longer time
            horizon allows for more aggressive investment strategies.
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px] ">
          2.3 Assessing Risk Tolerance:
          <p className="indent-4">
            Gauge your comfort level with market fluctuations. Are you a
            risk-taker or a conservative investor? Your risk tolerance shapes
            your investment approach.
          </p>
        </div>
      </div>
      <div className="mt-[48px] sm:mt-[24px]">
        <div className="w-full text-start font-bold text-[24px] leading-[33px] mb-[30px] sm:mb-[20px] sm:text-[17px] sm:leading-[23px]">
          3. Diving into Stock Market Mechanics
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px] ">
          3.1 How the Stock Market Works:
          <p className="indent-4">
            Grasp the concept of a stock exchange – a marketplace where buyers
            and sellers trade stocks. It's the heartbeat of the financial world.
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px] my-6 sm:my-3">
          3.2 Reading Stock Quotes:
          <p className="indent-4">
            Decode the numbers! Learn to interpret stock quotes, understand bid
            prices, ask prices, and the day's trading range.
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px] ">
          3.3 The Role of Brokers:
          <p className="indent-4">
            Meet your investing ally – a broker. They act as intermediaries,
            facilitating your stock market transactions.
          </p>
        </div>
      </div>
      <div className="mt-[48px] sm:mt-[24px]">
        <div className="w-full text-start font-bold text-[24px] leading-[33px] mb-[30px] sm:mb-[20px] sm:text-[17px] sm:leading-[23px]">
          4. Building Your Investment Portfolio
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px] ">
          4.1 Diversification:
          <p className="indent-4">
            Spread your wings and your investments! Diversify your portfolio to
            minimize risk by investing in a variety of assets.
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px] my-6 sm:my-3">
          4.2 Asset Allocation:
          <p className="indent-4">
            Strike the right balance between stocks, bonds, and other
            investments based on your goals, time horizon, and risk tolerance.
          </p>
        </div>
      </div>
      <div className="mt-[48px] sm:mt-[24px]">
        <div className="w-full text-start font-bold text-[24px] leading-[33px] mb-[30px] sm:mb-[20px] sm:text-[17px] sm:leading-[23px]">
          5. Staying Informed
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px] ">
          5.1 Financial News and Analysis:
          <p className="indent-4">
            Tune in to financial news outlets and read analyses to stay updated
            on market trends, economic indicators, and company performances.
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px] my-6 sm:my-3">
          5.2 Continuous Learning:
          <p className="indent-4">
            The world of investing is ever-evolving. Keep learning, exploring
            new investment strategies, and adapting to changes in the market.
          </p>
        </div>
      </div>
      <div className="mt-[48px] sm:mt-[24px] pb-[96px] text-[24px] font-normal leading-[33px] text-start sm:text-[12px] sm:leading-[23px] sm:text-start">
        Congratulations! You've just completed your crash course in investing.
        Armed with this knowledge, you're ready to navigate the stock market
        with confidence. Remember, patience and continuous learning are your
        allies on this journey. Happy investing!
      </div>
    </div>
  );
};

export default Learning1;
