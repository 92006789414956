import React, { useEffect, useState } from "react";
import HelpCard from "utils/helpCard";
import { useNavigate } from "react-router-dom";
import { HelpCenterTitles } from "utils/constants";

const GetStarted1 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  const [isThumbUpActive, setIsThumbUpActive] = useState(false);
  const [isThumbDownActive, setIsThumbDownActive] = useState(false);

  const handleThumbUpClick = () => {
    setIsThumbUpActive(!isThumbUpActive);
    setIsThumbDownActive(false);
  };

  const handleThumbDownClick = () => {
    setIsThumbDownActive(!isThumbDownActive);
    setIsThumbUpActive(false);
  };

  return (
    <div className="bg-primary h-max">
      <div className="max-screen-1440 mx-auto px-[28px] sm:px-[18px] md:px-[23px]">
        <div className="sm:block hidden text-white text-[24px] font-medium pt-[14px] flex items-center">
          <div
            className="flex items-center gap-2 mb-4"
            onClick={() => navigate("/help/getstarted")}
          >
            <img src="/icons/left.svg" className="z-[12]" alt="left-back" />
            Get started
          </div>
        </div>
        <div className="sm:hidden block text-white text-[50px] font-roboto-bold pt-[50px] flex items-center gap-8">
          <img
            src="/images/help/getstarted-detail.svg"
            alt="get_started-detail"
          />
          Get started
        </div>
        <div className="mt-10 sm:mt-4 sm:w-full flex max-w-[1416px] w-full grid grid-cols-12">
          <div className="col-span-9 md:col-span-12 sm:col-span-12">
            <div className="w-full mx-auto mb-10 sm:mb-4">
              <div className="w-full bg-transparent rounded shadow-md">
                <div className="font-bold text-[24px] text-white w-full text-left flex justify-between items-center pb-8 sm:pb-4 border-b border-b-1 border-[#464646]">
                  {HelpCenterTitles.getstarted[0].title}
                </div>
                <div
                  className={`mt-8 pb-8 sm:mt-4 sm:pb-[56px] border-b border-b-1 border-[#464646]`}
                >
                  <div className="text-white text-[24px] sm:text-[16px]">
                    <p className="font-normal">
                      To build a personalized watchlist, follow these initial
                      steps:
                    </p>
                    <div className="mt-4">
                      <div className="font-bold">1. Sign Up or Log In:</div>
                      <div className="font-normal ml-[28px] sm:ml-0">
                        If you haven't already, sign up for an account or log in
                        to your existing account on the platform.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">2. Complete Onboarding:</div>
                      <div className="font-normal ml-[28px] sm:ml-0">
                        During the onboarding process, you may be prompted to
                        select your interests and provide information to
                        customize your experience. This can include choosing
                        sectors you're interested in and selecting companies to
                        follow.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">3. Navigate to Watchlist:</div>
                      <div className="font-normal ml-[28px] sm:ml-0">
                        Once logged in, navigate to the "Watchlist" section.
                        This is often located in the main dashboard or a
                        dedicated section related to market or investment
                        activities.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">4. Search for Companies:</div>
                      <div className="font-normal ml-[28px] sm:ml-0">
                        Use the search functionality to find companies you want
                        to add to your watchlist. You can search by company
                        name, stock symbol, or other relevant criteria.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        5. Add Companies to Watchlist:
                      </div>
                      <div className="font-normal ml-[28px] sm:ml-0">
                        When you find a company you're interested in, look for
                        an option to add it to your watchlist. This is typically
                        represented by a button or icon next to the company's
                        information.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        6. Organize and Customize:
                      </div>
                      <div className="font-normal ml-[28px] sm:ml-0">
                        After adding companies, you may have options to organize
                        and customize your watchlist. This could include
                        rearranging the order of companies, categorizing them,
                        or adding notes.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">7. Set Alerts (Optional):</div>
                      <div className="font-normal ml-[28px] sm:ml-0">
                        Depending on the platform, you might have the option to
                        set alerts for specific companies on your watchlist.
                        This could include price alerts, news alerts, or other
                        customizable notifications.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">8. Review and Update:</div>
                      <div className="font-normal ml-[28px] sm:ml-0">
                        Periodically review your watchlist to stay informed
                        about the companies you're following. Update your
                        watchlist based on changes in your interests or market
                        conditions.
                      </div>
                    </div>
                  </div>
                  <div className="text-[#979797] text-[16px] mt-[108px] sm:mt-8 sm:text-[10px] ">
                    Was this information helpful?
                  </div>
                  <div className="flex justify-between items-center w-[120px] sm:w-[75px] mt-4">
                    <img
                      src={
                        isThumbUpActive
                          ? "/images/help/thumbup_active.svg"
                          : "/images/help/thumbup_normal.svg"
                      }
                      alt="thumbup"
                      onClick={handleThumbUpClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                    <img
                      src={
                        isThumbDownActive
                          ? "/images/help/thumbdown_active.svg"
                          : "/images/help/thumbdown_normal.svg"
                      }
                      alt="thumbdown"
                      onClick={handleThumbDownClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                  </div>
                </div>
              </div>
              <div className="text-[#0F69FE] text-[24px] mt-[80px] sm:mt-4 sm:text-[16px]">
                More Help
              </div>
              <div onClick={() => navigate(`/help/getstarted/2`)}>
                <HelpCard title={HelpCenterTitles.getstarted[1].title} />
              </div>
              <div onClick={() => navigate(`/help/getstarted/3`)}>
                <HelpCard title={HelpCenterTitles.getstarted[2].title} />
              </div>
              <div onClick={() => navigate(`/help/getstarted/4`)}>
                <HelpCard title={HelpCenterTitles.getstarted[3].title} />
              </div>
              <div onClick={() => navigate(`/help/getstarted/5`)}>
                <HelpCard title={HelpCenterTitles.getstarted[4].title} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetStarted1;
