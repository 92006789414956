import React, { useEffect, useState } from "react";
import HelpCard from "utils/helpCard";
import { useNavigate } from "react-router-dom";
import { HelpCenterTitles } from "utils/constants";

const Security1 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  const [isThumbUpActive, setIsThumbUpActive] = useState(false);
  const [isThumbDownActive, setIsThumbDownActive] = useState(false);

  const handleThumbUpClick = () => {
    setIsThumbUpActive(!isThumbUpActive);
    setIsThumbDownActive(false);
  };

  const handleThumbDownClick = () => {
    setIsThumbDownActive(!isThumbDownActive);
    setIsThumbUpActive(false);
  };

  return (
    <div className="bg-primary h-max">
      <div className="max-screen-1440 mx-auto px-[28px] sm:px-[18px] md:px-[23px]">
        <div className="sm:block hidden text-white text-[24px] font-medium pt-[14px] flex items-center">
          <div
            className="flex items-center gap-2 mb-4"
            onClick={() => navigate("/help/security")}
          >
            <img src="/icons/left.svg" className="z-[12]" alt="left-back" />
            Security
          </div>
        </div>
        <div className="sm:hidden block text-white text-[50px] font-roboto-bold pt-[50px] flex items-center gap-8">
          <img src="/images/help/security-detail.svg" alt="security-detail" />
          Security
        </div>
        <div className="mt-10 sm:mt-4 sm:w-full flex max-w-[1416px] w-full grid grid-cols-12">
          <div className="col-span-9 md:col-span-12 sm:col-span-12">
            <div className="w-full mx-auto mb-10 sm:mb-4">
              <div className="w-full bg-transparent rounded shadow-md">
                <div className="font-bold text-[24px] text-white w-full text-left flex justify-between items-center pb-8 sm:pb-4 border-b border-b-1 border-[#464646]">
                  {HelpCenterTitles.security[0].title}
                </div>
                <div
                  className={`mt-8 pb-8 sm:mt-4 sm:pb-[56px] border-b border-b-1 border-[#464646]`}
                >
                  <div className="text-white text-[24px] sm:text-[16px]">
                    <p className="font-normal">
                      The protection of personal information is a critical
                      aspect of any app, especially in the finance and
                      investment sector. Here are general measures that
                      investment apps often employ to safeguard users' personal
                      information:
                    </p>
                    <div className="mt-4">
                      <div className="font-bold">1. Encryption:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        The app uses encryption protocols to secure the
                        transmission of data between your device and the app's
                        servers. This ensures that any sensitive information,
                        such as login credentials or personal details, is
                        transmitted securely.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        2. Secure Socket Layer (SSL) Technology:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        SSL technology is commonly used to establish a secure
                        and encrypted connection between your device and the
                        app's servers. This is especially crucial when handling
                        sensitive financial data.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        3. Two-Factor Authentication (2FA):
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        The app may offer 2FA as an additional layer of
                        security. This involves using a secondary method (e.g.,
                        a code sent to your mobile device) to verify your
                        identity during the login process.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">4. Privacy Policies:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        The app has clear and transparent privacy policies that
                        outline how your personal information is collected,
                        used, and protected. Reviewing these policies can
                        provide insights into the app's commitment to user
                        privacy.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        5. Data Encryption at Rest:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        In addition to encrypting data during transmission, the
                        app may employ encryption methods to protect stored data
                        on their servers. This ensures that even if unauthorized
                        access occurs, the data remains unreadable.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        6. Regular Security Audits:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        The app undergoes regular security audits and
                        assessments to identify and address potential
                        vulnerabilities. This helps ensure that the app's
                        security measures are up to date.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        7. User Authentication Practices:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        The app enforces strong user authentication practices to
                        prevent unauthorized access. This includes secure
                        password policies and mechanisms to detect and prevent
                        suspicious login attempts.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        8. Limited Access to Personal Information:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Only authorized personnel within the app's organization
                        have access to users' personal information. Access
                        controls are implemented to restrict and monitor access
                        to sensitive data.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        9. Secure Payment Processing:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        If the app involves financial transactions, it uses
                        secure payment processing methods to protect your
                        financial data during transactions.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        10. Regular Software Updates:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        The app regularly updates its software to patch security
                        vulnerabilities and improve overall security. Users are
                        often encouraged to keep their apps updated to benefit
                        from the latest security features.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        11. Customer Support Security Practices:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        The app's customer support team adheres to secure
                        practices to verify user identities before providing
                        assistance, ensuring that personal information is not
                        disclosed to unauthorized individuals.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        12. Legal and Compliance Standards:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        The app complies with legal and regulatory standards
                        related to data protection and user privacy. This may
                        include adherence to data protection laws and
                        regulations applicable in the regions where the app
                        operates.
                      </div>
                    </div>
                    <div className="mt-4 font-normal">
                      Remember that the specifics of these measures can vary
                      between apps, and it's essential to review the app's
                      documentation,{" "}
                      <u
                        className="text-[#0F69FE] cursor-pointer"
                        onClick={() => navigate("/privacypolicy")}
                      >
                        privacy policy
                      </u>
                      , and{" "}
                      <u
                        className="text-[#0F69FE] cursor-pointer"
                        onClick={() => navigate("/terms")}
                      >
                        terms of service
                      </u>{" "}
                      for detailed information about how your personal
                      information is protected.
                    </div>
                  </div>
                  <div className="text-[#979797] text-[16px] mt-[108px] sm:mt-8 sm:text-[10px] ">
                    Was this information helpful?
                  </div>
                  <div className="flex justify-between items-center w-[120px] sm:w-[75px] mt-4">
                    <img
                      src={
                        isThumbUpActive
                          ? "/images/help/thumbup_active.svg"
                          : "/images/help/thumbup_normal.svg"
                      }
                      alt="thumbup"
                      onClick={handleThumbUpClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                    <img
                      src={
                        isThumbDownActive
                          ? "/images/help/thumbdown_active.svg"
                          : "/images/help/thumbdown_normal.svg"
                      }
                      alt="thumbdown"
                      onClick={handleThumbDownClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                  </div>
                </div>
              </div>
              <div className="text-[#0F69FE] text-[24px] mt-[80px] sm:mt-4 sm:text-[16px]">
                More Help
              </div>
              <div onClick={() => navigate(`/help/security/2`)}>
                <HelpCard title={HelpCenterTitles.security[1].title} />
              </div>
              <div onClick={() => navigate(`/help/security/3`)}>
                <HelpCard title={HelpCenterTitles.security[2].title} />
              </div>
              <div onClick={() => navigate(`/help/security/4`)}>
                <HelpCard title={HelpCenterTitles.security[3].title} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Security1;
