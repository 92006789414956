import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HelpCard from "utils/helpCard";
import { HelpCenterTitles } from "utils/constants";

const Notifications = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  return (
    <div className="bg-primary h-max">
      <div className="max-screen-1440 mx-auto px-[28px] sm:px-[18px] md:px-[23px]">
        <div className="sm:block hidden text-white text-[24px] font-medium pt-[14px] flex items-center">
          <div
            className="flex items-center gap-2 mb-4"
            onClick={() => navigate("/help")}
          >
            <img src="/icons/left.svg" className="z-[12]" alt="left-back" />
            Notifications
          </div>
          <img
            src="/images/help/notifications-detail.svg"
            alt="notifications-detail"
            className="w-[40px]"
          />
        </div>
        <div className="sm:hidden block text-white text-[50px] font-roboto-bold pt-[50px] flex items-center gap-8">
          <img
            src="/images/help/notifications-detail.svg"
            alt="notifications-detail"
          />
          Notifications
        </div>
        <div className="mt-10 sm:mt-0 sm:w-full flex max-w-[1416px] w-[92%] grid grid-cols-12 gap-8">
          <div className="col-span-8 sm:col-span-12">
            <div className="text-[24px] font-semibold sm:text-[16px] text-left text-white mt-8">
              Stay Ahead with Timely Alerts and Updates
            </div>
            <div className="text-[16px] sm:text-[10px] text-left text-white mt-8 sm:mt-4">
              In the fast-paced world of investments, timely information is key.
              Our notification system keeps you informed with personalized
              updates tailored to your preferences. Stay on top of market
              changes, news updates, and community interactions with ease.
            </div>
            <div className="my-[64px] sm:my-6">
              <div className="text-[#0F69FE] text-[24px] font-medium sm:text-[16px] leading-tight">
                Related questions
              </div>
              <div className="w-full mx-auto mt-6 sm:pr-[12px]">
                {HelpCenterTitles.notifications.map((item, index) => (
                  <div onClick={() => navigate(`${index + 1}`)} key={index}>
                    <HelpCard title={item.title} />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-span-4 sm:hidden">
            <img src="/images/help/notifications.png" alt="notifications" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
