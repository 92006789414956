import React, { useEffect } from "react";

const Learning6 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="w-[85%] mx-auto bg-white px-[48px] sm:px-[18px] mt-[-260px] sm:mt-[-190px] sm:w-[100%] rounded-[10px] text-[#050505]">
      <div className="pt-[64px] font-bold text-[34px] leading-[33px] text-center sm:text-[17px] sm:leading-[23px]">
        Unveiling the Mysteries of Stocks: A Beginner's Odyssey
        <br className="block sm:hidden" /> into the World of Investments
      </div>
      <div className="text-[24px] font-normal text-center my-[48px] sm:my-[24px] sm:text-[12px] sm:leading-[23px] sm:text-start ">
        Greetings, future investors! If the stock market has always felt like a
        distant galaxy, you're not alone. This article is your launchpad,
        introducing you to the captivating universe of stocks and demystifying
        the complexities for those stepping into this financial frontier. Join
        me on this thrilling journey, and let's unravel the wonders of stocks
        together.
      </div>
      <div className="flex justify-center mb-[30px] ">
        <img src="/images/learnings/learning-6.png" alt="" className="w-full" />
      </div>
      <div>
        <div className="w-full text-start font-bold text-[24px] leading-[33px] mb-[30px] sm:mb-[20px] sm:text-[17px] sm:leading-[23px]">
          1. Decoding the Language of Stocks
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px]">
          1.1 What Are Stocks?
          <p className="indent-4">
            At its core, a stock represents ownership in a company. Think of it
            as a share, a tiny piece of the company's pie that you own.
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px] my-6 sm:my-3">
          1.2 The Stock Market:
          <p className="indent-4">
            Picture a bustling marketplace, where buyers and sellers meet to
            trade stocks. This is the stock market – a dynamic hub of financial
            activity.
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px]">
          1.3 Public vs. Private Companies:
          <p className="indent-4">
            Understand the distinction between public and private companies.
            Public companies trade stocks on the open market, while private
            companies keep ownership within a select group.
          </p>
        </div>
      </div>
      <div className="mt-[48px] sm:mt-[24px]">
        <div className="w-full text-start font-bold text-[24px] leading-[33px] mb-[30px] sm:mb-[20px] sm:text-[17px] sm:leading-[23px]">
          2. The Basics of Stock Ownership
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px]">
          2.1 Common vs. Preferred Stocks:
          <p className="indent-4">
            Delve into the two primary types of stocks – common and preferred.
            Each comes with its own set of rights and perks for shareholders.
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px] my-6 sm:my-3">
          2.2 Dividends:
          <p className="indent-4">
            Uncover the joy of dividends – periodic payments made by some
            companies to their shareholders. It's a bonus for being a
            part-owner!
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px]">
          2.3 Voting Rights:
          <p className="indent-4">
            As a shareholder, you might get a say in the company's decisions.
            Learn how voting rights give you a voice in certain matters.
          </p>
        </div>
      </div>
      <div className="mt-[48px] sm:mt-[24px]">
        <div className="w-full text-start font-bold text-[24px] leading-[33px] mb-[30px] sm:mb-[20px] sm:text-[17px] sm:leading-[23px]">
          3. The ABCs of Buying and Selling
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px]">
          3.1 How to Buy Stocks:
          <p className="indent-4">
            Step into the shoes of an investor as we explore the various methods
            to buy stocks, whether through a broker or in today's digital age,
            via online platforms.
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px] my-6 sm:my-3">
          3.2 Understanding Stock Prices:
          <p className="indent-4">
            Decode the numbers! Learn how stock prices are determined and why
            they fluctuate over time.
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px]">
          3.3 Strategies for Selling:
          <p className="indent-4">
            Discover the art of selling stocks, whether for a profit or to cut
            losses. Timing and strategy play crucial roles in your success.
          </p>
        </div>
      </div>
      <div className="mt-[48px] sm:mt-[24px]">
        <div className="w-full text-start font-bold text-[24px] leading-[33px] mb-[30px] sm:mb-[20px] sm:text-[17px] sm:leading-[23px]">
          4. Risks and Rewards
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px]">
          4.1 Risk in Stock Investing:
          <p className="indent-4">
            Embrace the reality – stocks come with risks. Understand market
            volatility, company-specific risks, and economic factors that can
            impact your investments.
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px] my-6 sm:my-3">
          4.2 Potential Rewards:
          <p className="indent-4">
            On the flip side, stocks offer the potential for significant
            returns. Learn how a well-timed investment can lead to capital gains
            and a flourishing portfolio.
          </p>
        </div>
      </div>
      <div className="mt-[48px] sm:mt-[24px]">
        <div className="w-full text-start font-bold text-[24px] leading-[33px] mb-[30px] sm:mb-[20px] sm:text-[17px] sm:leading-[23px]">
          5. The Role of Brokers
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px]">
          5.1 Meet Your Investing Ally:
          <p className="indent-4">
            Brokers act as intermediaries, connecting buyers and sellers in the
            stock market. Explore the different types of brokers and find the
            one that suits your needs.
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px] my-6 sm:my-3">
          5.2 Online Brokerage Platforms:
          <p className="indent-4">
            Step into the digital age with online brokerage platforms. Discover
            the convenience of trading stocks from the comfort of your home.
          </p>
        </div>
      </div>
      <div className="mt-[48px] sm:mt-[24px] pb-[96px] text-[24px] font-normal leading-[33px] text-start sm:text-[12px] sm:leading-[23px] sm:text-start">
        Congratulations! You've just completed your crash course in the
        fundamentals of stocks. Armed with this knowledge, you're ready to dip
        your toes into the exciting world of stock market investing. Stay
        curious, stay informed, and let the adventure begin. Happy investing!
      </div>
    </div>
  );
};

export default Learning6;
