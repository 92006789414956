import React, { useEffect, useState } from "react";
import HelpCard from "utils/helpCard";
import { useNavigate } from "react-router-dom";
import { HelpCenterTitles } from "utils/constants";

const Security4 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  const [isThumbUpActive, setIsThumbUpActive] = useState(false);
  const [isThumbDownActive, setIsThumbDownActive] = useState(false);

  const handleThumbUpClick = () => {
    setIsThumbUpActive(!isThumbUpActive);
    setIsThumbDownActive(false);
  };

  const handleThumbDownClick = () => {
    setIsThumbDownActive(!isThumbDownActive);
    setIsThumbUpActive(false);
  };

  return (
    <div className="bg-primary h-max">
      <div className="max-screen-1440 mx-auto px-[28px] sm:px-[18px] md:px-[23px]">
        <div className="sm:block hidden text-white text-[24px] font-medium pt-[14px] flex items-center">
          <div
            className="flex items-center gap-2 mb-4"
            onClick={() => navigate("/help/security")}
          >
            <img src="/icons/left.svg" className="z-[12]" alt="left-back" />
            Security
          </div>
        </div>
        <div className="sm:hidden block text-white text-[50px] font-roboto-bold pt-[50px] flex items-center gap-8">
          <img src="/images/help/security-detail.svg" alt="security-detail" />
          Security
        </div>
        <div className="mt-10 sm:mt-4 sm:w-full flex max-w-[1416px] w-full grid grid-cols-12">
          <div className="col-span-9 md:col-span-12 sm:col-span-12">
            <div className="w-full mx-auto mb-10 sm:mb-4">
              <div className="w-full bg-transparent rounded shadow-md">
                <div className="font-bold text-[24px] text-white w-full text-left flex justify-between items-center pb-8 sm:pb-4 border-b border-b-1 border-[#464646]">
                  {HelpCenterTitles.security[3].title}
                </div>
                <div
                  className={`mt-8 pb-8 sm:mt-4 sm:pb-[56px] border-b border-b-1 border-[#464646]`}
                >
                  <div className="text-white text-[24px] sm:text-[16px]">
                    <p className="font-normal">
                      The storage of financial data locally on your device can
                      depend on the specific features and design of the app, as
                      well as user preferences. Here are common scenarios
                      regarding the storage of financial data:
                    </p>
                    <div className="mt-4">
                      <div className="font-bold">1. Local Storage:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        In some cases, the app may store certain financial data
                        locally on your device. This could include data such as
                        transaction history, watchlists, or preferences. Local
                        storage can enhance offline access and performance.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">2. Cloud-Based Storage:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Many modern finance apps opt for cloud-based storage
                        solutions. Financial data is stored securely on remote
                        servers, allowing for seamless synchronization across
                        devices and ensuring data accessibility from anywhere.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">3. End-to-End Encryption:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Apps that prioritize security often implement end-to-end
                        encryption. This means that even if data is stored
                        locally or in the cloud, it is encrypted in a way that
                        only the user and the app have the ability to decrypt
                        and access the information.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">4. User Control:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Some apps provide users with control over where their
                        financial data is stored. Users may be able to choose
                        whether certain data is stored locally on their device
                        or in the cloud, depending on their preferences.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">5. Security Measures:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Regardless of storage location, financial apps typically
                        implement stringent security measures to protect user
                        data. This includes encryption, secure connections, and
                        adherence to industry standards for data protection.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">6. Sensitive Information:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Sensitive financial information such as credit card
                        details or bank account numbers is typically treated
                        with high-security measures. This information may be
                        stored in compliance with financial regulations and
                        security best practices.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        7. Data Deletion Policies:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        The app may have policies regarding the retention and
                        deletion of user data. Check the app's privacy policy or
                        documentation to understand how long certain types of
                        data are retained and under what circumstances data is
                        deleted.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">8. Offline Mode:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Some apps provide an offline mode that allows users to
                        access certain financial data without an internet
                        connection. In such cases, relevant data may be stored
                        locally to enable offline functionality.
                      </div>
                    </div>
                    <div className="mt-4 font-normal">
                      For specific details about how the app stores financial
                      data, it's recommended to refer to the app's{" "}
                      <u
                        className="text-[#0F69FE] cursor-pointer"
                        onClick={() => navigate("/privacypolicy")}
                      >
                        privacy policy
                      </u>
                      , and{" "}
                      <u
                        className="text-[#0F69FE] cursor-pointer"
                        onClick={() => navigate("/terms")}
                      >
                        terms of service
                      </u>
                      , or{" "}
                      <u
                        className="text-[#0F69FE] cursor-pointer"
                        onClick={() => navigate("/contact")}
                      >
                        contact customer support
                      </u>
                      . These sources will provide information tailored to the
                      app's specific data storage practices and security
                      measures.
                    </div>
                  </div>
                  <div className="text-[#979797] text-[16px] mt-[108px] sm:mt-8 sm:text-[10px] ">
                    Was this information helpful?
                  </div>
                  <div className="flex justify-between items-center w-[120px] sm:w-[75px] mt-4">
                    <img
                      src={
                        isThumbUpActive
                          ? "/images/help/thumbup_active.svg"
                          : "/images/help/thumbup_normal.svg"
                      }
                      alt="thumbup"
                      onClick={handleThumbUpClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                    <img
                      src={
                        isThumbDownActive
                          ? "/images/help/thumbdown_active.svg"
                          : "/images/help/thumbdown_normal.svg"
                      }
                      alt="thumbdown"
                      onClick={handleThumbDownClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                  </div>
                </div>
              </div>
              <div className="text-[#0F69FE] text-[24px] mt-[80px] sm:mt-4 sm:text-[16px]">
                More Help
              </div>
              <div onClick={() => navigate(`/help/security/1`)}>
                <HelpCard title={HelpCenterTitles.security[0].title} />
              </div>
              <div onClick={() => navigate(`/help/security/2`)}>
                <HelpCard title={HelpCenterTitles.security[1].title} />
              </div>
              <div onClick={() => navigate(`/help/security/3`)}>
                <HelpCard title={HelpCenterTitles.security[2].title} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Security4;
