import PublicLayout from "layout/PublicLayout";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Section1 from "components/Landing-Page/Section1";
import StoreModal from "components/Modal/StoreModal";

function StoresPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  return (
    <>
      <StoreModal
        onClick={() => {
          navigate("/");
        }}
        open={true}
        onClose={() => navigate("/")}
      />
      <div>
        <PublicLayout>
          <Section1 />
        </PublicLayout>
      </div>
    </>
  );
}

export default StoresPage;
