import React, { useEffect, useState } from "react";
import HelpCard from "utils/helpCard";
import { useNavigate } from "react-router-dom";
import { HelpCenterTitles } from "utils/constants";

const Watchlist4 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  const [isThumbUpActive, setIsThumbUpActive] = useState(false);
  const [isThumbDownActive, setIsThumbDownActive] = useState(false);

  const handleThumbUpClick = () => {
    setIsThumbUpActive(!isThumbUpActive);
    setIsThumbDownActive(false);
  };

  const handleThumbDownClick = () => {
    setIsThumbDownActive(!isThumbDownActive);
    setIsThumbUpActive(false);
  };

  return (
    <div className="bg-primary h-max">
      <div className="max-screen-1440 mx-auto px-[28px] sm:px-[18px] md:px-[23px]">
        <div className="sm:block hidden text-white text-[24px] font-medium pt-[14px] flex items-center">
          <div
            className="flex items-center gap-2 mb-4"
            onClick={() => navigate("/help/watchlist")}
          >
            <img src="/icons/left.svg" className="z-[12]" alt="left-back" />
            Watchlist
          </div>
        </div>
        <div className="sm:hidden block text-white text-[50px] font-roboto-bold pt-[50px] flex items-center gap-8">
          <img src="/images/help/watchlist-detail.svg" alt="watchlist-detail" />
          Watchlist
        </div>
        <div className="mt-10 sm:mt-4 sm:w-full flex max-w-[1416px] w-full grid grid-cols-12">
          <div className="col-span-9 md:col-span-12 sm:col-span-12">
            <div className="w-full mx-auto mb-10 sm:mb-4">
              <div className="w-full bg-transparent rounded shadow-md">
                <div className="font-bold text-[24px] text-white w-full text-left flex justify-between items-center pb-8 sm:pb-4 border-b border-b-1 border-[#464646]">
                  {HelpCenterTitles.watchlist[3].title}
                </div>
                <div
                  className={`mt-8 pb-8 sm:mt-4 sm:pb-[56px] border-b border-b-1 border-[#464646]`}
                >
                  <div className="text-white text-[24px] sm:text-[16px]">
                    <p className="font-normal">
                      Certainly! Here's a response for the help center of your
                      project regarding how users can interpret the data table
                      in their watchlist within the Capiwise app:
                    </p>
                    <div className="mt-4">
                      <div className="font-bold">
                        Interpreting the Data Table in Your Watchlist
                      </div>
                      <div className="font-normal">
                        The data table in your watchlist is a powerful tool for
                        gaining insights into the performance of the stocks and
                        ETFs you are tracking. Here's a guide on how to
                        interpret the key information presented in the data
                        table within the Capiwise app:
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">1. Stock/ETF Symbol:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        The stock or ETF symbol represents the unique identifier
                        for the asset. It's usually a combination of letters
                        that serves as a shorthand reference.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">2. Name:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        The name column displays the full name of the stock or
                        ETF, providing additional context beyond the symbol.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">3. Current Price:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        This column shows the current market price of the asset.
                        It reflects the most recent trading price.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">4. Change:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        The change column displays the net change in the stock
                        or ETF's price since the last trading session. It
                        includes both the absolute change (in currency) and the
                        percentage change.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">5. Open Price:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        The open price represents the opening value of the stock
                        or ETF at the beginning of the current trading session.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">6. High and Low Prices:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        These columns display the highest and lowest prices
                        reached by the asset during the current trading session.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">7. Volume:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Volume indicates the total number of shares or units
                        traded for the stock or ETF during the current trading
                        session.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">8. Market Cap:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Market capitalization represents the total market value
                        of a company's outstanding shares of stock. It's
                        calculated by multiplying the current stock price by the
                        total number of outstanding shares.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">9. Dividend Yield:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        For dividend-paying stocks or ETFs, the dividend yield
                        column shows the annual dividend income as a percentage
                        of the current market price.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">10. 52-Week High and Low:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        These columns display the highest and lowest prices the
                        asset has reached over the past 52 weeks, providing a
                        broader perspective on its performance.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">11. Beta:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Beta measures the asset's volatility in relation to the
                        overall market. A beta of 1 indicates the asset moves in
                        line with the market, while a beta above 1 suggests
                        higher volatility.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">12. Earnings Date:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        The earnings date column shows the anticipated date when
                        the company is scheduled to release its earnings report.
                      </div>
                    </div>
                    <div className="mt-4 font-normal">
                      Understanding and regularly reviewing this data table can
                      help you make informed decisions about your investments.
                      If you have specific questions about any of the columns or
                      need additional assistance, refer to the app's user guide
                      or reach out to our{" "}
                      <u
                        className="text-[#0F69FE] cursor-pointer"
                        onClick={() => navigate("/contact")}
                      >
                        support team
                      </u>
                      .
                    </div>
                  </div>
                  <div className="text-[#979797] text-[16px] mt-[108px] sm:mt-8 sm:text-[10px] ">
                    Was this information helpful?
                  </div>
                  <div className="flex justify-between items-center w-[120px] sm:w-[75px] mt-4">
                    <img
                      src={
                        isThumbUpActive
                          ? "/images/help/thumbup_active.svg"
                          : "/images/help/thumbup_normal.svg"
                      }
                      alt="thumbup"
                      onClick={handleThumbUpClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                    <img
                      src={
                        isThumbDownActive
                          ? "/images/help/thumbdown_active.svg"
                          : "/images/help/thumbdown_normal.svg"
                      }
                      alt="thumbdown"
                      onClick={handleThumbDownClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                  </div>
                </div>
              </div>
              <div className="text-[#0F69FE] text-[24px] mt-[80px] sm:mt-4 sm:text-[16px]">
                More Help
              </div>
              <div onClick={() => navigate(`/help/watchlist/1`)}>
                <HelpCard title={HelpCenterTitles.watchlist[0].title} />
              </div>
              <div onClick={() => navigate(`/help/watchlist/2`)}>
                <HelpCard title={HelpCenterTitles.watchlist[1].title} />
              </div>
              <div onClick={() => navigate(`/help/watchlist/3`)}>
                <HelpCard title={HelpCenterTitles.watchlist[2].title} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Watchlist4;
