import React, { useEffect, useState } from "react";
import HelpCard from "utils/helpCard";
import { useNavigate } from "react-router-dom";
import { HelpCenterTitles } from "utils/constants";

const GetStarted3 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  const [isThumbUpActive, setIsThumbUpActive] = useState(false);
  const [isThumbDownActive, setIsThumbDownActive] = useState(false);

  const handleThumbUpClick = () => {
    setIsThumbUpActive(!isThumbUpActive);
    setIsThumbDownActive(false);
  };

  const handleThumbDownClick = () => {
    setIsThumbDownActive(!isThumbDownActive);
    setIsThumbUpActive(false);
  };

  return (
    <div className="bg-primary h-max">
      <div className="max-screen-1440 mx-auto px-[28px] sm:px-[18px] md:px-[23px]">
        <div className="sm:block hidden text-white text-[24px] font-medium pt-[14px] flex items-center">
          <div
            className="flex items-center gap-2 mb-4"
            onClick={() => navigate("/help/getstarted")}
          >
            <img src="/icons/left.svg" className="z-[12]" alt="left-back" />
            Get started
          </div>
        </div>
        <div className="sm:hidden block text-white text-[50px] font-roboto-bold pt-[50px] flex items-center gap-8">
          <img
            src="/images/help/getstarted-detail.svg"
            alt="get_started-detail"
          />
          Get started
        </div>
        <div className="mt-10 sm:mt-4 sm:w-full flex max-w-[1416px] w-full grid grid-cols-12">
          <div className="col-span-9 md:col-span-12 sm:col-span-12">
            <div className="w-full mx-auto mb-10 sm:mb-4">
              <div className="w-full bg-transparent rounded shadow-md">
                <div className="font-bold text-[24px] text-white w-full text-left flex justify-between items-center pb-8 sm:pb-4 border-b border-b-1 border-[#464646]">
                  {HelpCenterTitles.getstarted[2].title}
                </div>
                <div
                  className={`mt-8 pb-8 sm:mt-4 sm:pb-[56px] border-b border-b-1 border-[#464646]`}
                >
                  <div className="text-white text-[24px] sm:text-[16px]">
                    <p className="font-normal">
                      To start interacting with the app's investment community,
                      follow these steps:
                    </p>
                    <div className="mt-4">
                      <div className="font-bold">1. Sign Up and Log In:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Ensure you have signed up for an account on the app and
                        are logged in.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        2. Explore Community Sections:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Navigate to the community sections within the app. Look
                        for tabs or sections related to discussions, forums, or
                        community interactions.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">3. Create a Profile:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Complete your user profile. Add a profile picture and
                        provide any additional information that you are
                        comfortable sharing. A complete profile helps other
                        community members get to know you.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">4. Browse Discussions:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Start by browsing existing discussions. Look for topics
                        that interest you or align with your investment goals.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">5. Like and Comment:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Engage with the community by liking posts and leaving
                        comments on topics that catch your attention. This is a
                        great way to express your thoughts and connect with
                        other users.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">6. Start a Discussion:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        If you have a specific question, idea, or topic you'd
                        like to discuss, consider starting your own thread or
                        discussion. This invites others to join the
                        conversation.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">7. Follow Other Users:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Follow other users whose insights or posts you find
                        interesting. This helps you stay updated on their
                        contributions to the community.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">8. Use Hashtags or Tags:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        If the app supports it, use relevant hashtags or tags to
                        categorize your posts. This makes it easier for others
                        to find and join discussions on specific topics.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        9. Participate in Polls or Surveys:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        If there are polls or surveys within the community,
                        participate in them. This not only adds your voice to
                        community decisions but also provides insights into your
                        preferences.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        10. Attend Virtual Events (If Available):
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Check if the app hosts virtual events, webinars, or live
                        discussions. Participating in these events allows you to
                        interact with community members in real-time.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        11. Respect Community Guidelines:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Familiarize yourself with the community guidelines or
                        rules. Respectful and constructive interactions
                        contribute to a positive community experience.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">12. Share Your Knowledge:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        If you have expertise in a certain area, share your
                        knowledge with the community. Answer questions, provide
                        insights, and contribute to the collective learning
                        experience.
                      </div>
                    </div>
                    <div className="mt-4 font-normal">
                      Remember that community interactions should be respectful,
                      and it's beneficial to approach discussions with an open
                      mind. Building a positive presence in the investment
                      community enhances your overall experience and allows you
                      to learn from others.
                    </div>
                  </div>
                  <div className="text-[#979797] text-[16px] mt-[108px] sm:mt-8 sm:text-[10px] ">
                    Was this information helpful?
                  </div>
                  <div className="flex justify-between items-center w-[120px] sm:w-[75px] mt-4">
                    <img
                      src={
                        isThumbUpActive
                          ? "/images/help/thumbup_active.svg"
                          : "/images/help/thumbup_normal.svg"
                      }
                      alt="thumbup"
                      onClick={handleThumbUpClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                    <img
                      src={
                        isThumbDownActive
                          ? "/images/help/thumbdown_active.svg"
                          : "/images/help/thumbdown_normal.svg"
                      }
                      alt="thumbdown"
                      onClick={handleThumbDownClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                  </div>
                </div>
              </div>
              <div className="text-[#0F69FE] text-[24px] mt-[80px] sm:mt-4 sm:text-[16px]">
                More Help
              </div>
              <div onClick={() => navigate(`/help/getstarted/1`)}>
                <HelpCard title={HelpCenterTitles.getstarted[0].title} />
              </div>
              <div onClick={() => navigate(`/help/getstarted/2`)}>
                <HelpCard title={HelpCenterTitles.getstarted[1].title} />
              </div>
              <div onClick={() => navigate(`/help/getstarted/4`)}>
                <HelpCard title={HelpCenterTitles.getstarted[3].title} />
              </div>
              <div onClick={() => navigate(`/help/getstarted/5`)}>
                <HelpCard title={HelpCenterTitles.getstarted[4].title} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetStarted3;
