import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HelpCard from "utils/helpCard";
import { HelpCenterTitles } from "utils/constants";

const GetStarted = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  return (
    <div className="bg-primary h-max">
      <div className="max-screen-1440 mx-auto px-[28px] sm:px-[18px] md:px-[23px]">
        <div className="sm:block hidden text-white text-[24px] font-medium pt-[14px] flex items-center">
          <div
            className="flex items-center gap-2 mb-4"
            onClick={() => navigate("/help")}
          >
            <img src="/icons/left.svg" className="z-[12]" alt="left-back" />
            Get started
          </div>
          <img
            src="/images/help/getstarted-detail.svg"
            alt="get_started-detail"
            className="w-[40px]"
          />
        </div>
        <div className="sm:hidden block text-white text-[50px] font-roboto-bold pt-[50px] flex items-center gap-8">
          <img
            src="/images/help/getstarted-detail.svg"
            alt="get_started-detail"
          />
          Get started
        </div>
        <div className="mt-10 sm:mt-0 sm:w-full flex max-w-[1416px] w-[92%] grid grid-cols-12 gap-8">
          <div className="col-span-8 sm:col-span-12">
            <div className="text-[24px] font-semibold sm:text-[16px] text-left text-white mt-8">
              Welcome to Capiwise - Your <br className="hidden sm:block" />{" "}
              Gateway to Informed Investments
            </div>
            <div className="text-[16px] sm:text-[10px] text-left text-white mt-8 sm:mt-4">
              Embarking on your investment journey has never been more
              accessible and insightful. At Capiwise, we're dedicated to
              empowering investors of all levels, providing a seamless and
              comprehensive platform for staying ahead in the dynamic world of
              stock investments.
            </div>
            <div className="text-[16px] sm:text-[10px] text-left text-white mt-8 sm:mt-4">
              Dive into a world of financial opportunities where you can access
              real-time market insights, track significant news, curate a
              personalized watchlist, and engage within a vibrant community of
              like-minded investors. Whether you're a seasoned investor or just
              beginning, our app serves as your gateway to a wealth of
              information and a thriving community eager to share insights and
              strategies.
            </div>
            <div className="my-[64px] sm:my-6">
              <div className="text-[#0F69FE] text-[24px] font-medium sm:text-[16px] leading-tight">
                Related questions
              </div>
              <div className="w-full mx-auto mt-6 sm:pr-[12px]">
                {HelpCenterTitles.getstarted.map((item, index) => (
                  <div onClick={() => navigate(`${index + 1}`)} key={index}>
                    <HelpCard title={item.title} />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-span-4 sm:hidden">
            <img src="/images/help/getstarted.png" alt="get_started" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetStarted;
