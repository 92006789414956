import { useEffect } from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
// import mapboxgl from "!mapbox-gl";

function Map() {
  // mapboxgl.accessToken =
  //   "pk.eyJ1IjoiY2FwaXdpc2UiLCJhIjoiY2xtbnI5NmhnMTI3eTJzczd3bmEwanZvMCJ9.Fmh166JZRz-w6uPfJ9gY_Q";

  // useEffect(() => {
  //   const map = new mapboxgl.Map(
  //     {
  //       container: "map",
  //       style: "mapbox://styles/mapbox/dark-v11",
  //       center: [13.2937151, 52.6224732],
  //       zoom: 14,
  //       interactive: false,
  //     },
  //     null,
  //     { zoomControl: false }
  //   );
  //   const el = document.createElement("div");
  //   const width = 30;
  //   const height = 30;
  //   el.className = "marker";
  //   el.style.backgroundImage = `url(/map.png)`;
  //   el.style.width = `${width}px`;
  //   el.style.height = `${height}px`;
  //   el.style.backgroundSize = "100%";
  //   el.cooperativeGestures = true;
  //   map.scrollZoom.disable();

  //   new mapboxgl.Marker(el).setLngLat([13.2937151, 52.6224732]).addTo(map);
  // }, []);

  return (
    // <div className="max-w-[1440px] relative mb-0 mx-auto bg-[url('../public/images/contact/map.png')]">
    <div className="max-w-[1440px] relative mb-0 mx-auto">
      {/* <div className="map-container" id="map" /> */}
      <div className="">
        <picture>
          <source
            media="(max-width:1024px)"
            srcSet="images/contact/map-mbl.png"
          />
          <img
            src="images/contact/map.png"
            className="w-[1440px] lg:w-[100%]"
            alt="map"
          />
        </picture>
      </div>
      <div className="absolute top-0">
        <div className=" pl-[28px] sm:pl-[19px] block ">
          <div className="text-white text-[48px] md:text-[36px] sm:pt-[29px] sm:text-17 font-roboto-bold w-[523px] sm:w-[177px] mb-[32px] sm:mb-[17px] pt-[96px] md:pt-[64px] leading-normal">
            United States Office
          </div>
          <div className="text-white text-[24px] md:text-17 font-reg mb-[12px] sm:text-12 w-[380px] sm:w-[132px]">
            Situated in the bustling tech and startup hub.
          </div>
          <div className="text-white text-[24px] md:text-17 font-reg mb-[52px] md:mb-[40px] sm:mb-[26px] sm:text-12 sm:w-[132px]">
            Miami downtown, Florida
          </div>
          <div className="text-white text-[48px] md:text-[36px] sm:text-17 font-roboto-bold w-[523px] sm:w-[177px] mb-[32px] sm:mb-[17px] leading-normal">
            Germany Office
          </div>
          <div className="text-white text-[24px] md:text-17 font-reg mb-[12px] sm:text-12 w-[380px] sm:w-[132px]">
            In the heart of the tech and startup capital.
          </div>
          <div className="text-white text-[24px] md:text-17 font-reg mb-[108px] sm:mb-[26px] sm:text-12 sm:w-[132px]">
            Tegel Berlin, Germany
          </div>
        </div>
      </div>
    </div>
  );
}

export default Map;
