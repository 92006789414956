import React, { useEffect } from "react";

const Learning5 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="w-[85%] mx-auto bg-white px-[48px] sm:px-[18px] mt-[-260px] sm:mt-[-190px] sm:w-[100%] rounded-[10px] text-[#050505]">
      <div className="pt-[64px] font-bold text-[34px] leading-[33px] text-center sm:text-[17px] sm:leading-[23px]">
        Building Wealth Brick by Brick: A Beginner's Guide to
        <br className="block sm:hidden" /> Crafting a Diversified Investment
        Portfolio
      </div>
      <div className="text-[24px] font-normal text-center my-[48px] sm:my-[24px] sm:text-[12px] sm:leading-[23px] sm:text-start ">
        Welcome, fellow financial explorers! If the idea of diving into the
        stock market feels like stepping into uncharted waters, fear not. This
        article is your compass to navigating the exciting world of investments,
        starting with a critical skill – creating a diversified portfolio. Let's
        embark on this journey together, unlocking the secrets to building a
        robust and resilient investment strategy.
      </div>
      <div className="flex justify-center mb-[30px] ">
        <img src="/images/learnings/learning-5.png" alt="" className="w-full" />
      </div>
      <div>
        <div className="w-full text-start font-bold text-[24px] leading-[33px] mb-[30px] sm:mb-[20px] sm:text-[17px] sm:leading-[23px]">
          1. The Power of Diversification
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px]">
          1.1 Understanding Diversification:
          <p className="indent-4">
            Diversification is like having a well-balanced meal – it's about
            spreading your investments across various assets to reduce risk.
            Learn how this strategy acts as a shield against the unpredictable
            nature of the market.
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px] my-6 sm:my-3">
          1.2 The Risks of Putting All Eggs in One Basket:
          <p className="indent-4">
            Discover the pitfalls of investing in a single asset or asset class.
            A diversified portfolio mitigates the impact of poor-performing
            investments and helps safeguard your wealth.
          </p>
        </div>
      </div>
      <div className="mt-[48px] sm:mt-[24px]">
        <div className="w-full text-start font-bold text-[24px] leading-[33px] mb-[30px] sm:mb-[20px] sm:text-[17px] sm:leading-[23px]">
          2. Building the Foundations
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px]">
          2.1 Asset Classes 101:
          <p className="indent-4">
            Dive into the basics of asset classes – stocks, bonds, and cash
            equivalents. Each plays a unique role in your portfolio, offering a
            blend of risk and return.
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px] my-6 sm:my-3">
          2.2 Risk and Return Profile:
          <p className="indent-4">
            Understand the risk and return characteristics of different asset
            classes. Balancing high-risk, high-return stocks with more stable
            bonds creates a well-rounded portfolio.
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px]">
          2.3 Aligning with Financial Goals:
          <p className="indent-4">
            Tailor your asset allocation to align with your financial goals.
            Short-term goals may benefit from more conservative investments,
            while long-term goals can withstand a higher risk profile.
          </p>
        </div>
      </div>
      <div className="mt-[48px] sm:mt-[24px]">
        <div className="w-full text-start font-bold text-[24px] leading-[33px] mb-[30px] sm:mb-[20px] sm:text-[17px] sm:leading-[23px]">
          3. The Art of Asset Allocation
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px]">
          3.1 Customizing Your Mix:
          <p className="indent-4">
            Explore different asset allocation strategies based on your risk
            tolerance and investment horizon. Whether it's aggressive, moderate,
            or conservative, find the mix that suits your financial temperament.
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px] my-6 sm:my-3">
          3.2 Stocks, Bonds, and More:
          <p className="indent-4">
            Discover the intricacies of allocating funds to individual assets
            within each class. From large-cap stocks to government bonds, find
            the right balance for your portfolio.
          </p>
        </div>
      </div>
      <div className="mt-[48px] sm:mt-[24px]">
        <div className="w-full text-start font-bold text-[24px] leading-[33px] mb-[30px] sm:mb-[20px] sm:text-[17px] sm:leading-[23px]">
          4. Global Perspectives and Industry Sectors
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px]">
          4.1 Embracing Global Markets:
          <p className="indent-4">
            Consider the benefits of international diversification. Investing
            beyond domestic markets can provide exposure to different economies
            and reduce reliance on a single market's performance.
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px] my-6 sm:my-3">
          4.2 Sector Specifics:
          <p className="indent-4">
            Explore industry sectors and understand how diversifying across
            sectors can shield your portfolio from the impact of a downturn in
            any particular industry.
          </p>
        </div>
      </div>
      <div className="mt-[48px] sm:mt-[24px]">
        <div className="w-full text-start font-bold text-[24px] leading-[33px] mb-[30px] sm:mb-[20px] sm:text-[17px] sm:leading-[23px]">
          5. Periodic Portfolio Review
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px]">
          5.1 The Importance of Review:
          <p className="indent-4">
            Regularly assess your portfolio's performance against your financial
            goals. Periodic reviews help identify any necessary adjustments to
            maintain a balanced and diversified stance.
          </p>
        </div>
        <div className="w-full mx-auto font-normal text-[24px] leading-[33px] sm:justify-start sm:text-[12px] sm:leading-[23px] my-6 sm:my-3">
          5.2 Rebalancing Act:
          <p className="indent-4">
            Learn the art of rebalancing – bringing your portfolio back to its
            original asset allocation. This ensures your investments stay in
            line with your risk tolerance and objectives.
          </p>
        </div>
      </div>
      <div className="mt-[48px] sm:mt-[24px] pb-[96px] text-[24px] font-normal leading-[33px] text-start sm:text-[12px] sm:leading-[23px] sm:text-start">
        Congratulations! You've just acquired the key to building a diversified
        portfolio. As you embark on your investment journey, remember that
        diversity is not just the spice of life but the secret sauce to
        financial success. Stay curious, stay informed, and watch your wealth
        grow. Happy investing!
      </div>
    </div>
  );
};

export default Learning5;
