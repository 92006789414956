import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import PublicLayout from "layout/PublicLayout";
import Notifications1 from "./notifications1";
import Notifications2 from "./notifications2";
import Notifications3 from "./notifications3";
import Notifications4 from "./notifications4";

const SingleHelpNotifications = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams();
  return (
    <PublicLayout>
      {id === "1" ? (
        <Notifications1 />
      ) : id === "2" ? (
        <Notifications2 />
      ) : id === "3" ? (
        <Notifications3 />
      ) : id === "4" ? (
        <Notifications4 />
      ) : (
        <></>
      )}
    </PublicLayout>
  );
};

export default SingleHelpNotifications;
