import React from "react";

function Integration() {
  const integrations = [
    {
      title: "Auto-trading",
      description: "Fully managed portfolio with high-accuracy automation.",
    },
    {
      title: "Zero Fees",
      description: "Buy and sell partial or full shares, all with zero fees.",
    },
    {
      title: "Portfolio",
      description:
        "Guided portfolio management with integrated risk management.",
    },
    {
      title: "Fintech Social Media",
      description:
        "Share your investing experience with friends and learn from others. Discuss the latest trends in the app.",
    },
  ];

  return (
    <>
      <div className="text-[48px] sm:text-17 text-white text-center sm:mt-[60px] mt-[160px] mb-[80px] sm:mb-[28px] tracking-tight font-roboto-bold">
        We integrate
      </div>
      <div className="gap-4 bg-white sm:px-[16px] sm:bg-primary flex rounded-[10px] max-w-[1103px] mx-auto sm:pb-0 pt-[91px] sm:pt-0 pb-0 sm:max-w-[375px]">
        <div className="h-[334px]">
          <picture>
            <source
              media="(max-width:495px)"
              srcSet="/images/about/timeline-mbl.png"
            />
            <img
              src="/images/about/timeline.png"
              className="h-[628px] ml-[84px] sm:w-[135px] md:ml-[56px] sm:ml-0 sm:h-[334px]"
              alt="Flowers"
            />
          </picture>
        </div>
        <div className="ml-[65px] sm:ml-[22px] sm:mt-0 mt-[0px] ">
          {integrations.map(({ title, description }, index) => (
            <div
              key={title}
              className={`${
                index !== 3 && "border-b-[1px]"
              } mb-[42px] pb-[42px] md:pb-[32px] md:mb-[32px] sm:mb-[33px] sm:pb-0 border-solid border-[#979797] sm:border-none sm:mt-0 w-[92%]`}
            >
              <div className="font-roboto-bold text-[34px] sm:text-light-green mb-[16px] sm:text-17 tracking-tighter sm:mb-[8px]">
                {title}
              </div>
              <div className="text-[#979797] sm:leading-normal sm:text-12 text-[24px] sm:text-white trackig-tight font-reg">
                {description}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Integration;
