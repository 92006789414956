import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import PublicLayout from "layout/PublicLayout";
import Security1 from "./security1";
import Security2 from "./security2";
import Security3 from "./security3";
import Security4 from "./security4";

const SingleHelpSecurity = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams();
  return (
    <PublicLayout>
      {id === "1" ? (
        <Security1 />
      ) : id === "2" ? (
        <Security2 />
      ) : id === "3" ? (
        <Security3 />
      ) : id === "4" ? (
        <Security4 />
      ) : (
        <></>
      )}
    </PublicLayout>
  );
};

export default SingleHelpSecurity;
