import LearningCard from "components/LearningCard";
// import PublicLayout from "layout/PublicLayout";
import React, { useEffect, useState } from "react";
import { learningItems } from "utils/constants";
import { useParams } from "react-router-dom";
import Learning1 from "components/Learnings/learning1";
import Learning2 from "components/Learnings/learning2";
import Learning3 from "components/Learnings/learning3";
import Learning4 from "components/Learnings/learning4";
import Learning5 from "components/Learnings/learning5";
import Learning6 from "components/Learnings/learning6";
import { Header } from "components";
import { Footer } from "components/Footer";

const SingleLearningPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [scrollProgress, setScrollProgress] = useState(0);

  const handleScroll = () => {
    const totalScroll = document.documentElement.scrollTop;
    const windowHeight =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrollPositionFromBottom = windowHeight - totalScroll;
    let scroll = totalScroll / (windowHeight - 600); // Subtract 800px from the total height for the calculation

    scroll = scroll > 1 ? 1 : scroll;

    if (scrollPositionFromBottom <= 600) {
      scroll = 1;
    }
    setScrollProgress(scroll);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const { id } = useParams();

  return (
    // <PublicLayout>
    <div className="bg-primary overflow-hidden">
      <Header />
      <div className="bg-[#0F69FE] bg-cover h-max pb-4 sticky top-[84px] sm:top-[52px] md:top-[52px] z-[12]">
        <div className="max-w-[1440px] mx-auto px-[28px]">
          <div className="flex justify-between items-center mb-[10px] pt-[28px] sm:pt-1">
            <div className="text-white text-[34px] leading-[39px] tracking-[0.28px] font-roboto-bold sm:text-[17px] sm:leading-[19px] sm:pb-[10px] sm:hidden">
              Investing for Beginners
            </div>
            {/* <div className="text-white text-[24px] leading-[28px] font-reg tracking-[0.28px] sm:hidden">
                18 May 2023
              </div> */}
          </div>
          <div className="border-b-[3px] border-[#555555] relative">
            <div
              style={{
                width: `${scrollProgress * 100}%`,
                backgroundColor: `rgba(255, 255, 255, ${scrollProgress})`,
              }}
              className="border-b-[4px] border-white absolute top-[100%] transition-colors duration-150"
            ></div>
          </div>
          <div className="flex justify-between mt-[20px]">
            <div className="leading-[56px] text-[48px] text-white tracking-[0.28px] font-roboto-bold sm:text-[24px] sm:leading-[28px] sm:tracking-[1.09px]">
              {id === "1"
                ? "Introducing to Investing"
                : id === "2"
                ? "Understanding Risk and Return"
                : id === "3"
                ? "Types of Investments"
                : id === "4"
                ? "Setting Investment Goals"
                : id === "5"
                ? "Creating a Diversified Portfolio"
                : "Introduction to Stocks"}
            </div>
            <div className="hidden w-[258px] h-[46px] rounded-[53px] bg-white cursor-pointer flex justify-evenly items-center">
              <img
                src="/images/play.svg"
                alt=""
                className="w-[27px] h-[27px]  "
              />
              <div className="font-roboto-bold text-black text-[17px] leading-[19px] tracking-[0.28px]">
                Listen article
              </div>
              <div className="font-reg text-black text-[17px] leading-[19px] tracking-[0.28px]">
                (5 min)
              </div>
            </div>
          </div>
          <div className="flex justify-between w-[20%] mt-[20px] md:w-full sm:w-full sm:hidden">
            <div className="flex sm:order-2">
              <img src="/images/clock.svg" alt="" className=" mr-[5px]" />
              <div className="text-[17px] text-white font-reg leading-[45px]">
                5 min read
              </div>
            </div>
            <div className="flex sm:order-1">
              <img src="/icons/green-logo.svg" alt="" className="mr-[5px]" />
              <div className="text-[17px] text-white font-reg leading-[45px]">
                Capiwise
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-1440 mx-auto px-[28px] sm:px-0 sm:mt-[224px] mt-[310px]">
        {id === "1" ? (
          <Learning1 />
        ) : id === "2" ? (
          <Learning2 />
        ) : id === "3" ? (
          <Learning3 />
        ) : id === "4" ? (
          <Learning4 />
        ) : id === "5" ? (
          <Learning5 />
        ) : (
          <Learning6 />
        )}
      </div>
      <div className="max-screen-1440 mt-[60px] pb-[216px] sm:pb-[72px] pl-6">
        <div className="text-white font-med text-[48px] leading-[56px] tracking-[0.28px] mb-[20px] sm:text-[32px]">
          Keep Learning
        </div>
        <div className="mt-[50px] sm:mt-[32px]">
          <LearningCard items={learningItems} id={id} />
        </div>
      </div>
      <Footer />
    </div>
    // </PublicLayout>
  );
};

export default SingleLearningPage;
