import React, { useEffect, useState } from "react";
import HelpCard from "utils/helpCard";
import { useNavigate } from "react-router-dom";
import { HelpCenterTitles } from "utils/constants";

const Markets2 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  const [isThumbUpActive, setIsThumbUpActive] = useState(false);
  const [isThumbDownActive, setIsThumbDownActive] = useState(false);

  const handleThumbUpClick = () => {
    setIsThumbUpActive(!isThumbUpActive);
    setIsThumbDownActive(false);
  };

  const handleThumbDownClick = () => {
    setIsThumbDownActive(!isThumbDownActive);
    setIsThumbUpActive(false);
  };

  return (
    <div className="bg-primary h-max">
      <div className="max-screen-1440 mx-auto px-[28px] sm:px-[18px] md:px-[23px]">
        <div className="sm:block hidden text-white text-[24px] font-medium pt-[14px] flex items-center">
          <div
            className="flex items-center gap-2 mb-4"
            onClick={() => navigate("/help/markets")}
          >
            <img src="/icons/left.svg" className="z-[12]" alt="left-back" />
            Markets
          </div>
        </div>
        <div className="sm:hidden block text-white text-[50px] font-roboto-bold pt-[50px] flex items-center gap-8">
          <img src="/images/help/markets-detail.svg" alt="markets-detail" />
          Markets
        </div>
        <div className="mt-10 sm:mt-4 sm:w-full flex max-w-[1416px] w-full grid grid-cols-12">
          <div className="col-span-9 md:col-span-12 sm:col-span-12">
            <div className="w-full mx-auto mb-10 sm:mb-4">
              <div className="w-full bg-transparent rounded shadow-md">
                <div className="font-bold text-[24px] text-white w-full text-left flex justify-between items-center pb-8 sm:pb-4 border-b border-b-1 border-[#464646]">
                  {HelpCenterTitles.markets[1].title}
                </div>
                <div
                  className={`mt-8 pb-8 sm:mt-4 sm:pb-[56px] border-b border-b-1 border-[#464646]`}
                >
                  <div className="text-white text-[24px] sm:text-[16px]">
                    <p className="font-normal">
                      The determination of the most-viewed companies by the
                      community within the Capiwise app is typically based on
                      user engagement metrics and data-driven algorithms. While
                      the specific methods can vary, here are common factors and
                      practices that contribute to identifying the most-viewed
                      companies:
                    </p>
                    <div className="mt-4">
                      <div className="font-bold">1. Page Views:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        The number of views or clicks on a company's profile or
                        information page is a fundamental metric. Companies with
                        higher page views are considered more-viewed by the
                        community.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">2. User Interactions:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Interactions such as likes, comments, and shares related
                        to a particular company contribute to its visibility.
                        Higher engagement indicates a higher level of interest
                        from the community.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">3. Watchlist Additions:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Companies frequently added to users' watchlists are
                        often considered popular or trending. The number of
                        times a company is added to watchlists contributes to
                        its ranking.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">4. Community Discussions:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        The frequency and depth of discussions in the community
                        forums or social features about a specific company can
                        be a significant factor. Companies generating more
                        community engagement are likely to be deemed
                        most-viewed.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        5. News and Media Mentions:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        The inclusion of a company in news articles, press
                        releases, or media mentions within the app contributes
                        to its visibility. This is especially true if users
                        actively click on and read articles about a particular
                        company.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">6. Market Performance:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Companies experiencing significant price movements or
                        demonstrating notable market performance may attract
                        more attention. Metrics related to market trends and
                        performance can influence the determination of the
                        most-viewed companies.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        7. User Preferences and History:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Algorithms may take into account individual user
                        preferences and viewing history to tailor
                        recommendations. If a user consistently views content
                        related to specific companies, those companies may be
                        considered most-viewed for that user.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        8. Data Analytics and Machine Learning:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Advanced data analytics and machine learning algorithms
                        are often employed to analyze user behaviour patterns.
                        These algorithms learn from user interactions over time
                        to dynamically adjust and determine which companies are
                        currently most-viewed.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">9. Real-Time Metrics:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        The determination of most-viewed companies may be based
                        on real-time metrics, reflecting the latest user
                        interactions and market dynamics.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">10. Popularity Trends:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Companies experiencing sudden spikes in interest or
                        popularity within a specific timeframe may be identified
                        as most-viewed during that period.
                      </div>
                    </div>
                    <div className="mt-4 font-normal">
                      It's important to note that the exact methodology for
                      determining the most-viewed companies may be proprietary
                      to Capiwise, and the app's documentation or help centre
                      may provide specific insights into the algorithms and
                      metrics used for this purpose. Users can refer to the
                      app's guidelines for a more detailed understanding of how
                      the most-viewed companies are determined within the
                      Capiwise community.
                    </div>
                  </div>
                  <div className="text-[#979797] text-[16px] mt-[108px] sm:mt-8 sm:text-[10px] ">
                    Was this information helpful?
                  </div>
                  <div className="flex justify-between items-center w-[120px] sm:w-[75px] mt-4">
                    <img
                      src={
                        isThumbUpActive
                          ? "/images/help/thumbup_active.svg"
                          : "/images/help/thumbup_normal.svg"
                      }
                      alt="thumbup"
                      onClick={handleThumbUpClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                    <img
                      src={
                        isThumbDownActive
                          ? "/images/help/thumbdown_active.svg"
                          : "/images/help/thumbdown_normal.svg"
                      }
                      alt="thumbdown"
                      onClick={handleThumbDownClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                  </div>
                </div>
              </div>
              <div className="text-[#0F69FE] text-[24px] mt-[80px] sm:mt-4 sm:text-[16px]">
                More Help
              </div>
              <div onClick={() => navigate(`/help/markets/1`)}>
                <HelpCard title={HelpCenterTitles.markets[0].title} />
              </div>
              <div onClick={() => navigate(`/help/markets/3`)}>
                <HelpCard title={HelpCenterTitles.markets[2].title} />
              </div>
              <div onClick={() => navigate(`/help/markets/4`)}>
                <HelpCard title={HelpCenterTitles.markets[3].title} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Markets2;
