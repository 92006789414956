import * as Yup from "yup";
import { camelCasetoNormal } from "utils/index";

export const generateSchema = (param) => {
  const labels = Object.keys(param) || [];
  const schema = {};
  labels.map((label) => {
    if (label.indexOf("email") !== -1)
      schema[label] = Yup.string()
        .email(`${camelCasetoNormal(label)} is must be valid`)
        .required(`${camelCasetoNormal(label)} is required`);
    else
      schema[label] = Yup.string().required(
        `${camelCasetoNormal(label)} is required`
      );
  });
  return Yup.object().shape(schema);
};

export const uploadValidation = Yup.object().shape({
  title: Yup.string().required("Title is required"),
});
