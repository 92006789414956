import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";

const LearningCard = ({ items, id }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  // const [items, setItems] = useState(initialItems);

  // useEffect(() => {
  //   if (id !== undefined && id >= 1 && id <= initialItems.length) {
  //     setItems([...initialItems.slice(0, id - 1), ...initialItems.slice(id)]);
  //   }
  // }, [id, initialItems]);

  const settings = {
    dots: true,
    infinite: false,
    arrows: false,
    speed: 500,
    initialSlide: 0,
    slidesToShow: 3,
    adaptiveHeight: true,
    afterChange: (indexOfCurrentSlide) => {
      setCurrentSlide(indexOfCurrentSlide);
    },
    appendDots: (dots) => (
      <>
        <ul className="flex justify-center mt-[30px] pb-[10px] gap-4">
          {dots}
        </ul>
      </>
    ),
    customPaging: (i) => (
      <div
        className={
          i === currentSlide
            ? "bg-light-green rounded-full w-[15px] h-[15px] cursor-pointer"
            : "bg-[#979797] rounded-full w-[15px] h-[15px] cursor-pointer"
        }
      ></div>
    ),
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,

          dots: false,
        },
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1.6,

          dots: false,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2.5,

          dots: false,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 2,

          dots: false,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1.6,

          dots: false,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1.6,

          dots: false,
        },
      },
      {
        breakpoint: 370,
        settings: {
          slidesToShow: 1.6,

          dots: false,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {items?.map((item, index) => (
        <div key={index}>
          <Link to={`/learning/${index + 1}`}>
            <div
              style={{
                background: `url(${item?.image})`,
                backgroundSize: "cover",
              }}
              className={` card w-[422px] sm:w-[calc(100%-16px)] md:w-[400px] md:h-[500px] sm:h-[295px] h-[578px] bg-no-repeat rounded-[10px] flex flex-col justify-center cursor-pointer`}
            >
              <div
                className={` bg-[#00000050] box w-[379px] md:w-[350px] sm:w-[191px] sm:h-[71px] sm:mt-[80%] mt-[90%] mx-auto rounded-[10px] relative hover:flex hover:flex-col hover:justify-end hover:pb-[9%] `}
              >
                <div className="w-[379px] md:w-[350px] sm:w-[191px] mx-auto">
                  <div className="text-white text-center font-roboto-bold text-[34px] py-[22px] px-[10px] leading-[39px] sm:text-[17px] sm:leading-[16px] ">
                    {item?.heading}
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      ))}
    </Slider>
  );
};

export default LearningCard;
