import React, { useState } from "react";
import ScrollJacker from "components/ScrollJacker";
import StoreModal from "components/Modal/StoreModal";

function Section2() {
  const [openStore, setOpenStore] = useState(false);

  return (
    <>
      <StoreModal
        onClick={() => {
          setOpenStore(false);
        }}
        open={openStore}
        onClose={() => setOpenStore(false)}
      />
      <section className="">
        <div className="max-screen-1440 relative top-10 sm:top-0 ">
          <div className="flex justify-center items-center">
            <div>
              <div className="text-white text-center font-roboto-bold text-[50px] md:text-[28px] sm:text-[17px] w-full leading-normal">
                <div>Breaking Language Barriers:</div>
                <div>Accessible Worldwide</div>
              </div>
              <div className="text-center mt-8 text-[24px] font-roboto font-normal md:text-sm font-reg tracking-tight text-base sm:text-12 sm:mt-[20px] sm:mx-auto mb-[32px] leading-tight">
                <div className="text-white">Our app transcends walls,</div>
                <div className="text-light-green">
                  welcoming investors everywhere.
                </div>
              </div>
              <div className="flex items-center justify-center mb-4 sm:hidden">
                <button
                  className="arrow-button justify-center w-[303px] bg-primary md:h-[41px] h-[56px] md:w-[190px] sm:h-[42px] border sm:mt-[21px] sm:w-[90%] sm:mx-auto border-white sm:border-light-green text-white font-reg flex sm:justify-center items-center sm:pl-[0] pl-[35px] text-[24px] sm:text-[17px] rounded-[39px] relative hover:bg-light-green sm:hover:border-white hover:border-light-green"
                  onClick={() => setOpenStore(true)}
                >
                  <div className="arrow absolute left-[30px] md:left-[10px]">
                    <div className="head"></div>
                  </div>
                  <span className="font-roboto-bold text-[24px] md:text-[17px]">
                    {" "}
                    Download it down
                  </span>
                </button>
              </div>
              <div className="flex justify-center w-full">
                <div className="relative max-w-[1280px] w-full mx-auto">
                  {/* <img
                    src="/images/background/background-gradient-circles.png"
                    alt="recurso-bg"
                    className="w-full h-auto"
                  /> */}
                  <picture>
                    <source
                      media="(max-width:465px)"
                      srcSet="/images/background/Recurso.png"
                    />
                    <img
                      src="/images/background/background-gradient-circles.png"
                      className="w-full h-auto"
                      alt="recurso-bg"
                    />
                  </picture>
                  <ul className="absolute top-[30px] left-0 right-0 mx-auto px-8 max-w-[1200px]">
                    <li className="bg-white bg-opacity-0 text-gray-800 px-2 flex justify-start">
                      <img
                        src="/images/chats/german-review.png"
                        alt="chat-german"
                        className="w-[50%] md:w-[60%] sm:w-[85%] h-auto"
                      />
                    </li>
                    <li className="bg-white bg-opacity-0 text-gray-800 px-2 flex justify-end mt-[-60px] sm:mt-0">
                      <img
                        src="/images/chats/china-review.png"
                        alt="chat-chinese"
                        className="w-[50%] md:w-[60%] sm:w-[85%] h-auto"
                      />
                    </li>
                    <li className="bg-white bg-opacity-0 text-gray-800 px-2 flex justify-start mt-[-60px] sm:mt-0">
                      <img
                        src="/images/chats/spain-review.png"
                        alt="chat-spanish"
                        className="w-[50%] md:w-[60%] sm:w-[85%] h-auto"
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-[-620px] mx-auto mx-6 pb-8 md:hidden sm:hidden">
            <ScrollJacker id="value" height={2580} increment={860}>
              <div className="grid grid-cols-12 flex justify-center items-center pt-[96px] sm:pt-4 sm:gap-8">
                <div className="col-span-4 sm:col-span-12 ml-4 sm:mt-[-96px]">
                  <div className="text-light-green text-base sm:mb-[8px] font-reg md:text-[12px] sm:text-[12px] mb-[15px]">
                    Valuation
                  </div>
                  <div className="font-roboto-bold text-md md:text-[21px] mb-[15px] sm:text-17 sm:mb-[24px] text-white">
                    We make impossible{" "}
                    <span className="text-light-green">easy</span>
                  </div>
                  <div className="flex gap-x-[11px] mb-[32px] sm:mb-[10px]">
                    <img
                      src="/icons/check-mark.svg"
                      className="h-[fit-content] md:w-[18px] sm:w-[11px]"
                      alt="check-mark"
                    />
                    <div className="text-base text-white font-reg md:text-[17px] sm:text-12">
                      Millions of stock and ETF valuations performed instantly.{" "}
                    </div>
                  </div>
                  <div className="flex gap-x-[11px]">
                    <img
                      src="/icons/check-mark.svg"
                      className="h-[fit-content] md:w-[18px] sm:w-[11px]"
                      alt="check-mark"
                    />
                    <div className="text-base text-white font-reg md:text-[17px] sm:text-12">
                      Fair value and fundamental analyses: Find out if it is a
                      good <br className="hidden sm:block" />
                      time to buy in a second.
                    </div>
                  </div>
                </div>
                <div className="col-span-8 sm:col-span-12 flex justify-center items-center">
                  <img
                    src="/images/valuation-mockup.png"
                    className="w-[75%] md:max-w-[70%] sm:max-w-[84%]"
                    alt="valuation1"
                  />
                </div>
              </div>
              <div className="grid grid-cols-12 flex justify-center items-center pt-[96px] sm:pt-4 sm:gap-8">
                <div className="col-span-6 sm:order-2 sm:col-span-12 w-full flex items-center justify-center">
                  <img
                    src="/images/new-mockup-alerts.png"
                    alt="valuation-scroll1"
                    className="w-[60%]"
                  />
                </div>
                <div className="col-span-6 mx-auto sm:order-1 sm:col-span-12 mr-6 sm:ml-4 sm:mt-[-96px]">
                  <div className="text-light-green text-base font-reg md:text-[12px] sm:text-[12px]">
                    Alerts
                  </div>
                  <div className="font-roboto-bold text-[32px] md:text-[21px] sm:text-17 text-white my-[15px] md:my-[12px] sm:my-[8px]">
                    Stay ahead with{" "}
                    <span className="text-light-green">custom alerts</span>
                  </div>
                  <div className="text-base text-white font-reg md:text-[17px] sm:text-12">
                    Set personalized alerts for stocks of interest and monitor
                    their performance. Receive notifications when they hit
                    specific value thresholds, ensuring you never miss an
                    investment opportunity
                  </div>
                  <div className="mb-4 sm:hidden">
                    <button
                      className="arrow-button justify-center w-[303px] bg-primary mt-[60px] md:mt-[42px] md:h-[41px] h-[56px] md:w-[190px] sm:h-[42px] border sm:mt-[21px] sm:w-[90%] sm:mx-auto border-white sm:border-light-green text-white font-reg flex sm:justify-center items-center sm:pl-[0] pl-[35px] text-[24px] sm:text-[17px] rounded-[39px] relative hover:bg-light-green sm:hover:border-white hover:border-light-green"
                      onClick={() => setOpenStore(true)}
                    >
                      <div className="arrow absolute left-[30px] md:left-[10px]">
                        <div className="head"></div>
                      </div>
                      <span className="font-roboto-bold text-[24px] md:text-[17px]">
                        {" "}
                        Download it down
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </ScrollJacker>
          </div>
          <div className="hidden md:block sm:block md:mt-[-200px] sm:mt-[128px] mx-auto mx-6 pb-8 md:mb-8 sm:mb-0">
            <div className="grid grid-cols-12 flex justify-center items-center pt-[96px] sm:pt-4 sm:gap-8">
              <div className="col-span-4 sm:col-span-12 ml-4 sm:mt-[-96px]">
                <div className="text-light-green text-base sm:mb-[8px] font-reg md:text-[12px] sm:text-[12px] mb-[15px]">
                  Valuation
                </div>
                <div className="font-roboto-bold text-md md:text-[21px] mb-[15px] sm:text-17 sm:mb-[24px] text-white">
                  We make impossible{" "}
                  <span className="text-light-green">easy</span>
                </div>
                <div className="flex gap-x-[11px] mb-[32px] sm:mb-[10px]">
                  <img
                    src="/icons/check-mark.svg"
                    className="h-[fit-content] md:w-[18px] sm:w-[11px]"
                    alt="check-mark"
                  />
                  <div className="text-base text-white font-reg md:text-[17px] sm:text-12">
                    Millions of stock and ETF valuations performed instantly.{" "}
                  </div>
                </div>
                <div className="flex gap-x-[11px]">
                  <img
                    src="/icons/check-mark.svg"
                    className="h-[fit-content] md:w-[18px] sm:w-[11px]"
                    alt="check-mark"
                  />
                  <div className="text-base text-white font-reg md:text-[17px] sm:text-12">
                    Fair value and fundamental analyses: Find out if it is a
                    good <br className="hidden sm:block" />
                    time to buy in a second.
                  </div>
                </div>
              </div>
              <div className="col-span-8 sm:col-span-12 flex justify-center items-center">
                <img
                  src="/images/valuation-mockup.png"
                  className="w-[75%] md:max-w-[70%] sm:max-w-[84%]"
                  alt="valuation1"
                />
              </div>
            </div>
            <div className="grid grid-cols-12 flex justify-center items-center pt-[96px] sm:pt-[120px] sm:gap-8">
              <div className="col-span-6 sm:order-2 sm:col-span-12 w-full flex items-center justify-center">
                <img
                  src="/images/new-mockup-alerts.png"
                  alt="valuation-scroll1"
                  className="w-[60%]"
                />
              </div>
              <div className="col-span-6 mx-auto sm:order-1 sm:col-span-12 mr-6 sm:ml-4 sm:mt-[-96px]">
                <div className="text-light-green text-base font-reg md:text-[12px] sm:text-[12px]">
                  Alerts
                </div>
                <div className="font-roboto-bold text-[32px] md:text-[21px] sm:text-17 text-white my-[15px] md:my-[12px] sm:my-[8px]">
                  Stay ahead with{" "}
                  <span className="text-light-green">custom alerts</span>
                </div>
                <div className="text-base text-white font-reg md:text-[17px] sm:text-12">
                  Set personalized alerts for stocks of interest and monitor
                  their performance. Receive notifications when they hit
                  specific value thresholds, ensuring you never miss an
                  investment opportunity
                </div>
                <div className="mb-4 sm:hidden">
                  <button
                    className="arrow-button justify-center w-[303px] bg-primary mt-[60px] md:mt-[42px] md:h-[41px] h-[56px] md:w-[190px] sm:h-[42px] border sm:mt-[21px] sm:w-[90%] sm:mx-auto border-white sm:border-light-green text-white font-reg flex sm:justify-center items-center sm:pl-[0] pl-[35px] text-[24px] sm:text-[17px] rounded-[39px] relative hover:bg-light-green sm:hover:border-white hover:border-light-green"
                    onClick={() => setOpenStore(true)}
                  >
                    <div className="arrow absolute left-[30px] md:left-[10px]">
                      <div className="head"></div>
                    </div>
                    <span className="font-roboto-bold text-[24px] md:text-[17px]">
                      {" "}
                      Download it down
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Section2;
