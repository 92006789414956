import React from 'react'

const Star = ({fill,width,height}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 29 27" fill={`${fill}`} xmlns="http://www.w3.org/2000/svg">
<path d="M14.5 1.61804L17.2799 10.1738L17.3922 10.5193H17.7555L26.7515 10.5193L19.4735 15.807L19.1796 16.0205L19.2919 16.366L22.0718 24.9217L14.7939 19.634L14.5 19.4205L14.2061 19.634L6.92817 24.9217L9.7081 16.366L9.82035 16.0205L9.52646 15.807L2.24852 10.5193L11.2445 10.5193H11.6078L11.7201 10.1738L14.5 1.61804Z" stroke="#FFA412"/>
</svg>

  )
}

export default Star