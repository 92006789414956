import React, { useState } from "react";
import ThanksModal from "components/Modal/ThanksModal";
import InputField from "./InputField";
import axios from "axios";
import { useFormik } from "formik";
import { generateSchema } from "validation";
import { Link } from "react-router-dom";

function Form() {
  const [open, setOpen] = useState(false);
  const initialValues = { firstName: "", lastName: "", email: "", message: "" };

  const onSubmit = async (values) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL,
        {
          subject: `Contact Request from ${values.email}`,
          body: ` <html>
                <body>
                  <h2>Following are the details:</h2>
                  <p>Name: ${values.firstName + values.lastName}</p>
                  <p>Email: ${values.email}</p>
                  <p>Message: ${values.message}</p>
                </body>
              </html>`,
          is_html_body: true,
        },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "Content-Type": "application/json",
          },
        }
      );

      if (response) {
        setOpen(true);
        handleReset();
      }
    } catch (error) {
      console.error("There was an error sendng the email:", error);
    }
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleReset,
    // handleBlur,
  } = useFormik({
    initialValues,
    onSubmit,
    validationSchema: generateSchema(initialValues),
    enableReinitialize: true,
  });

  return (
    <>
      <ThanksModal open={open} onClose={() => setOpen(false)} />
      <div className="bg-white rounded-[10px] pt-[44px] px-[48px] sm:px-[21px] sm:pt-[21px]">
        <div className="flex justify-between sm:flex-col sm:gap-y-[16px] mb-[16px]">
          <div className="w-[100%] mr-[76px]">
            <InputField
              label="First name"
              errors={errors.firstName}
              name="firstName"
              // handleBlur={handleBlur}
              onChange={handleChange}
              value={values.firstName}
            />
          </div>
          <div className="w-[100%]">
            <InputField
              label="Last name"
              errors={errors.lastName}
              name="lastName"
              // handleBlur={handleBlur}
              onChange={handleChange}
              value={values.lastName}
            />
          </div>
        </div>
        <InputField
          label="Your email"
          errors={errors.email}
          name="email"
          // handleBlur={handleBlur}
          onChange={handleChange}
          value={values.email}
        />
        <div className="mt-[24px]">
          <div className="mb-[160px] md:mb-[102px] sm:mb-[48px] text-[24px] sm:text-17 font-reg text-black mt-[24px]">
            How can we help you?
          </div>
          <input
            name="message"
            // handleBlur={handleBlur}
            onChange={handleChange}
            value={values.message}
            className="w-[100%] indent-[22px] text-base font-reg sm:indent-[18px] border-solid border-light-green border-t-0 outline-none border-[2px] sm:text-17 sm:h-[28px]"
          />
          {errors?.message && (
            <div className="text-red-500 text-12 mt-[2px]">
              {errors.message}
            </div>
          )}
        </div>
        <div className="flex gap-x-[12px] mt-[72px] md:mt-[56px] sm:mt-[40px]">
          <label className="container">
            <div className="text-black mt-[5px] sm:mt-0 sm:leading-normal text-12 sm:text-[9px] tracking-tight font-reg">
              I’d like to receive more information about Capiwise. I understand
              and agree to the{" "}
              <Link to="/privacypolicy">
                <span className="text-light-green">Privacy Policy.</span>
              </Link>
            </div>
            <input type="checkbox" />
            <span className="checkmark"></span>
          </label>
          {/* <input type="checkbox" /> */}
        </div>
        <button
          onClick={handleSubmit}
          className="bg-light-green w-[292px] sm:w-[100%] h-[54px] sm:h-[42px] text-base sm:text-17 font-reg block ml-auto my-[48px] md:my-[28px] sm:my-[18px] text-white rounded-[100px]"
        >
          Send message
        </button>
      </div>
    </>
  );
}

export default Form;
