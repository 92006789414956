import React from 'react'

const StaticCard = ({item}) => {
  return (
   <>
   <div className='w-[30%] h-[437px] bg-white rounded-[13px] sm:w-[47%] sm:h-[170px] md:w-[47%] hover:bounce cursor-pointer animated'>
      <img src={item?.path} alt="" className='m-auto pt-[20%] h-[271px] w-[200px] sm:w-[90px] sm:h-[90px] sm:pt-[15%] ' />
      <div className='text-center text-[32px] text-black font-roboto-bold leading-[37px] tracking-[0.4px] mt-[30px] w-[230px] m-auto sm:text-[17px] sm:leading-[normal] sm:w-[119px] sm:mt-[10px]'>{item?.title}</div>
    </div>
   </>
  )
}

export default StaticCard