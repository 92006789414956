import React, { useState } from "react";
import StoreModal from "components/Modal/StoreModal";
import Carousel from "components/SlickSlider/Carousel";

function Section3() {
  // const navigate = useNavigate();
  const url = "https://play.google.com/store/apps/details?id=com.capiwise"
  const [openStore, setOpenStore] = useState(false);
  return (
    <>
      {/* <StoreModal
        onClick={() => {
          setOpenStore(false);
        }}
        open={openStore}
        onClose={() => setOpenStore(false)}
      /> */}
      <section className="">
        <div className="max-screen-1440 mx-auto h-[1024px] md:h-[760px] sm:h-[680px] relative bg-white rounded-[30px] sm:rounded-[10px] z-[1] overflow-hidden">
          <img
            src="/images/background/Recurso12.png"
            alt="Left"
            className="absolute bottom-16 left-0 w-[calc(30%-24px)] object-cover"
          />
          <img
            src="/images/background/Recurso11.png"
            alt="Right"
            className="absolute top-0 right-0 w-[calc(30%-24px)] object-cover"
          />
          <div className="absolute top-0 left-1/2 transform -translate-x-1/2 max-screen-1440 w-full h-full flex flex-col items-center justify-center">
            <div className="text-[#040B11] text-[50px] md:text-[28px] sm:text-[17px] font-bold w-full sm:w-[60%] text-center leading-normal">
              Simplicity Meets Functionality: <br className="sm:hidden" />{" "}
              Easy-to-Use
            </div>
            <div className="text-[#040B11] text-[24px] md:text-[17px] sm:text-[12px] font-normal w-[36%] sm:w-[60%] text-center leading-normal">
              Navigate effortlessly through market insights, news updates and
              community opinions
            </div>
            <div className="mt-8 md:hidden lg:hidden">
              <Carousel />
            </div>
            <div className="flex items-center justify-center mx-auto mt-[56px] md:mt-[28px] sm:hidden">
              <img
                src="/images/phones-flow-mockup.png"
                className="pt-0 px-[60px] md:px-4 w-[86%]"
                alt="func2-1"
              />
            </div>
            <div className="flex items-center justify-center mb-4">
              <button
                className="arrow-button justify-center w-[303px] bg-white mt-[60px] md:mt-[42px] md:h-[41px] h-[56px] md:w-[190px] sm:h-[42px] border sm:mt-[21px] sm:mx-auto border-black text-[#040B11] font-reg flex sm:justify-center items-center sm:pl-0 pl-[35px] text-[24px] sm:text-[17px] rounded-[39px] relative hover:text-white hover:bg-light-green sm:hover:border-white hover:border-light-green"
                onClick={() => setOpenStore(true)}
              >
                <div className="arrowblack absolute left-[30px] md:left-[10px]">
                  <div className="head"></div>
                </div>
                <span className="font-roboto-bold text-[24px] md:text-[17px] sm:text-[17px]">
                  {" "}
                  Download it down
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="bg-no-repeat mt-[-40px] relative bg-cover sm:bg-cover bg-[url('../public/images/background/section3-bg1.png')] sm:bg-[url('../public/images/background/section3-bg1-mbl.png')] pl-[51px] sm:pl-[0px] h-[903px] md:h-[558px] sm:h-[490px] sm:w-[100%] sm:bottom-[-8px] max-w-[1440px] mx-auto">
          <div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-full flex flex-col items-center justify-center grid grid-cols-5">
            <div className="h-[903px] md:h-[558px] sm:h-[262px] col-span-2 sm:col-span-5 bg-black bg-opacity-60 text-white text-light-green flex justify-start items-center">
              <div className="mx-8">
                <div className="text-light-green text-[16px] md:text-[12px] sm:text-[12px] font-normal sm:text-center">
                  Community
                </div>
                <div className="text-white text-[32px] md:text-[28px] sm:text-[17px] font-roboto-bold sm:text-center my-[15px] leading-tight">
                  Connect, share, learn, join <br className="hidden lg:block" />
                  a global community
                </div>
                <div className="text-white text-[24px] md:text-[17px] sm:text-[12px] font-normal sm:text-center leading-tight">
                  <span className="text-light-green font-bold">
                    Engage, follow, and connect{" "}
                  </span>
                  with passionate individuals worldwide about investments and
                  topics that drive you. Join discussions, share insights, and
                  learn from diverse perspectives.
                </div>
                <div className="flex items-center justify-start mb-4 md:hidden sm:hidden">
                  <button
                    className="arrow-button justify-center w-[303px] bg-transparent mt-[60px] md:mt-[42px] md:h-[41px] h-[56px] md:w-[190px] sm:h-[42px] border sm:mt-[21px] sm:w-[90%] sm:mx-auto border-white sm:border-light-green text-[#040B11] font-reg flex sm:justify-center items-center sm:pl-[0] pl-[35px] text-[24px] sm:text-[17px] rounded-[39px] relative text-white hover:bg-light-green sm:hover:border-white hover:border-light-green"
                    onClick={() => setOpenStore(true)}
                  >
                    <div className="arrow absolute left-[30px] md:left-[10px]">
                      <div className="head"></div>
                    </div>
                    <span className="font-roboto-bold text-[24px] md:text-[17px]">
                      {" "}
                      Download it down
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-span-3 sm:col-span-5"></div>
          </div>
        </div>
        <div className="flex justify-center items-center mt-[64px] mx-auto max-screen-1440 h-[1286px] md:h-max sm:h-max">
          <div className=" relative ">
            <img
              src="/images/background/section3-bg2.png"
              alt="Left"
              className="sm:mt-[200px]"
            />
            <div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-full h-full flex flex-col items-center justify-center">
              <div className="text-light-green font-normal text-[16px] md:text-[12px] sm:text-[12px] text-center">
                Markets
              </div>
              <div className="text-white font-bold text-[32px] md:text-[28px] sm:text-[17px] text-center w-[40%] md:w-[48%] sm:w-[80%] mt-[28px] md:mt-4 sm:mt-2 leading-tight">
                Explore Trends, daily earnings, and create{" "}
                <span className="text-light-green ">
                  your personal watchlist
                </span>
              </div>
              <div className="text-white text-[24px] md:text-[17px] sm:text-[12px] font-normal text-center w-[45%] sm:w-[80%] my-12 md:my-4 sm:my-4 leading-tight">
                Access trending companies, daily earnings reports, and create
                your customized watchlist. Stay updated on market shifts and
                tailor your investments to your preferences.
              </div>
              <div className="flex items-center justify-center w-full md:w-[42%]">
                <img
                  src="/images/iPhone14Pro-Mockup-2.png"
                  className="mt-8 sm:mt-2 sm:w-[73%]"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center mt-[-80px] sm:mt-0 md:mt-0">
          <div className="mx-auto max-screen-1440 relative">
            <picture>
              <source
                media="(max-width:465px)"
                srcSet="/images/background/section3-bg3-mbl.png"
              />
              <img
                src="/images/background/section3-bg3-1.png"
                alt="Left"
                className="w-[100vw] 2xl:pr-[2px] h-[822px] md:h-[526px] sm:h-[422px]"
              />
            </picture>
            <div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-full h-max flex flex-col items-center justify-center grid grid-cols-12 bg-gradient-to-t-fade sm:bg-none">
              <div className="col-span-7 sm:col-span-12 sm:order-2 grid grid-cols-5">
                <div className="col-span-1 sm:hidden"></div>
                <div className="col-span-2">
                  <img
                    src="/images/background/sub3-1.png"
                    className="pt-[300px] md:pt-[160px] sm:pt-[40px] sm:mt-[100px] sm:ml-4"
                    alt="sub3-1"
                  />
                </div>
                <div className="col-span-2">
                  <img
                    src="/images/background/sub3-2.png"
                    className="mt-[-40px] sm:mt-[20px]"
                    alt="sub3-2"
                  />
                </div>
              </div>
              <div className="h-[862px] col-span-5 sm:col-span-12 sm:order-1 text-white md:h-[526px] sm:h-[190px] text-light-green flex justify-start items-center relative sm:bg-black sm:bg-opacity-30">
                <div className="mx-8 w-[70%] sm:w-full">
                  <div className="text-light-green text-[16px] md:text-[12px] sm:text-[12px] font-normal sm:text-center leading-normal">
                    News
                  </div>
                  <div className="text-white text-[32px] md:text-[28px] sm:text-[17px] font-bold my-[12px] sm:text-center leading-tight">
                    Keep up with relevant{" "}
                    <span className="text-light-green font-bold">
                      market <br className="hidden sm:block" />
                      updates
                    </span>
                  </div>
                  <div className="text-white text-[24px] md:text-[17px] sm:text-[12px] font-normal sm:text-center leading-tight">
                    Access pertinent market news to stay abreast of developments
                    and trends. Stay informed about market shifts, ensuring you
                    make well-informed investment decisions.
                  </div>
                  <div className="flex items-center justify-start mb-4 sm:hidden">
                    <button
                      className="arrow-button justify-center w-[303px] bg-transparent mt-[60px] md:mt-[42px] md:h-[41px] h-[56px] md:w-[190px] sm:h-[42px] border sm:mt-[21px] sm:w-[90%] sm:mx-auto border-white sm:border-light-green text-[#040B11] font-reg flex sm:justify-center items-center sm:pl-[0] pl-[35px] text-[24px] sm:text-[17px] rounded-[39px] relative text-white hover:bg-light-green sm:hover:border-white hover:border-light-green"
                      onClick={() => setOpenStore(true)}
                    >
                      <div className="arrow absolute left-[30px] md:left-[10px]">
                        <div className="head"></div>
                      </div>
                      <span className="font-roboto-bold text-[24px] md:text-[17px]">
                        {" "}
                        Download it down
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mx-auto max-screen-1440 h-[1120px] md:h-[720px] sm:h-[420px] relative z-[1] overflow-hidden">
          <img
            src="/images/Recurso3-3.png"
            alt="Left"
            className="absolute bottom-0 md:bottom-[40px] sm:bottom-8 left-0 w-[calc(55%-24px)] sm:w-[calc(75%-24px)] object-cover"
          />
          <img
            src="/images/Banner.png"
            alt="Right"
            className="absolute bottom-[-360px] md:hidden sm:bottom-0 right-0 w-[calc(80%-24px)] object-cover"
          />
          <div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-full h-[90%] md:h-[80%] flex flex-col items-start justify-start">
            <picture>
              <source
                media="(max-width:465px)"
                srcSet="/images/iphone-mbl.png"
              />
              <img
                src="/images/iPhoneXPSDMockup1.png"
                className=""
                alt="iPhoneXPSDMockup1"
              />
            </picture>
          </div>
          <div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-full h-[90%] md:h-[80%] flex flex-col items-center justify-center grid grid-cols-12">
            <div className="flex items-center justify-center text-white col-span-6"></div>
            <div className="flex items-center justify-center text-white col-span-6">
              <div>
                <div className="text-white text-[50px] md:text-[28px] sm:text-[17px] font-roboto-bold leading-tight">
                  Get Started <br /> with Capiwise
                </div>
                <div className="mt-4 sm:mr-6">
                  <div className="flex gap-x-[30px] items-center sm:contents ">
                    <div
                      className="border border-white rounded-[4px] sm:rounded-0 sm:border-none sm:mb-4 cursor-pointer"
                      onClick={() => window.open(url, '_blank')}
                    >
                      {/* <div> */}
                      <picture>
                        <source
                          media="(max-width:465px)"
                          srcSet="/images/gplay-mbl.png"
                        />
                        <img
                          src="/images/android.svg"
                          className="w-[232px] md:w-[120px] "
                          alt="googleplay"
                        />
                      </picture>
                    </div>
                    <div
                      className="border border-white rounded-[4px] sm:rounded-0 sm:border-none cursor-pointer"
                      onClick={() => setOpenStore(true)}
                    >
                      {/* <div> */}
                      <picture>
                        <source
                          media="(max-width:465px)"
                          srcSet="/images/appstore-mbl.png"
                        />
                        <img
                          src="/images/ios.svg"
                          className="w-[232px] md:w-[120px]"
                          alt="applestore"
                        />
                      </picture>
                    </div>
                  </div>
                  <div className="my-6 sm:my-4 font-roboto text-[16px] sm:text-[12px] text-light-green font-normal leading-tight">
                    Enjoy free access, <br className="hidden sm:block" />
                    no hidden fees
                  </div>
                  <div className="sm:hidden flex justify-center items-center max-w-[546px] h-[120px] md:w-[323px] md:h-[71px] rounded-[4px] bg-[#0B1620] gap-6 mx-auto px-6 md:px-4 py-2">
                    <img
                      src="/images/image23.png"
                      className="max-w-[90px] md:max-w-[54px]"
                      alt="qr"
                    />
                    <div className="text-[24px] md:text-[17px] font-normal leading-normal">
                      Scan this code with your phone’s camera and get{" "}
                      <span className="font-bold">Capiwise app</span>
                    </div>
                  </div>
                  <div className="flex items-center justify-start sm:justify-center gap-2 pt-6 sm:hidden">
                    <svg
                      width="12"
                      height="14"
                      viewBox="0 0 12 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_1270_8964)">
                        <path
                          d="M12 10.2563V10.3656C11.988 10.3902 11.9733 10.414 11.9641 10.4397C11.6195 11.3859 11.0746 12.2242 10.4351 13.0111C10.0384 13.4991 9.58343 13.9078 8.9034 13.9989H8.55936C8.47975 13.9871 8.39985 13.9767 8.32052 13.9636C7.98481 13.9075 7.67009 13.7876 7.35709 13.6666C6.65752 13.3968 5.96024 13.3888 5.25405 13.6439C4.8807 13.7789 4.51021 13.9258 4.11501 13.9989H3.57036C3.21712 13.908 2.91361 13.7334 2.64113 13.5046C2.45029 13.3445 2.28129 13.165 2.12206 12.9775C0.919498 11.5608 0.197791 9.95528 0.0290762 8.13217C-0.0373174 7.4137 0.000909256 6.70482 0.238892 6.01592C0.563674 5.07576 1.12328 4.3053 2.05106 3.81593C2.70465 3.47106 3.39877 3.31259 4.14634 3.44972C4.60822 3.53429 5.04567 3.69358 5.47537 3.87012C5.86395 4.02968 6.24335 4.05185 6.63682 3.88052C6.87049 3.7787 7.11854 3.7059 7.36112 3.62324C8.0423 3.39114 8.73354 3.29152 9.45438 3.44397C10.2885 3.6205 11.0087 3.96482 11.5212 4.64058C11.6313 4.78564 11.6319 4.78756 11.4735 4.89184C11.101 5.13735 10.7662 5.42172 10.5207 5.78711C9.95765 6.62572 9.92949 7.5303 10.2221 8.45048C10.4991 9.32221 11.1602 9.87508 12 10.2563Z"
                          fill="white"
                        />
                        <path
                          d="M5.96882 2.94203C6.02429 1.88473 6.53532 1.0754 7.45362 0.495708C7.83474 0.255126 8.25437 0.0939175 8.70676 0.0159131C8.93497 -0.0234996 8.97263 -0.00187736 8.9663 0.221735C8.92894 1.53084 8.2857 2.48359 7.07595 3.08792C6.77129 3.24009 6.43041 3.27184 6.0858 3.24967C6.00072 3.2442 5.96479 3.21327 5.96824 3.13335C5.97112 3.06985 5.96882 3.00608 5.96882 2.94231V2.94203Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1270_8964">
                          <rect width="12" height="14" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span className="font-roboto text-[16px] sm:text-[8px] text-white font-normal">
                      Coming soon in App Store
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rounded-[50px] sm:rounded-[10px] max-screen-1440 relative sm:top-0 bg-primary">
          <div className="z-[1] bg-no-repeat mt-[-270px] sm:mt-[-96px] bottom-[-92px] md:h-[413px] relative bg-cover sm:bg-cover bg-[url('../public/images/background/security-photo.png')] pl-[51px] sm:pl-[0px] h-[702px] sm:h-[186px] sm:w-[100%] sm:bottom-[-8px] rounded-b-[50px] md:rounded-[30px] sm:rounded-b-[10px] max-w-[1440px] mx-auto">
            <div className="text-white md:text-[28px] sm:pl-[42px] sm:w-[201px] text-bmd font-roboto-bold sm:tracking-tight leading-normal mb-[24px] sm:mb-[16px] pt-[202px] md:pt-[73px] sm:pt-[36px] w-[464px] sm:text-17">
              We take <span className="text-light-green">security</span>{" "}
              seriously
            </div>
            <div className="text-white text-base font-reg w-[464px] md:text-[21px] sm:pl-[42px] sm:w-[59%] sm:text-12">
              Industry-leading technology
              <br className="hidden sm:block" /> and standards are strictly
              <br className="hidden sm:block" /> followed
              <span className="sm:hidden">
                ; we protect your
                <br className="hidden sm:block" /> data
              </span>
              .{" "}
              <span className="text-light-green font-bold">
                Feel safe with us.
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Section3;
