import React, { useEffect, useState } from "react";
import HelpCard from "utils/helpCard";
import { useNavigate } from "react-router-dom";
import { HelpCenterTitles } from "utils/constants";

const Markets4 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  const [isThumbUpActive, setIsThumbUpActive] = useState(false);
  const [isThumbDownActive, setIsThumbDownActive] = useState(false);

  const handleThumbUpClick = () => {
    setIsThumbUpActive(!isThumbUpActive);
    setIsThumbDownActive(false);
  };

  const handleThumbDownClick = () => {
    setIsThumbDownActive(!isThumbDownActive);
    setIsThumbUpActive(false);
  };

  return (
    <div className="bg-primary h-max">
      <div className="max-screen-1440 mx-auto px-[28px] sm:px-[18px] md:px-[23px]">
        <div className="sm:block hidden text-white text-[24px] font-medium pt-[14px] flex items-center">
          <div
            className="flex items-center gap-2 mb-4"
            onClick={() => navigate("/help/markets")}
          >
            <img src="/icons/left.svg" className="z-[12]" alt="left-back" />
            Markets
          </div>
        </div>
        <div className="sm:hidden block text-white text-[50px] font-roboto-bold pt-[50px] flex items-center gap-8">
          <img src="/images/help/markets-detail.svg" alt="markets-detail" />
          Markets
        </div>
        <div className="mt-10 sm:mt-4 sm:w-full flex max-w-[1416px] w-full grid grid-cols-12">
          <div className="col-span-9 md:col-span-12 sm:col-span-12">
            <div className="w-full mx-auto mb-10 sm:mb-4">
              <div className="w-full bg-transparent rounded shadow-md">
                <div className="font-bold text-[24px] text-white w-full text-left flex justify-between items-center pb-8 sm:pb-4 border-b border-b-1 border-[#464646]">
                  {HelpCenterTitles.markets[3].title}
                </div>
                <div
                  className={`mt-8 pb-8 sm:mt-4 sm:pb-[56px] border-b border-b-1 border-[#464646]`}
                >
                  <div className="text-white text-[24px] sm:text-[16px]">
                    <p className="font-normal">
                      To access articles on the news within the Capiwise app,
                      you can follow these general steps. Keep in mind that the
                      specific features and navigation may vary based on the
                      design of the app:
                    </p>
                    <div className="mt-4">
                      <div className="font-bold">1. News Section:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Look for a dedicated "News" section or tab within the
                        Capiwise app. This is a common area where articles, news
                        updates, and market insights are aggregated.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">2. Homepage or Dashboard:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Check the homepage or dashboard of the Capiwise app.
                        Some apps feature a news feed directly on the main
                        screen, providing quick access to the latest articles
                        and updates.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        3. Explore or Discover Section:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Navigate to an "Explore" or "Discover" section within
                        the app. This area may showcase trending articles,
                        featured news, or the latest market insights.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">4. Market Insights:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Explore sections related to "Market Insights" or
                        "Research." These sections often contain articles and
                        reports providing in-depth analysis of market trends and
                        specific companies.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">5. Search Functionality:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Use the app's search functionality to look for specific
                        topics, companies, or keywords related to the articles
                        you're interested in. This can help you find relevant
                        news content.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">6. Categories or Tags:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Some apps categorize articles using tags or categories.
                        Look for sections labeled with specific topics,
                        industries, or themes to find articles that match your
                        interests.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">7. Notifications:</div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Ensure that your notification settings are configured to
                        receive alerts about new articles. The app may send
                        notifications when there are updates or relevant news
                        articles based on your preferences.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        8. Community or Social Features:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        If the app has community or social features, explore
                        discussions related to news articles. Users often share
                        and discuss articles within the app's community,
                        providing additional perspectives.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        9. Watchlist Notifications:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        If you have specific companies added to your watchlist,
                        the app may send notifications about news articles
                        related to those companies. Check your watchlist
                        notifications for relevant updates.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        10. Daily Briefing or Newsletter:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        Some apps offer a daily briefing or newsletter feature
                        that summarizes the top news and articles. Look for this
                        feature to get a quick overview of the latest happenings
                        in the financial world.
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-bold">
                        11. Bookmark or Save Feature:
                      </div>
                      <div className="text-white text-[24px] sm:text-[16px]">
                        If you come across articles you want to revisit later,
                        check if the app has a bookmark or save feature. This
                        allows you to create a personalized reading list.
                      </div>
                    </div>
                    <div className="mt-4 font-normal">
                      Remember to refer to the Capiwise app's documentation or
                      help centre for specific guidance on accessing articles
                      within the news section. The app's help centre is a
                      valuable resource for understanding its features and
                      making the most of your news reading experience.
                    </div>
                  </div>
                  <div className="text-[#979797] text-[16px] mt-[108px] sm:mt-8 sm:text-[10px] ">
                    Was this information helpful?
                  </div>
                  <div className="flex justify-between items-center w-[120px] sm:w-[75px] mt-4">
                    <img
                      src={
                        isThumbUpActive
                          ? "/images/help/thumbup_active.svg"
                          : "/images/help/thumbup_normal.svg"
                      }
                      alt="thumbup"
                      onClick={handleThumbUpClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                    <img
                      src={
                        isThumbDownActive
                          ? "/images/help/thumbdown_active.svg"
                          : "/images/help/thumbdown_normal.svg"
                      }
                      alt="thumbdown"
                      onClick={handleThumbDownClick}
                      className="sm:h-[24px] cursor-pointer"
                    />
                  </div>
                </div>
              </div>
              <div className="text-[#0F69FE] text-[24px] mt-[80px] sm:mt-4 sm:text-[16px]">
                More Help
              </div>
              <div onClick={() => navigate(`/help/markets/1`)}>
                <HelpCard title={HelpCenterTitles.markets[0].title} />
              </div>
              <div onClick={() => navigate(`/help/markets/2`)}>
                <HelpCard title={HelpCenterTitles.markets[1].title} />
              </div>
              <div onClick={() => navigate(`/help/markets/3`)}>
                <HelpCard title={HelpCenterTitles.markets[2].title} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Markets4;
