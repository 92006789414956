import { Header } from "components";
import Footer from "components/Footer/Footer";
import React from "react";

function PublicLayout({ children }) {
  return (
    <div className="bg-primary overflow-hidden">
      <Header />
      {children}
      <Footer />
    </div>
  );
}

export default PublicLayout;
